import {
  AccountBalance,
  Build,
  Business,
  Calculate,
  CarCrash,
  CarRental,
  Dashboard,
  DirectionsCar,
  FileUploadOutlined,
  FormatListBulleted,
  Group,
  Handshake,
  History,
  ListAlt,
  LocalSee,
  LocalShipping,
  MessageOutlined,
  Public,
  Settings,
  Shield,
} from '@mui/icons-material'
import { Box } from '@mui/material'
import TransporterBadge from 'components/TransporterBadge'
import React from 'react'
import { CustomNavigation } from 'router/types/customNavigation'
import UserListBuilder from 'views/Shared/UserListBuilder/UserListBuilder'
import {
  AffiliateManagement,
  ApplicationManagement,
  DepreciationFormulas,
  DepreciationCalculator,
  HubspotDash,
  SuperAdminHome,
  TruckDebtOverhaulManagement,
  UserManagementV2,
  VINDecoder,
} from 'views/SuperAdmin'
import AdminListBuilder from 'views/SuperAdmin/AdminListBuilder'
import InventoryImporterManagement from 'views/SuperAdmin/InventoryImporter/InventoryImporterManagement'
import InventoryV3 from 'views/SuperAdmin/Management/InventoryManagement/InventoryV3'
import PhotoUpload from 'views/SuperAdmin/Management/PhotoUpload/PhotoUpload'
import TransporterPhotoUpload from 'views/SuperAdmin/Management/PhotoUpload/TransporterPhotoUpload'
import SubmissionManagement from 'views/SuperAdmin/Management/SubmissionManagement'
import AuditLog from 'views/SuperAdmin/Tools/AuditLog/AuditLog'
import MessageLog from 'views/SuperAdmin/Tools/Messages/MessageLog'

// const theme = useTheme()

export const getSuperUserPages = (userType: string, isOwner: boolean): CustomNavigation => {
  const adminOnlyPages = []

  return [
    { kind: 'header', title: 'General' },
    {
      segment: 'admin',
      title: 'Dashboard',
      icon: <Dashboard />,
      component: <SuperAdminHome />,
    },
    {
      segment: 'admin/application-management',
      title: 'Financing Applications',
      icon: <AccountBalance />,
      component: <ApplicationManagement />,
    },
    {
      segment: 'admin/management/photo-upload',
      title: 'Photo Uploader',
      icon: (
        <Box position='relative' sx={{ display: 'flex' }}>
          <LocalSee />
        </Box>
      ),
      component: <PhotoUpload />,
    },
    {
      segment: 'admin/management/transporter-photo-upload',
      title: 'Transporter Photos',
      icon: <TransporterBadge />,
      component: <TransporterPhotoUpload />,
    },
    {
      segment: 'admin/user-management-v2',
      title: 'User Management',
      icon: <Group />,
      component: <UserManagementV2 />,
    },
    { kind: 'divider' },
    { kind: 'header', title: 'Inventory' },
    {
      segment: 'admin/inventory',
      title: 'Manage Inventory',
      icon: <ListAlt />,
      children: [
        {
          segment: 'optic-inventory-v2',
          title: 'Optic Inventory',
          icon: (
            <Box position='relative'>
              <DirectionsCar />
            </Box>
          ),
          component: <InventoryV3 inventoryCollection='master_inventory' />,
        },

        ...(userType !== 'admin'
          ? []
          : [
              {
                segment: 'shield-inventory-v2',
                title: 'Shield Inventory',
                icon: (
                  <Box position='relative'>
                    <Shield />
                  </Box>
                ),
                component: <InventoryV3 inventoryCollection='shield_inventory' />,
              },
            ]),
        {
          segment: 'admin/inventory-importer',
          title: 'Inventory Importer',
          icon: <FileUploadOutlined />,
          component: <InventoryImporterManagement />,
        },
      ],
    },
    {
      segment: 'admin/management/submission-management',
      title: 'Website Submissions',
      component: <SubmissionManagement />,
      icon: <Public />,
    },

    {
      segment: 'admin/list-builder',
      title: 'List Builder',
      icon: <FormatListBulleted />,
      component: <AdminListBuilder />,
    },
    {
      segment: 'admin/my-inventory-lists',
      title: 'My Inventory Lists',
      icon: <FormatListBulleted />,
      component: <UserListBuilder />,
    },

    { kind: 'divider' },
    { kind: 'header', title: 'Tools' },
    {
      segment: 'admin/tools',
      title: 'Tools',
      icon: <Build />,
      children: [
        ...(isOwner
          ? [
              ...(userType !== 'admin'
                ? []
                : [
                    {
                      segment: 'optic-gray-book-formulas',
                      title: 'Optic Gray Book | Formulas',
                      icon: <CarCrash />,
                      component: <DepreciationFormulas />,
                    },
                  ]),
              {
                segment: 'optic-gray-book-calculator',
                title: 'Optic Gray Book | Calculator',
                icon: <Calculate />,
                component: <DepreciationCalculator />,
              },
            ]
          : []),
        {
          segment: 'vin-decoder',
          title: 'VIN Decoder',
          icon: <CarRental />,
          component: <VINDecoder />,
        },
        {
          segment: 'audit-log',
          title: 'Audit Log',
          icon: <History />,
          component: <AuditLog />,
        },
        {
          segment: 'messages',
          title: 'Messages',
          icon: <MessageOutlined />,
          component: <MessageLog />,
        },
      ],
    },
    { kind: 'divider' },
    { kind: 'header', title: 'Miscellaneous' },
    {
      segment: 'admin/other',
      title: 'Miscellaneous',
      icon: <Settings />,
      children: [
        {
          segment: 'admin/affiliate-management',
          title: 'Affiliate Program',
          icon: <Handshake />,
          component: <AffiliateManagement />,
        },
        {
          segment: 'admin/truck-debt-overhaul-management',
          title: 'Truck Debt Overhaul',
          icon: <LocalShipping />,
          component: <TruckDebtOverhaulManagement />,
        },
        {
          segment: 'hubspot-integration',
          title: 'Hubspot Integration',
          icon: <Business />,
          component: <HubspotDash />,
        },
      ],
    },
  ]
}
