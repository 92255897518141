import { Paper, Typography, Box, useTheme, Stack } from '@mui/material'
import { PieChart } from '@mui/x-charts'
import { useMemo } from 'react'
import { formatPrice } from 'core/utils/inventoryUtils'
import React from 'react'

interface ProfitDistributionChartProps {
  vehicles: any[]
  dateRange: { start: Date; end: Date }
}

export const ProfitDistributionChart = ({ vehicles, dateRange }: ProfitDistributionChartProps) => {
  const theme = useTheme()

  const parseDate = (dateValue: any): Date | null => {
    try {
      if (!dateValue) return null

      // Handle Firestore Timestamp
      if (dateValue?.seconds) {
        return new Date(dateValue.seconds * 1000)
      }

      // Handle string dates with "at" format
      if (typeof dateValue === 'string' && dateValue.includes(' at ')) {
        const cleanedDate = dateValue.replace(' at ', ' ')
        const date = new Date(cleanedDate)
        if (!isNaN(date.getTime())) {
          return date
        }
      }

      // Handle string dates
      if (typeof dateValue === 'string') {
        const date = new Date(dateValue)
        return isNaN(date.getTime()) ? null : date
      }

      // Handle Date objects
      if (dateValue instanceof Date) {
        return isNaN(dateValue.getTime()) ? null : dateValue
      }

      return null
    } catch (error) {
      console.error('Error parsing date:', dateValue, error)
      return null
    }
  }

  const getDateRangeText = () => {
    // If start year is 2000 or earlier, it's "All Time"
    if (dateRange.start.getFullYear() <= 2000) {
      return 'All Time'
    }

    // Format dates in a readable way
    const formatDate = (date: Date) => 
      date.toLocaleDateString('en-US', { 
        month: 'short', 
        day: 'numeric',
        year: '2-digit'
      })

    return `${formatDate(dateRange.start)} - ${formatDate(dateRange.end)}`
  }

  const profitData = useMemo(() => {
    // Filter sold vehicles - handle all time differently
    const soldVehicles = vehicles.filter(v => {
      if (v.status?.toLowerCase() !== 'sold') return false
      
      // If it's all time, include even without sold_date
      if (dateRange.start.getFullYear() <= 2000) return true
      
      // Otherwise, require sold_date and check range
      if (!v.sold_date) return false
      const soldDate = parseDate(v.sold_date)
      if (!soldDate) return false
      return soldDate >= dateRange.start && soldDate <= dateRange.end
    })
    
    // Define profit ranges with more meaningful brackets
    const ranges = [
      { label: 'Loss', min: -Infinity, max: 0, color: theme.palette.error.main },
      { label: '$0-$2.5k', min: 0, max: 2500, color: theme.palette.warning.light },
      { label: '$2.5k-$5k', min: 2500, max: 5000, color: theme.palette.success.light },
      { label: '$5k-$7.5k', min: 5000, max: 7500, color: theme.palette.success.main },
      { label: '$7.5k-$10k', min: 7500, max: 10000, color: theme.palette.primary.light },
      { label: '$10k+', min: 10000, max: Infinity, color: theme.palette.primary.main }
    ]

    // Count vehicles and sum profits in each range
    const data = ranges.map(range => {
      const vehiclesInRange = soldVehicles.filter(v => {
        const profit = parseFloat(v.profit) || 0
        return profit > range.min && profit <= range.max
      })

      const count = vehiclesInRange.length
      const totalProfit = vehiclesInRange.reduce((sum, v) => sum + (parseFloat(v.profit) || 0), 0)
      const avgProfit = count > 0 ? totalProfit / count : 0

      return {
        id: range.label,
        label: `${range.label} (${count})`,
        value: count,
        color: range.color,
        avgProfit,
        totalProfit
      }
    }).filter(range => range.value > 0) // Only show ranges with vehicles

    return data
  }, [vehicles, dateRange, theme])

  const totalProfit = useMemo(() => {
    return profitData.reduce((sum, range) => sum + range.totalProfit, 0)
  }, [profitData])

  if (profitData.length === 0) {
    return (
      <Paper sx={{ p: 3, height: 400 }}>
        <Typography variant="h6" gutterBottom>
          Profit Distribution ({getDateRangeText()})
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 300 }}>
          <Typography color="text.secondary">No profit data available for selected date range</Typography>
        </Box>
      </Paper>
    )
  }

  return (
    <Paper sx={{ p: 3, height: 400 }}>
      <Stack spacing={0.5} mb={2}>
        <Typography variant="h6">
          Profit Distribution ({getDateRangeText()})
        </Typography>
        <Typography 
          variant="subtitle1" 
          color="text.secondary"
          sx={{ 
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
            color: totalProfit >= 0 ? 'success.main' : 'error.main',
            fontWeight: 'medium'
          }}
        >
          Total Profit: {formatPrice(totalProfit.toString())}
        </Typography>
      </Stack>
      <Box sx={{ width: '100%', height: 265 }}>
        <PieChart
          series={[
            {
              data: profitData,
              highlightScope: { faded: 'global', highlighted: 'item' },
              innerRadius: 30,
              paddingAngle: 2,
              cornerRadius: 4,
              valueFormatter: (item) => {
                const data = profitData.find(d => d.value === item.value)
                if (!data) return ''
                return [
                  `${data.label}`,
                  `Avg Profit: ${formatPrice(data.avgProfit.toString())}`,
                  `Total Profit: ${formatPrice(data.totalProfit.toString())}`
                ].join('\n')
              }
            }
          ]}
          height={265}
          margin={{ right: 200 }}
          slotProps={{
            legend: {
              direction: 'column',
              position: { vertical: 'middle', horizontal: 'right' },
              padding: 8,
              itemMarkWidth: 20,
              itemMarkHeight: 2,
              itemGap: 12,
              labelStyle: {
                fontSize: 12
              }
            }
          }}
        />
      </Box>
    </Paper>
  )
}
