import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import {
  Badge,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  ToggleButton,
  ToggleButtonGroup,
  TextField,
  Typography,
  MenuItem,
  Select,
} from '@mui/material'
import { useSessionStorageState } from '@toolpad/core'
import React, { useCallback, useMemo, useState } from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { getVehicleSizeOptions } from 'core/constants/inventory'
import _ from 'lodash'
import { useIsMobile } from './screen/useIsMobile'
import { ConstructionOutlined } from '@mui/icons-material'

interface Filters {
  search: string
  type: string[]
  make: string[]
  model: string[]
  size: string[]
  year: string[]
  fuel: string[]
  mileage: [number, number]
  buyPriceRange: [number, number]
  sellPriceRange: [number, number]
  status?: 'Pending' | 'Available' | 'Pending Sale' | 'Sold'
  dealQuality: string[]
}

type FilterKey = keyof Filters
type NumberFilterKey = 'mileage' | 'buyPriceRange' | 'sellPriceRange'
type FilterValue = string[] | [number, number]

const numberFilterLabels: Record<NumberFilterKey, string> = {
  buyPriceRange: 'Buy Price',
  sellPriceRange: 'Sell Price',
  mileage: 'Miles',
}

const getNumberFilterLabel = (key: NumberFilterKey): string => {
  return numberFilterLabels[key]
}

const initialFilters: Filters = {
  search: '',
  type: [],
  make: [],
  model: [],
  size: [],
  year: [],
  fuel: [],
  mileage: [0, 500000],
  buyPriceRange: [0, 100000],
  sellPriceRange: [0, 100000],
  status: undefined,
  dealQuality: [],
}

type VehicleStatus = 'all' | 'available' | 'sold'

// Filter Hook
const useInventoryFilter = (inventory: Vehicle[], includeSellPrice: boolean = true, isOwner: boolean = false) => {
  const [filters, setFilters] = useState<Filters>(initialFilters)
  const [vehicleStatus, setVehicleStatus] = useState<VehicleStatus>('available')
  const isMobile = useIsMobile()

  const applyFilter = useCallback((key: keyof Filters, value: any) => {
    setFilters(prevFilters => ({ ...prevFilters, [key]: value }))
  }, [])

  const vehicleFilterSet = useMemo(() => {
    switch (vehicleStatus) {
      case 'sold':
        return inventory.filter(vehicle => (vehicle.status || '').slice(0, 4).toLowerCase() === 'sold')
      case 'available':
        return inventory.filter(vehicle => (vehicle.status || '').slice(0, 4).toLowerCase() !== 'sold')
      case 'all':
        return inventory
      default:
        return inventory
    }
  }, [inventory, vehicleStatus])

  const filteredInventory = useMemo(() => {
    const filtered = vehicleFilterSet.filter(vehicle => {
      // Skip poor quality vehicles unless specifically requested
      if (vehicle.dealQuality === 'poor' && (!filters.dealQuality || !filters.dealQuality.includes('poor'))) {
        return false
      }

      const matchesType =
        filters.type.length === 0 || (vehicle.type && filters.type.includes(vehicle.type.toLowerCase()))

      const matchesMake =
        filters.make.length === 0 || (vehicle.make && filters.make.includes(vehicle.make.toLowerCase()))

      const matchesSize =
        filters.size.length === 0 || (vehicle.size && filters.size.includes(vehicle.size.toLowerCase()))

      const matchesModel =
        filters.model.length === 0 || (vehicle.model && filters.model.includes(vehicle.model.toLowerCase()))

      const matchesYear =
        filters.year.length === 0 || (vehicle.year && filters.year.includes(vehicle.year.toString()))

      const matchesFuel =
        filters.fuel.length === 0 || (vehicle.fuel && filters.fuel.includes(vehicle.fuel.toLowerCase()))

      const matchesMileage =
        vehicle.miles == null ||
        vehicle.miles === '' ||
        (parseInt(vehicle.miles, 10) >= filters.mileage[0] && parseInt(vehicle.miles, 10) <= filters.mileage[1])

      const matchesBuyPrice =
        vehicle.seller_asking_price == null ||
        vehicle.seller_asking_price === '' ||
        (parseFloat(vehicle.seller_asking_price) >= filters.buyPriceRange[0] &&
          parseFloat(vehicle.seller_asking_price) <= filters.buyPriceRange[1])

      const matchesStatus = !filters.status || vehicle.status === filters.status

      const matchesSearch =
        !filters.search ||
        Object.values(vehicle).some(value => value?.toString().toLowerCase().includes(filters.search.toLowerCase()))

      const matchesSellPrice =
        !includeSellPrice ||
        vehicle.optic_list_price == null ||
        vehicle.optic_list_price === '' ||
        (parseFloat(vehicle.optic_list_price) >= filters.sellPriceRange[0] &&
          parseFloat(vehicle.optic_list_price) <= filters.sellPriceRange[1])

      const matchesDealQuality =
        filters.dealQuality.length === 0 ||
        (vehicle.dealQuality && filters.dealQuality.includes(vehicle.dealQuality))

      return (
        matchesType &&
        matchesMake &&
        matchesSize &&
        matchesModel &&
        matchesYear &&
        matchesFuel &&
        matchesMileage &&
        matchesBuyPrice &&
        matchesStatus &&
        matchesSearch &&
        matchesSellPrice &&
        matchesDealQuality
      )
    })

    return filtered.sort((a, b) => {
      const dateA = a.date_added ? new Date(a.date_added).getTime() : 0
      const dateB = b.date_added ? new Date(b.date_added).getTime() : 0
      return dateB - dateA
    })
  }, [inventory, filters, vehicleStatus, includeSellPrice])

  const SearchBarUI = () => {
    const [searchValue, setSearchValue] = useState(filters.search)
    const [localVehicleStatus, setLocalVehicleStatus] = useState<VehicleStatus>(
      filters.search === '' ? 'all' : vehicleStatus,
    )

    const handleSearch = () => {
      applyFilter('search', searchValue)
      if (localVehicleStatus !== vehicleStatus) {
        setVehicleStatus(localVehicleStatus)
      }
    }

    return (
      <Box
        mt={isMobile ? 0 : 2}
        mb={2}
        width='100%'
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <form
          onSubmit={e => {
            e.preventDefault()
            handleSearch()
          }}
          style={{ width: '100%', maxWidth: '1000px' }}
        >
          <TextField
            fullWidth
            placeholder='Search inventory'
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position='start'>
                    {isMobile ? (
                      <Select
                        value={localVehicleStatus}
                        onChange={e => {
                          setLocalVehicleStatus(e.target.value as VehicleStatus)
                        }}
                        size='small'
                        variant='standard'
                        IconComponent={() => null}
                        sx={{
                          minWidth: 'auto',
                          '& .MuiSelect-select': {
                            py: 0.5,
                            pl: 0.5,
                            pr: 0.5,
                            borderRight: '1px solid',
                            borderColor: 'divider',
                          },
                          '&:before, &:after': {
                            display: 'none',
                          },
                          '& .MuiSelect-standard': {
                            background: 'transparent',
                          },
                        }}
                      >
                        <MenuItem value='all'>All</MenuItem>
                        <MenuItem value='available'>Available</MenuItem>
                        <MenuItem value='sold'>Sold</MenuItem>
                      </Select>
                    ) : (
                      <ToggleButtonGroup
                        value={localVehicleStatus}
                        exclusive
                        onChange={(e, newValue) => {
                          if (newValue !== null) {
                            setLocalVehicleStatus(newValue as VehicleStatus)
                          }
                        }}
                        aria-label='vehicle status'
                        size='small'
                        sx={{
                          ml: -1,
                          '& .MuiToggleButton-root': {
                            border: 'none',
                            borderRadius: 0,
                            padding: '4px 12px',
                            textTransform: 'none',
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.04)',
                            },
                          },
                          '& .MuiToggleButton-root.Mui-selected': {
                            backgroundColor: 'transparent',
                            color: 'primary.main',
                            fontWeight: 'bold',
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.04)',
                            },
                          },
                        }}
                      >
                        <ToggleButton value='all'>All</ToggleButton>
                        <ToggleButton value='available'>Available</ToggleButton>
                        <ToggleButton value='sold'>Sold</ToggleButton>
                      </ToggleButtonGroup>
                    )}
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    {searchValue && (
                      <IconButton
                        onClick={() => {
                          setSearchValue('')
                          applyFilter('search', '')
                        }}
                      >
                        <CloseIcon sx={{ fontSize: '20px' }} />
                      </IconButton>
                    )}
                    <IconButton type='submit'>
                      <SearchIcon sx={{ fontSize: '30px', color: 'primary' }} />
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
            sx={{ height: '56px' }}
          />
        </form>
      </Box>
    )
  }

  const FilterUI = () => {
    const [expandedAccordions, setExpandedAccordions] = useSessionStorageState<string[]>(
      'filter_opened-accordions',
      [],
      {
        codec: {
          stringify: JSON.stringify,
          parse: storedValue => {
            try {
              return JSON.parse(storedValue)
            } catch (error) {
              return []
            }
          },
        },
      },
    )
    const [mobileFilters, setMobileFilters] = useState<Filters>(filters)
    const availableMakes = Array.from(
      new Set(vehicleFilterSet.map(vehicle => safeToLowerCase(vehicle.make)).filter(Boolean)),
    )
    const availableTypes = Array.from(
      new Set(vehicleFilterSet.map(vehicle => safeToLowerCase(vehicle.type)).filter(Boolean)),
    )
    const availableSizes = Array.from(
      new Set(vehicleFilterSet.map(vehicle => safeToLowerCase(vehicle.size)).filter(Boolean)),
    )

    const sizeOptions = useMemo(() => {
      if (isMobile && mobileFilters.type.length > 0) {
        const typeSpecificSizes = new Set(mobileFilters.type.flatMap(type => getVehicleSizeOptions(type)))
        return Array.from(new Set([...availableSizes].filter(size => typeSpecificSizes.has(size))))
      } else if (!isMobile && filters.type.length > 0) {
        const typeSpecificSizes = new Set(filters.type.flatMap(type => getVehicleSizeOptions(type)))
        return Array.from(new Set([...availableSizes].filter(size => typeSpecificSizes.has(size))))
      }

      return availableSizes
    }, [availableSizes, filters.type, mobileFilters.type])

    const availableYears = Array.from(
      new Set(
        vehicleFilterSet
          .map(vehicle => vehicle.year?.toString())
          .filter(Boolean)
          .sort((num1, num2) => Number(num2) - Number(num1)),
      ),
    )
    const availableModels = Array.from(
      new Set(vehicleFilterSet.map(vehicle => safeToLowerCase(vehicle.model)).filter(Boolean)),
    )
    const availableFuels = Array.from(
      new Set(vehicleFilterSet.map(vehicle => safeToLowerCase(vehicle.fuel)).filter(Boolean)),
    )

    const availableDealQualities = Array.from(
      new Set(vehicleFilterSet.map(vehicle => safeToLowerCase(vehicle.dealQuality)).filter(Boolean)),
    )

    const isFilterActive = (filterType: FilterKey): boolean => {
      return !_.isEqual(filters[filterType], initialFilters[filterType])
    }

    const getActiveOptionCount = (filters: Filters, filterType: FilterKey): number => {
      const filter = filters[filterType]
      if (Array.isArray(filter)) {
        return filter.length
      }
      return filter !== initialFilters[filterType] ? 1 : 0
    }

    const isAccordionExpanded = useCallback(
      (accordionKey: string) => {
        if (expandedAccordions === null) return false

        return expandedAccordions.includes(accordionKey)
      },
      [expandedAccordions],
    )

    const handleAccordionToggle = useCallback(
      (accordionKey: string) => {
        setExpandedAccordions(prev => {
          if (prev === null) return [accordionKey]
          return prev.includes(accordionKey) ? prev.filter(key => key !== accordionKey) : [...prev, accordionKey]
        })
      },
      [setExpandedAccordions],
    )

    const handleFilterClick = (filterType: FilterKey, value: string) => {
      if (isMobile) {
        setMobileFilters(prevFilters => {
          const currentFilter = prevFilters[filterType] as string[]
          const updatedFilter = currentFilter.includes(value)
            ? currentFilter.filter(item => item !== value)
            : [...currentFilter, value]

          return {
            ...prevFilters,
            [filterType]: updatedFilter,
          }
        })
      } else {
        setFilters(prevFilters => {
          const currentFilter = prevFilters[filterType] as string[]
          const updatedFilter = currentFilter.includes(value)
            ? currentFilter.filter(item => item !== value)
            : [...currentFilter, value]

          return {
            ...prevFilters,
            [filterType]: updatedFilter,
          }
        })
      }
    }

    const hasValidOptions = (options: string[]): boolean => {
      return options.some(option => option.trim() !== '')
    }

    const FilterButtonGrid = ({ options, filterType }: { options: string[]; filterType: FilterKey }) => (
      <Grid container spacing={1}>
        {options.map(option => (
          <Grid item key={option}>
            <Button
              variant={
                ((isMobile ? mobileFilters[filterType] : filters[filterType]) as string[]).includes(option)
                  ? 'contained'
                  : 'outlined'
              }
              size='small'
              onClick={() => handleFilterClick(filterType, option)}
            >
              {option}
            </Button>
          </Grid>
        ))}
      </Grid>
    )

    const renderNumberRangeFilter = (label: string, filterKey: 'mileage' | 'buyPriceRange' | 'sellPriceRange') => {
      const [localMin, setLocalMin] = useState(String((filters[filterKey] as [number, number])[0]))
      const [localMax, setLocalMax] = useState(String((filters[filterKey] as [number, number])[1]))

      const handleBlur = () => {
        const min = Number(localMin)
        const max = Number(localMax)
        if (isMobile) {
          setMobileFilters(prevFilters => {
            return {
              ...prevFilters,
              [filterKey]: [min, max],
            }
          })
        } else {
          applyFilter(filterKey, [min, max])
        }
      }

      return (
        <Box display='flex' flexDirection='row' gap={2}>
          <TextField
            label={`Min ${label}`}
            variant='outlined'
            type='number'
            value={localMin}
            onChange={e => setLocalMin(e.target.value)}
            onBlur={handleBlur}
          />
          <TextField
            label={`Max ${label}`}
            variant='outlined'
            type='number'
            value={localMax}
            onChange={e => setLocalMax(e.target.value)}
            onBlur={handleBlur}
          />
        </Box>
      )
    }

    const ActiveFilterBadge = ({ filterType, children }: { filterType: FilterKey; children: React.ReactNode }) => {
      const count = getActiveOptionCount(isMobile ? mobileFilters : filters, filterType)

      return (
        <Box display='flex' alignItems='center' width='100%' justifyContent='space-between'>
          <Typography>{children}</Typography>
          {count > 0 && <Badge badgeContent={count} color='primary' showZero={false} />}
        </Box>
      )
    }

    return (
      <Box>
        {hasValidOptions(availableTypes) && (
          <Accordion expanded={isAccordionExpanded('type')} onChange={() => handleAccordionToggle('type')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                '& .MuiAccordionSummary-content': {
                  marginRight: isAccordionExpanded('type') ? 4 : 2,
                  width: '100%',
                },
              }}
            >
              <ActiveFilterBadge filterType='type'>Type</ActiveFilterBadge>
            </AccordionSummary>
            <AccordionDetails>
              <FilterButtonGrid options={availableTypes} filterType='type' />
            </AccordionDetails>
          </Accordion>
        )}
        {hasValidOptions(sizeOptions) && (
          <Accordion expanded={isAccordionExpanded('size')} onChange={() => handleAccordionToggle('size')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                '& .MuiAccordionSummary-content': {
                  marginRight: isAccordionExpanded('size') ? 3 : 2,
                  width: '100%',
                },
              }}
            >
              <ActiveFilterBadge filterType='size'>Size</ActiveFilterBadge>
            </AccordionSummary>
            <AccordionDetails>
              <FilterButtonGrid options={sizeOptions} filterType='size' />
            </AccordionDetails>
          </Accordion>
        )}
        {hasValidOptions(availableMakes) && (
          <Accordion expanded={isAccordionExpanded('make')} onChange={() => handleAccordionToggle('make')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                '& .MuiAccordionSummary-content': {
                  marginRight: isAccordionExpanded('make') ? 3 : 2,
                  width: '100%',
                },
              }}
            >
              <ActiveFilterBadge filterType='make'>Make</ActiveFilterBadge>
            </AccordionSummary>
            <AccordionDetails>
              <FilterButtonGrid options={availableMakes} filterType='make' />
            </AccordionDetails>
          </Accordion>
        )}

        {hasValidOptions(availableModels) && (
          <Accordion expanded={isAccordionExpanded('model')} onChange={() => handleAccordionToggle('model')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                '& .MuiAccordionSummary-content': {
                  marginRight: isAccordionExpanded('model') ? 3 : 2,
                  width: '100%',
                },
              }}
            >
              <ActiveFilterBadge filterType='model'>Model</ActiveFilterBadge>
            </AccordionSummary>
            <AccordionDetails>
              <FilterButtonGrid options={availableModels} filterType='model' />
            </AccordionDetails>
          </Accordion>
        )}
        {hasValidOptions(availableYears) && (
          <Accordion expanded={isAccordionExpanded('year')} onChange={() => handleAccordionToggle('year')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                '& .MuiAccordionSummary-content': {
                  marginRight: isAccordionExpanded('year') ? 3 : 2,
                  width: '100%',
                },
              }}
            >
              <ActiveFilterBadge filterType='year'>Year</ActiveFilterBadge>
            </AccordionSummary>
            <AccordionDetails>
              <FilterButtonGrid options={availableYears} filterType='year' />
            </AccordionDetails>
          </Accordion>
        )}
        {hasValidOptions(availableFuels) && (
          <Accordion expanded={isAccordionExpanded('fuel')} onChange={() => handleAccordionToggle('fuel')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                '& .MuiAccordionSummary-content': {
                  marginRight: isAccordionExpanded('fuel') ? 3 : 2,
                  width: '100%',
                },
              }}
            >
              <ActiveFilterBadge filterType='fuel'>Fuel</ActiveFilterBadge>
            </AccordionSummary>
            <AccordionDetails>
              <FilterButtonGrid options={availableFuels} filterType='fuel' />
            </AccordionDetails>
          </Accordion>
        )}

        <Accordion expanded={isAccordionExpanded('mileage')} onChange={() => handleAccordionToggle('mileage')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>Mileage</AccordionSummary>
          <AccordionDetails>{renderNumberRangeFilter('Mileage', 'mileage')}</AccordionDetails>
        </Accordion>

        <Accordion
          expanded={isAccordionExpanded('buyPriceRange')}
          onChange={() => handleAccordionToggle('buyPriceRange')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>Buy Price</AccordionSummary>
          <AccordionDetails>{renderNumberRangeFilter('Buy Price', 'buyPriceRange')}</AccordionDetails>
        </Accordion>
        {includeSellPrice && (
          <Accordion
            expanded={isAccordionExpanded('sellPriceRange')}
            onChange={() => handleAccordionToggle('sellPriceRange')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>Sell Price</AccordionSummary>
            <AccordionDetails>{renderNumberRangeFilter('Sell Price', 'sellPriceRange')}</AccordionDetails>
          </Accordion>
        )}
        {isOwner && hasValidOptions(availableDealQualities) && (
          <Accordion
            expanded={isAccordionExpanded('dealQuality')}
            onChange={() => handleAccordionToggle('dealQuality')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                '& .MuiAccordionSummary-content': {
                  marginRight: isAccordionExpanded('dealQuality') ? 3 : 2,
                  width: '100%',
                },
              }}
            >
              <ActiveFilterBadge filterType='dealQuality'>Deal Quality</ActiveFilterBadge>
            </AccordionSummary>
            <AccordionDetails>
              <FilterButtonGrid options={availableDealQualities} filterType='dealQuality' />
            </AccordionDetails>
          </Accordion>
        )}
        {isMobile && (
          <Box sx={{ p: 2, borderTop: 1, borderColor: 'divider' }}>
            <Button fullWidth variant='contained' color='primary' onClick={() => setFilters(mobileFilters)}>
              Apply filters
            </Button>
          </Box>
        )}
      </Box>
    )
  }

  const getActiveFilters = (filters: Filters): [FilterKey, string[] | [number, number]][] => {
    return (Object.entries(filters) as [FilterKey, string[] | [number, number]][]).filter(([key, value]) => {
      if (key === 'search') return false

      const initialValue = initialFilters[key as keyof typeof initialFilters]

      if (Array.isArray(value) && Array.isArray(initialValue)) {
        return value[0] !== initialValue[0] || value[1] !== initialValue[1]
      }

      return value !== initialValue
    })
  }

  const ResetChip = () => {
    return <Chip key='reset-chip' label='Reset' onClick={() => setFilters(initialFilters)} variant='outlined' />
  }

  const FilterChip = ({ filterKey, value }: { filterKey: FilterKey; value: FilterValue }) => {
    if (!value) return null

    if (isNumberPair(value)) {
      if (value[0] === undefined || value[1] === undefined) return null

      return (
        <Chip
          key={`${filterKey}-${value[0]}-${value[1]}`}
          label={`${getNumberFilterLabel(filterKey as NumberFilterKey)}: ${new Intl.NumberFormat('en-US').format(value[0])}-${new Intl.NumberFormat('en-US').format(value[1])}`}
          onDelete={() => removeFilter(filterKey)}
          color='primary'
          variant='outlined'
        />
      )
    } else {
      if (!Array.isArray(value) || value.length === 0) return null

      return (
        <>
          {value
            .filter(item => item !== undefined && item !== '')
            .map((item, index) => (
              <Chip
                key={`${filterKey}-${item}-${index}`}
                label={item}
                onDelete={() => removeFilter(filterKey, item)}
                color='primary'
                variant='outlined'
              />
            ))}
        </>
      )
    }
  }

  const SoldVehiclesToggle = () => (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mr: 2 }}>
      <ToggleButtonGroup
        value={vehicleStatus}
        exclusive
        onChange={(e, newValue) => {
          e.stopPropagation()
          if (newValue !== null) {
            setVehicleStatus(newValue as VehicleStatus)
          }
        }}
        size='small'
        aria-label='vehicle status'
      >
        <ToggleButton value='all'>All</ToggleButton>
        <ToggleButton value='available'>Available</ToggleButton>
        <ToggleButton value='sold'>Sold</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  )

  const removeFilter = (filterType: FilterKey, value?: string) => {
    setFilters(prevFilters => {
      if (value === undefined) {
        // Remove all filters of this type
        return {
          ...prevFilters,
          [filterType]: initialFilters[filterType],
        }
      } else {
        // Remove individual filter
        const currentFilter = prevFilters[filterType] as string[]
        return {
          ...prevFilters,
          [filterType]: currentFilter.filter(item => item !== value),
        }
      }
    })
  }

  return {
    filteredInventory,
    initialFilters,
    filters,
    activeFilters: getActiveFilters(filters),
    FilterChip,
    ResetChip,
    setFilters,
    FilterUI,
    SearchBarUI,
    SoldVehiclesToggle,
    removeFilter,
  }
}

const isNumberPair = (value: FilterValue): value is [number, number] =>
  Array.isArray(value) && value.length === 2 && typeof value[0] === 'number' && typeof value[1] === 'number'

const safeToLowerCase = (value: string | number | undefined): string => {
  if (value === undefined) return ''

  return value.toString().toLowerCase()
}

export default useInventoryFilter
