import React, { useEffect } from "react";
import { useAuth } from "contexts/AuthContext";
import { useNavigate } from "react-router-dom";

interface AuthRouteProps {
  children: React.ReactNode;
}

const AuthRoute = ({ children }: AuthRouteProps) => {
  const { isAuthenticated, userInfo, isLoading } = useAuth();
  
  const navigate = useNavigate();
  let userType = userInfo?.user_type;
  
  if (userType === 'babyAdmin') {
    userType = 'admin'
  }
  useEffect(() => {
    const handleRedirect = () => {
      navigate(`/${userType}`);
    };
    if (isAuthenticated && userType) {
      handleRedirect();
    }
  }, [isAuthenticated, navigate, userType, isLoading]);

  return <>{children}</>;
};

export default AuthRoute;
