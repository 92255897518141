import { vehicleFuelOptions, vehicleTypeOptions } from 'core/constants/inventory'
import * as XLSX from 'xlsx'

type SellSubmissionExcelRow = {
  'PICKUP LOCATION': string
  YEAR: string
  MAKE: string
  MODEL: string
  'SIZE (P FOR STEP VANS, FEET FOR BOX TRUCKS AND CUTAWAYS)': string
  VIN: string
  MILES: string
  'DESIRED PRICE': string
  'LIEN (Yes/No) ': string
  'FINANCING BANK': string
}

export const readInventoryFromSellSubmissionXLSX = async (fileBase64: string): Promise<Omit<Vehicle, 'id'>[]> => {
  const workbook = XLSX.read(fileBase64, { type: 'base64' })
  const sheetName = workbook.SheetNames[0]
  const worksheet = workbook.Sheets[sheetName]
  const data = XLSX.utils.sheet_to_json(worksheet, { blankrows: false, defval: '' })

  //someone deleted a row and it shifted everything so im just going to find the header row dynamically for now like this
  let headers: string[] = []
  let headerRowIndex = -1

  for (let i = 0; i < data.length; i++) {
    const row = Object.values(data[i] as string[])
    if (row.includes('PICKUP LOCATION')) {
      headers = row
      headerRowIndex = i
      break
    }
  }

  if (headerRowIndex === -1) {
    throw new Error('Could not find header row, please make sure the uploaded spreadsheet matches the template')
  }

  const rows = data.slice(headerRowIndex + 1) as string[][]

  const vehicles = rows.map(row => {
    const item = Object.fromEntries(
      headers.map((header, index) => [header, Object.values(row)[index]]),
    ) as SellSubmissionExcelRow
    return {
      year: String(item['YEAR'] || ''),
      make: String(item['MAKE'] || ''),
      model: String(item['MODEL'] || ''),
      size: String(item['SIZE (P FOR STEP VANS, FEET FOR BOX TRUCKS AND CUTAWAYS)'] || ''),
      miles: String(item['MILES'] || ''),
      location: String(item['PICKUP LOCATION'] || ''),
      vin: String(item['VIN'] || ''),
      seller_asking_price: String(item['DESIRED PRICE'] || '').replace(/[^0-9.-]+/g, ''),
      show_on_website: false,
      who: '',
      stock_number: '',
      fuel: '',
      optic_list_price: '',
      photo_storage_path: '',
      type: '',
      profit: '',
      sold_date: '',
      truck_notes: '',
      phone_number: '',
      link: '',
      email: '',
      cameras: '',
      follow_up_notes: '',
      entered_by: '',
      payoff_amount: '',
    }
  })

  return vehicles
}

interface CarFaxExcelRow {
  Rank: string
  vRank: string
  'Make/Model': string
  VIN: string
  'Stock #': string
  Certified: string
  Body: string
  Interior: string
  Color: string
  Engine: string
  Transmission: string
  Drivetrain: string
  Price: string
  Odometer: string
  Age: string
  Distance: string
  Seller: string
  'Seller Address': string
  'Seller City': string
  'Seller State': string
  'Seller Postal Code': string
  'Seller Phone': string
  'CARFAX 1-Owner': string
  'CARFAX 1-Owner Report Online': string
  'CARFAX Clean Title': string
}

export const readInventoryFromCarFaxXLSX = (file: File): Promise<Partial<Vehicle>[]> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = (e: ProgressEvent<FileReader>) => {
      try {
        const data = new Uint8Array(e.target?.result as ArrayBuffer)
        const workbook = XLSX.read(data, { type: 'array' })
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]
        const jsonData = XLSX.utils.sheet_to_json(worksheet) as CarFaxExcelRow[]
        const makeModelColumn = findColumn(worksheet, 'Make/Model')

        const vehicles: Partial<Vehicle>[] = jsonData.map((row, rowNumber) => {
          const { year, make, model, type } = row['Make/Model']
            ? mapMakeModel(row['Make/Model'])
            : { year: '', make: '', model: '', type: '' }
          const cellAddress = `${makeModelColumn}${rowNumber + 2}`
          const cell = worksheet[cellAddress]
          const link = cell.l?.Target || ''

          return {
            year,
            make,
            model,
            type,
            link,
            vin: row['VIN'],
            fuel: parseFuel(row['Engine']),
            seller_asking_price: row['Price'],
            who: row['Seller'],
            location: row['Seller State'],
            phone_number: row['Seller Phone'],
            miles: row['Odometer'],
          }
        })

        resolve(vehicles)
      } catch (error) {
        reject(error)
      }
    }

    reader.onerror = error => reject(error)
    reader.readAsArrayBuffer(file)
  })
}

const mapMakeModel = (makeModel: string): { year: string; make: string; model: string; type: string } => {
  const year = makeModel.slice(0, 4)
  let remaining = makeModel.slice(5)

  const makeModelMatch = remaining.match(/^([\w]+)\s+([\w]+)/)
  let make = ''
  let model = ''

  if (makeModelMatch) {
    ;[, make, model] = makeModelMatch
    remaining = remaining.replace(`${make} ${model}`, '').trim()
  }

  const type = remaining.trim()
  const validType = vehicleTypeOptions.find(t => type.toLowerCase().includes(t.toLowerCase()))

  return {
    year: year,
    make: make,
    model: model,
    type: validType || '',
  }
}

function findColumn(worksheet: XLSX.WorkSheet, columnName: string): string {
  const range = XLSX.utils.decode_range(worksheet['!ref'] || 'A1')
  for (let C = range.s.c; C <= range.e.c; ++C) {
    const cellAddress = XLSX.utils.encode_cell({ r: range.s.r, c: C })
    if (worksheet[cellAddress]?.v === columnName) {
      return XLSX.utils.encode_col(C)
    }
  }
  throw new Error(`Column '${columnName}' not found in the worksheet`)
}

function parseFuel(engineInfo: string): string {
  const lowerEngineInfo = engineInfo.toLowerCase()
  vehicleFuelOptions.forEach(fuelOption => {
    if (lowerEngineInfo.includes(fuelOption.toLowerCase())) {
      return fuelOption
    }
  })
  return ''
}
