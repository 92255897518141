import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { FormField } from 'core/types/forms'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import React, { Fragment, useCallback, useEffect, useState } from 'react'

interface CustomFormProps {
  fields: FormField[]
  submitText: string
  onSubmit: (data: { [key: string]: string | number | boolean }) => void
  onCancel: () => void
  initialValues?: { [key: string]: string | number | boolean | unknown }
  subTitles?: { [beforeFieldNumber: number]: string }
  secondarySubmitText?: string
  onSecondarySubmit?: (data: { [key: string]: string | number | boolean }) => void
  onFieldChange?: (fieldName: string, value: string | number | boolean) => void
}

const CustomForm: React.FC<CustomFormProps> = ({
  fields,
  onSubmit,
  subTitles,
  submitText,
  onCancel,
  initialValues,
  secondarySubmitText,
  onSecondarySubmit,
  onFieldChange,
}) => {
  const [values, setValues] = useState<{ [key: string]: string | number | boolean }>({})
  const [errors, setErrors] = useState<{ [key: string]: string }>({})
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({})
  const isMobile = useIsMobile()

  const getSelectValues = useCallback(
    (selectField: FormField) => {
      if (typeof selectField.selectValues === 'function') {
        return selectField.selectValues(values)
      }
      return selectField.selectValues || []
    },
    [values],
  )

  useEffect(() => {
    const existingValues = Object.assign(
      {},
      ...fields.map(field => ({ [field.name]: initialValues?.[field.name] ?? '' })),
    )
    setValues(existingValues)
    setTouched({})
  }, [fields, initialValues])

  const fieldIsRequired = useCallback(
    (field: FormField) => {
      if (field.type === 'select' && !getSelectValues(field)) {
        return false
      }
      return field?.required
    },
    [getSelectValues],
  )

  const validateFieldValue = useCallback(
    (field: FormField, value: string | boolean | number) => {
      if (!value && value !== 0) return fieldIsRequired(field) ? 'Field is required' : null
      if (field?.validation?.validateValue) {
        const validationResult = field.validation.validateValue(value)
        if (typeof validationResult === 'string') {
          return validationResult
        }
        if (!validationResult) {
          return field.validation.errorText || 'Invalid value'
        }
      }
      return null
    },
    [fieldIsRequired],
  )

  const handleChange = useCallback((field: FormField, value: string | boolean | number) => {
    setValues(prev => ({ ...prev, [field.name]: value }))
    setTouched(prev => ({ ...prev, [field.name]: true }))
    
    // Validate immediately on change
    const errorText = validateFieldValue(field, value)
    setErrors(prev => ({
      ...prev,
      [field.name]: errorText || ''
    }))
    
    onFieldChange?.(field.name, value)
  }, [onFieldChange, validateFieldValue])

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>, isSecondary: boolean = false) => {
      event.preventDefault()

      // Mark all fields as touched
      const allTouched = Object.fromEntries(fields.map(field => [field.name, true]))
      setTouched(allTouched)

      const newErrors: { [key: string]: string } = {}
      fields.forEach(field => {
        const errorText = validateFieldValue(field, values[field.name])
        if (errorText) {
          newErrors[field.name] = errorText
        }
      })

      if (Object.keys(newErrors).length === 0) {
        if (isSecondary && onSecondarySubmit) {
          onSecondarySubmit(values)
        } else {
          onSubmit(values)
        }
      } else {
        setErrors(newErrors)
      }
    },
    [fields, values, validateFieldValue, onSubmit],
  )

  const renderField = useCallback(
    (field: FormField) => {
      if (field.viewOnly) {
        return (
          <Box>
            <Typography variant='h5' fontWeight='medium'>
              {field.label}
            </Typography>
            <Typography fontWeight='light'>{values[field.name]}</Typography>
          </Box>
        )
      }

      const helperText =
        typeof field.textFieldProps?.helperText === 'function'
          ? field.textFieldProps.helperText(values[field.name], errors[field.name])
          : errors[field.name]

      const commonProps = {
        required: field.required,
        fullWidth: true,
        id: field.name,
        name: field.name,
        label: field.showTitle ? null : field.label,
        value: values[field.name] ?? '',
        onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
          handleChange(field, field.type === 'check' ? event.target.checked : event.target.value),
        error: !!errors[field.name],
        helperText,
      }

      switch (field.type) {
        case 'text':
          return (
            <Box>
              {typeof field.textFieldProps?.helperText === 'function' && (
                <FormHelperText
                  sx={{
                    mt: 0,
                    mb: 1,
                    color: 'text.secondary', // Force normal text color
                  }}
                >
                  {field.textFieldProps.helperText(values[field.name])}
                </FormHelperText>
              )}
              <TextField
                {...commonProps}
                {...(field.textFieldProps || {})}
                helperText={errors[field.name]} // Only show error message
                InputProps={
                  field.button
                    ? {
                        endAdornment: (
                          <InputAdornment position='end'>{field.button(values[field.name])}</InputAdornment>
                        ),
                      }
                    : undefined
                }
              />
            </Box>
          )
        case 'select':
          return (
            <FormControl error={!!errors[field.name]} fullWidth>
              {!field.showTitle && <InputLabel id={field.name}>{field.label}</InputLabel>}
              <Select {...commonProps} onChange={event => handleChange(field, event.target.value)}>
                {getSelectValues(field)?.map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
              {errors[field.name] && <FormHelperText>{errors[field.name]}</FormHelperText>}
            </FormControl>
          )
        case 'check':
          return (
            <FormControl error={!!errors[field.name]}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(values[field.name])}
                    onChange={event => handleChange(field, event.target.checked)}
                    name={field.name}
                  />
                }
                label={field.label}
              />
              {errors[field.name] && <FormHelperText>{errors[field.name]}</FormHelperText>}
            </FormControl>
          )
        case 'action':
          return field.actionElement?.()
        default:
          return null
      }
    },
    [values, errors, handleChange, getSelectValues],
  )

  const hasSecondButton = secondarySubmitText && onSecondarySubmit

  return (
    <Box
      component='form'
      noValidate
      onSubmit={handleSubmit}
      height={{ xs: '100%', md: 'auto' }}
      width={{ xs: '100%', md: 'auto' }}
      p={0}
      overflow='hidden'
    >
      <Box
        flex={1}
        height={`calc(100% - ${isMobile ? `${hasSecondButton ? '142' : '96'}px` : '64px'})`}
        sx={{ overflowY: 'auto' }}
      >
        <Grid container spacing={2}>
          {fields.map((field, index) => {
            const isFullWidth = field.fullWidth || index === 1
            return (
              <Fragment key={`${subTitles?.[index] ? `subtitle-${index}-` : ''}${field.name}`}>
                {subTitles?.[index] && (
                  <Grid item xs={12} mt={2}>
                    <Typography variant='h4' fontWeight='bold'>
                      {subTitles[index]}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={isFullWidth ? 12 : 6}>
                  <Box>
                    {field.showTitle && (
                      <InputLabel
                        sx={{
                          color: 'black',
                          mb: 1,
                          fontWeight: 'medium',
                        }}
                      >
                        {field.label}
                      </InputLabel>
                    )}
                    {renderField(field)}
                  </Box>
                </Grid>
              </Fragment>
            )
          })}
        </Grid>
      </Box>
      <Box
        display='flex'
        flexDirection={isMobile ? 'column-reverse': 'row'}
        justifyContent='space-between'
        gap={1}
        m={isMobile ? 0 : 2}
        p={isMobile ? 1 : 2}
        alignItems='stretch'
        sx={{
          position: 'sticky',
          bottom: 0,
          left: 0,
          width: '100%',
          height: 'fit-content',
        }}
      >
        <Button type='button' color='secondary' fullWidth onClick={onCancel}>
          Cancel
        </Button>
        {hasSecondButton && (
          <Button type='button' variant='outlined' fullWidth onClick={e => handleSubmit(e as any, true)}>
            {secondarySubmitText}
          </Button>
        )}
        <Button type='submit' variant='contained' fullWidth>
          {submitText}
        </Button>
      </Box>
    </Box>
  )
}

export default React.memo(CustomForm)
