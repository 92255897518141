import { ArrowBack, Delete } from '@mui/icons-material'
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography
} from '@mui/material'
import { useAuth } from 'contexts/AuthContext'
import {
  getVehicleModelOptions,
  getVehicleSizeOptions,
  makeOptions,
  vehicleTypeOptions,
} from 'core/constants/inventory'
import { Formula } from 'core/types/grayBook'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import { useFormulas } from 'hooks/useFormulas'
import React, { useState } from 'react'
import DeprecationFormulaBuilder from './views/DepreciationFormulaBuilder'

// TODO: abstract the listItem into a component

const DepreciationFormulas = () => {
  const [make, setMake] = useState('')
  const [model, setModel] = useState('')
  const [type, setType] = useState('')
  const [size, setSize] = useState('')
  const [fuelType, setFuelType] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const { userInfo } = useAuth()
  const [selectedFormula, setSelectedFormula] = useState<Formula | null>(null)
  const [isEditing, setIsEditing] = useState(false)
  const [editedName, setEditedName] = useState('')
  const [activeStep, setActiveStep] = useState(0)
  const [view, setView] = useState<'create' | 'list'>('list')
  const isMobile = useIsMobile()

  const { formulas, isLoading, addFormula, updateFormula, deleteFormula } = useFormulas({
    formulasCollection: 'gray_book_formulas',
  })

  const filteredFormulas = React.useMemo(() => {
    if (!formulas) return []
    return formulas.filter(formula => formula.name.toLowerCase().includes(searchTerm.toLowerCase()))
  }, [formulas, searchTerm])

  const generateFormulaName = React.useMemo(() => {
    if (!type || !size || !userInfo?.first_name || !userInfo?.last_name) {
      return ''
    }

    const date = new Date()
    const formattedDate = date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
    })
    const initials = userInfo.first_name.charAt(0).toUpperCase() + userInfo.last_name.charAt(0).toUpperCase()
    const formattedSize = size.charAt(0).toUpperCase() + size.slice(1)

    if (!make || !model) {
      return `${type} ${formattedSize} (${initials}-${formattedDate})`
    }

    const formattedMake = make.charAt(0).toUpperCase() + make.slice(1)
    const formattedModel = model.charAt(0).toUpperCase() + model.slice(1)

    return `${formattedMake} ${formattedModel} ${formattedSize} (${initials}-${formattedDate})`
  }, [model, make, size, userInfo?.first_name, userInfo?.last_name])

  const handleSubmitFormula = async (formula: Omit<Formula, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'vehicleType' | 'vehicleSize' | 'vehicleMake' | 'vehicleModel' | 'vehicleFuel'>) => {
    if (selectedFormula) {
      // Update existing formula
      updateFormula({
        id: selectedFormula.id,
        data: {
          ...formula,
          updatedAt: new Date().toISOString(),
        },
      })
    } else {
      // Add new formula
      const newFormula = {
        ...formula,
        name: generateFormulaName,
        maxPrice: formula.maxPrice || 100000,
        vehicleType: type,
        vehicleSize: size,
        vehicleMake: make,
        vehicleModel: model,
        vehicleFuel: fuelType,
      }
      await addFormula(newFormula)
      if (isMobile) {
        setView('list')
      }
    }
  }

  if (isLoading) {
    return <Box>Loading...</Box>
  }

  return (
    <Box>
      {selectedFormula ? (
        <Box>
          <Box sx={{ mb: 3 }}>
            {isEditing ? (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='h5' component='span' sx={{ mr: 1 }}>
                  Edit Formula:{' '}
                </Typography>
                <TextField
                  autoFocus
                  value={editedName}
                  onChange={e => setEditedName(e.target.value)}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      updateFormula({
                        id: selectedFormula.id,
                        data: {
                          ...selectedFormula,
                          name: editedName,
                          updatedAt: new Date().toISOString(),
                        },
                      })
                      setIsEditing(false)
                    }
                  }}
                  onBlur={() => setIsEditing(false)}
                  variant='standard'
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      fontSize: '1.5rem',
                      fontWeight: 400,
                      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                    },
                  }}
                  sx={{
                    '& .MuiInputBase-input': {
                      padding: 0,
                    },
                  }}
                />
              </Box>
            ) : (
              <Box sx={{ 
                display: 'flex', 
                alignItems: isMobile ? 'flex-start' : 'center',
                flexDirection: isMobile ? 'column' : 'row',
                gap: isMobile ? 2 : 4
              }}>
                <Button variant='outlined' onClick={() => setSelectedFormula(null)} startIcon={<ArrowBack />}>
                  Back to Formula List
                </Button>

                <Typography
                  onDoubleClick={() => {
                    setIsEditing(true)
                    setEditedName(selectedFormula.name)
                  }}
                  variant='h4'
                  sx={{
                    textAlign: isMobile ? 'left' : 'inherit'
                  }}
                >
                  {editedName || selectedFormula.name}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <Box sx={{ mb: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
            <Typography variant='h4'>Optic Gray Book</Typography>
            {isMobile && (
              <ToggleButtonGroup
                value={view}
                exclusive
                onChange={(e, newView) => newView && setView(newView)}
                size="small"
              >
                <ToggleButton value="list">List</ToggleButton>
                <ToggleButton value="create">Create</ToggleButton>
              </ToggleButtonGroup>
            )}
          </Box>
        </Box>
      )}

      {!selectedFormula && (
        <Box sx={{ display: 'flex', gap: 4, flexDirection: isMobile ? 'column' : 'row' }}>
          {(!isMobile || view === 'create') && (
            <Box sx={{ width: isMobile ? '100%' : '25%' }}>
              <Stepper activeStep={activeStep} orientation='vertical' sx={{ mb: 4 }}>
                <Step>
                  <StepLabel>Vehicle Type & Size</StepLabel>
                  <StepContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                      <Select
                        value={type}
                        onChange={e => setType(e.target.value)}
                        required
                        displayEmpty
                        variant='outlined'
                      >
                        <MenuItem value=''>
                          <em>Select Type</em>
                        </MenuItem>
                        {vehicleTypeOptions.map(option => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                      {type && (
                        <Select
                          value={size}
                          onChange={e => setSize(e.target.value)}
                          required
                          displayEmpty
                          variant='outlined'
                        >
                          <MenuItem value=''>
                            <em>Select Size</em>
                          </MenuItem>
                          {getVehicleSizeOptions(type).map(option => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <Button
                        variant='contained'
                        onClick={() => setActiveStep(prev => prev + 1)}
                        sx={{ mr: 1 }}
                        disabled={!type || !size}
                      >
                        Continue
                      </Button>
                    </Box>
                  </StepContent>
                </Step>

                <Step>
                  <StepLabel>Make & Model</StepLabel>
                  <StepContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                      <Select value={make} onChange={e => setMake(e.target.value)} required displayEmpty>
                        <MenuItem value=''>
                          <em>Select Make</em>
                        </MenuItem>
                        {makeOptions.map(option => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                      {make && (
                        <Select
                          value={model}
                          onChange={e => setModel(e.target.value)}
                          required
                          displayEmpty
                          variant='outlined'
                        >
                          <MenuItem value=''>
                            <em>Select Model</em>
                          </MenuItem>
                          {getVehicleModelOptions(make).map(option => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <Button
                        variant='contained'
                        onClick={() => setActiveStep(prev => prev + 1)}
                        sx={{ mr: 1 }}
                        disabled={!make || !model}
                      >
                        Continue
                      </Button>
                      <Button onClick={() => setActiveStep(prev => prev - 1)} sx={{ mr: 1 }}>
                        Back
                      </Button>
                    </Box>
                  </StepContent>
                </Step>

                <Step>
                  <StepLabel>Fuel Type</StepLabel>
                  <StepContent>
                    <Box>
                      <FormControl>
                        <RadioGroup value={fuelType} onChange={e => setFuelType(e.target.value)}>
                          <FormControlLabel value='gas' control={<Radio />} label='Gas' />
                          <FormControlLabel value='diesel' control={<Radio />} label='Diesel' />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <Button
                        variant='contained'
                        onClick={() => {
                          addFormula({
                            name: generateFormulaName,
                            maxPrice: 100000,
                            vehicleType: type,
                            vehicleSize: size,
                            vehicleMake: make,
                            vehicleModel: model,
                            vehicleFuel: fuelType,
                          })
                          setActiveStep(0)
                          setType('')
                          setSize('')
                          setMake('')
                          setModel('')
                          setFuelType('')
                        }}
                        sx={{ mr: 1 }}
                        disabled={!fuelType}
                      >
                        Create Formula
                      </Button>
                      <Button onClick={() => setActiveStep(prev => prev - 1)} sx={{ mr: 1 }}>
                        Back
                      </Button>
                    </Box>
                  </StepContent>
                </Step>
              </Stepper>
            </Box>
          )}

          {(!isMobile || view === 'list') && (
            <Box sx={{ width: isMobile ? '100%' : '75%' }}>
              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  variant='outlined'
                  placeholder='Search formulas...'
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                  size='small'
                />
              </Box>

              <List>
                {filteredFormulas.map((formula, index) => (
                  <ListItem
                    key={formula.id}
                    onClick={() => setSelectedFormula(formula)}
                    sx={{
                      backgroundColor: index % 2 === 0 ? 'background.paper' : 'action.hover',
                      '&:hover': {
                        backgroundColor: 'action.selected',
                        cursor: 'pointer',
                      },
                      borderBottom: '1px solid',
                      borderColor: 'divider',
                      py: 2,
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography variant='subtitle1' component='div' sx={{ fontWeight: 'medium' }}>
                          {formula.name}
                        </Typography>
                      }
                      secondary={
                        <Typography variant='body2' color='text.secondary' sx={{ mt: 0.5 }}>
                          Created: {new Date(formula.createdAt || '').toLocaleString()}
                        </Typography>
                      }
                    />
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Tooltip title='Delete'>
                        <IconButton
                          aria-label='delete'
                          onClick={event => {
                            event.stopPropagation()
                            deleteFormula(formula.id)
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </Box>
      )}

      {selectedFormula && (
        <DeprecationFormulaBuilder formula={selectedFormula} submitFormula={handleSubmitFormula} />
      )}
    </Box>
  )
}

export default DepreciationFormulas
