import { Paper, Typography, Box, useTheme } from '@mui/material'
import { LineChart } from '@mui/x-charts'
import { useMemo } from 'react'
import { formatPrice } from 'core/utils/inventoryUtils'
import React from 'react'

interface SalesTrendChartProps {
  vehicles: any[]
  dateRange: { start: Date; end: Date }
}

export const SalesTrendChart = ({ vehicles, dateRange }: SalesTrendChartProps) => {
  const theme = useTheme()

  const parseDate = (dateValue: any): Date | null => {
    try {
      if (!dateValue) return null

      // Handle Firestore Timestamp
      if (dateValue?.seconds) {
        return new Date(dateValue.seconds * 1000)
      }

      // Handle string dates with "at" format
      if (typeof dateValue === 'string' && dateValue.includes(' at ')) {
        const cleanedDate = dateValue.replace(' at ', ' ')
        const date = new Date(cleanedDate)
        if (!isNaN(date.getTime())) {
          return date
        }
      }

      // Handle string dates
      if (typeof dateValue === 'string') {
        const date = new Date(dateValue)
        return isNaN(date.getTime()) ? null : date
      }

      // Handle Date objects
      if (dateValue instanceof Date) {
        return isNaN(dateValue.getTime()) ? null : dateValue
      }

      return null
    } catch (error) {
      console.error('Error parsing date:', dateValue, error)
      return null
    }
  }

  const chartData = useMemo(() => {
    // Filter sold vehicles within date range and group by day
    const salesByDay = vehicles
      .filter(v => {
        if (v.status?.toLowerCase() !== 'sold' || !v.sold_date) return false
        const soldDate = parseDate(v.sold_date)
        if (!soldDate) return false
        return soldDate >= dateRange.start && soldDate <= dateRange.end
      })
      .reduce((acc: Record<string, { sales: number; revenue: number }>, vehicle) => {
        const date = parseDate(vehicle.sold_date)
        if (!date) return acc

        // Format date as YYYY-MM-DD
        const dayKey = date.toISOString().split('T')[0]
        
        if (!acc[dayKey]) {
          acc[dayKey] = { sales: 0, revenue: 0 }
        }
        
        acc[dayKey].sales++
        acc[dayKey].revenue += parseFloat(vehicle.optic_list_price) || 0
        
        return acc
      }, {})

    // Sort dates chronologically
    const sortedDates = Object.keys(salesByDay).sort()

    // Format dates for display
    const formatDate = (dateStr: string) => {
      const date = new Date(dateStr)
      return date.toLocaleDateString('en-US', { 
        month: 'short', 
        day: 'numeric'
      })
    }

    return {
      xAxis: sortedDates.map(formatDate),
      sales: sortedDates.map(d => salesByDay[d].sales),
      revenue: sortedDates.map(d => salesByDay[d].revenue)
    }
  }, [vehicles, dateRange])

  if (chartData.xAxis.length === 0) {
    return (
      <Paper sx={{ p: 3, height: 400 }}>
        <Typography variant="h6" gutterBottom>
          Daily Sales Trend
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 300 }}>
          <Typography color="text.secondary">No sales data available for selected date range</Typography>
        </Box>
      </Paper>
    )
  }

  return (
    <Paper sx={{ p: 3, height: 400 }}>
      <Typography variant="h6" gutterBottom>
        Daily Sales Trend
      </Typography>
      <Box sx={{ width: '100%', height: 300 }}>
        <LineChart
          series={[
            {
              data: chartData.sales,
              label: 'Units Sold',
              area: true,
              color: theme.palette.primary.main,
              valueFormatter: (value: number | null) => 
                value !== null ? `${value} units` : '0 units',
              connectNulls: true
            },
            {
              data: chartData.revenue,
              label: 'Revenue',
              color: theme.palette.secondary.main,
              valueFormatter: (value: number | null) => 
                value !== null ? formatPrice(value.toString()) : '$0',
              connectNulls: true
            }
          ]}
          xAxis={[{
            data: chartData.xAxis,
            scaleType: 'band',
            tickLabelStyle: {
              angle: 45,
              textAnchor: 'start',
              fontSize: 12
            }
          }]}
          height={300}
          margin={{ left: 70, right: 20, top: 20, bottom: 50 }}
          slotProps={{
            legend: {
              direction: 'row',
              position: { vertical: 'top', horizontal: 'right' },
              padding: 0
            }
          }}
        />
      </Box>
    </Paper>
  )
}
