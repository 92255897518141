/* eslint-disable */

// TODO: Add type definitions and fix eslint here

import { Alert, Box, Button, Dialog, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import CloseIcon from '@mui/icons-material/Close'
import { IsLoading } from 'components'
import { adminFunctions } from 'core/api/application/admin'

interface TruckDebtOverhaulSubmission {
  client_id: number
  current_credit_score: number
  monthly_truck_payment: number
  number_of_trucks: number
  status: string
  submission_id: number
  time_created: string
  vehicles: DebtOverhaulTruck[]
}

function createData(
  submissionId: number,
  dateSubmitted: Date,
  creditScore: number,
  numOfTrucks: number,
  monthlyTruckPayment: number,
  status: 'UNREVIEWED' | 'PENDING' | 'COMPLETED',
  trucks: any,
) {
  return {
    submissionId,
    dateSubmitted,
    creditScore,
    numOfTrucks,
    monthlyTruckPayment,
    status,
    trucks,
  }
}

function Row(props: { row: ReturnType<typeof createData>; rowStatusChanged: (index: number) => void }) {
  const { row, rowStatusChanged } = props
  const [open, setOpen] = React.useState(false)

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row'>
          {row.dateSubmitted.toDateString()}
        </TableCell>
        <TableCell align='center'>{row.creditScore}</TableCell>
        <TableCell align='right'>{row.numOfTrucks}</TableCell>
        <TableCell align='right'>{row.monthlyTruckPayment}</TableCell>
        <TableCell align='center'>
          {row.status === 'UNREVIEWED' && (
            <Button
              size='small'
              variant='contained'
              color='info'
              onClick={() => {
                const response = adminFunctions.markSubmissionAsPending(row.submissionId)
                if (!response) 
                rowStatusChanged(row.submissionId)
              }}
            >
              Mark Pending
            </Button>
          )}

          {(row.status === 'UNREVIEWED' || row.status === 'PENDING') && (
            <Button
              size='small'
              variant='contained'
              color='success'
              sx={{ marginLeft: 1 }}
              onClick={() => {
                const response = adminFunctions.markSubmissionAsCompleted(row.submissionId)
                if (!response) 
                rowStatusChanged(row.submissionId)
              }}
            >
              Mark Completed
            </Button>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant='h6' gutterBottom component='div'>
                Truck Details
              </Typography>
              <Table size='small' aria-label='purchases'>
                <TableHead>
                  <TableRow>
                    <TableCell>Year</TableCell>
                    <TableCell>Fuel Type</TableCell>
                    <TableCell>VIN</TableCell>
                    <TableCell>Model</TableCell>
                    <TableCell>Size</TableCell>
                    <TableCell>Miles</TableCell>
                    <TableCell align='right'>Current Payoff ($)</TableCell>
                    <TableCell align='right'>Monthly Payment ($)</TableCell>
                    <TableCell>Purchase Date</TableCell>
                    <TableCell>Bank</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.trucks.map((truckRow: any) => (
                    <TableRow key={truckRow.VIN}>
                      <TableCell component='th' scope='row'>
                        {truckRow.year}
                      </TableCell>
                      <TableCell>{truckRow.fuel_type}</TableCell>
                      <TableCell>{truckRow.VIN}</TableCell>
                      <TableCell>{truckRow.model}</TableCell>
                      <TableCell>{truckRow.size}</TableCell>
                      <TableCell>{truckRow.miles.toLocaleString('en-US')}</TableCell>
                      <TableCell align='right'>{truckRow.current_payoff}</TableCell>
                      <TableCell align='right'>{truckRow.monthly_payment}</TableCell>
                      <TableCell>{truckRow.purchase_date}</TableCell>
                      <TableCell>{truckRow.bank}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

const ViewTruckDebtOverhaulSubmissions = ({ clientId, status, isOpen, onClose }: any): JSX.Element => {
  const [rows, setRows] = useState<
    {
      submissionId: number
      dateSubmitted: Date
      creditScore: number
      numOfTrucks: number
      monthlyTruckPayment: number
      status: 'UNREVIEWED' | 'PENDING' | 'COMPLETED'
      trucks: any
    }[]
  >([])

  const [isLoading, setIsLoading] = useState(true)

  const [error, setError] = useState({
    isError: false,
    errorMessage: '',
  })

  const resetErrorState = () => {
    setError({ isError: false, errorMessage: '' })
  }

  const rowStatusChanged = (submissionId: number) => {
    setRows(prevState =>
      prevState.filter(row => {
        return row.submissionId !== submissionId
      }),
    )
  }

  const fetchTruckDebtOverhaulSubmissions = async () => {
    setIsLoading(true)
    if (clientId && clientId.trim() !== '') {
      const submissionsData = await adminFunctions.getTruckDebtOverhaulSubmissions(clientId, status)

      if (!submissionsData) {
        setError({
          isError: true,
          errorMessage: `Error: Failed to fetch submission data. Please try again or contact support.`,
        })
        setIsLoading(false)
        return
      }

      const submissions = await Promise.all(
        submissionsData.map(async (submission: TruckDebtOverhaulSubmission) => {
          const trucks = await adminFunctions.getTrucksForSubmission(submission.submission_id)

          if (trucks['status'] === 'ok' && trucks['trucks']) {
            return {
              id: submission.submission_id,
              date_submitted: submission.time_created,
              number_of_trucks: submission.number_of_trucks,
              monthly_truck_payment: submission.monthly_truck_payment,
              current_credit_score: submission.current_credit_score,
              status: submission.status,
              trucks: trucks['trucks'],
            }
          }
        }),
      )

      const rows = submissions.map((submission: any, index: number) =>
        createData(
          submission.id,
          new Date(submission.date_submitted),
          submission.current_credit_score,
          submission.number_of_trucks,
          submission.monthly_truck_payment,
          submission.status,
          submission.trucks,
        ),
      )

      setRows(rows)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchTruckDebtOverhaulSubmissions()
  }, [clientId, status])

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        onClose()
        resetErrorState()
      }}
      maxWidth='xl'
      fullWidth
    >
      <DialogTitle fontSize={18} fontWeight={'bold'}>
        Truck Debt Overhaul Info
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%' }}>
          {isLoading ? (
            <IsLoading />
          ) : (
            <TableContainer component={Paper} sx={{ marginTop: 5 }}>
              <Table aria-label='collapsible table'>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Date Submitted</TableCell>
                    <TableCell>Credit Score</TableCell>
                    <TableCell align='right'>Number of Trucks</TableCell>
                    <TableCell align='right'>Monthly Truck Payment ($)</TableCell>
                    <TableCell align='center'>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <Row key={index} row={row} rowStatusChanged={rowStatusChanged} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
        {error.isError && (
          <Collapse in={error.isError}>
            <Alert
              severity='error'
              action={
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={() => {
                    resetErrorState()
                  }}
                >
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {error.errorMessage}
            </Alert>
          </Collapse>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ViewTruckDebtOverhaulSubmissions
