import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAuth } from 'contexts/AuthContext'
import { useSnackbar } from 'contexts/snackBarContext'
import { db } from 'core/config/firebase'
import { addDoc, collection, deleteDoc, doc, getDocs, updateDoc } from 'firebase/firestore'
import { Formula } from 'core/types/grayBook'
import { formulaConverter } from 'converters/formulaConverter'

interface UseFormulasOptions {
  onSuccess?: () => void
  onError?: (error: Error) => void
  dontShowUpdates?: boolean
}

interface UseFormulasParams {
  formulasCollection: string
  options?: UseFormulasOptions
}

export const useFormulas = ({ formulasCollection, options }: UseFormulasParams) => {
  const { showSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { userInfo } = useAuth()

  const formulasCollectionRef = collection(db, formulasCollection).withConverter(formulaConverter)

  const queryKey = [formulasCollection]

  const {
    data: formulas,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery<Formula[]>({
    queryKey,
    queryFn: async () => {
      const snapshot = await getDocs(formulasCollectionRef)
      return snapshot.docs.map(doc => ({
        // @ts-expect-error - overwrite incase an id field is accidently written
        id: doc.id,
        ...doc.data(),
      }))
    },
    select: (data) => data.sort((a, b) => (b.createdAt || '').localeCompare(a.createdAt || '')),
    staleTime: 1000 * 60 * 5, // 5 minutes
  })

  const addFormula = useMutation({
    mutationFn: async (formula: Omit<Formula, 'id' | 'createdAt' | 'updatedAt'>) => {
      const newFormula = {
        ...formula,
        createdBy: userInfo?.email || '',
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      } as Formula
      
      const docRef = await addDoc(formulasCollectionRef, formulaConverter.toFirestore(newFormula))
      
      return {
        ...newFormula,
        id: docRef.id
      } as Formula
    },
    onMutate: async (newFormula) => {
      await queryClient.cancelQueries({ queryKey })
      const previousFormulas = queryClient.getQueryData<Formula[]>(queryKey)

      const optimisticFormula = {
        ...newFormula,
        id: `temp-${Date.now()}`,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        createdBy: userInfo?.email || '',
      }

      queryClient.setQueryData<Formula[]>(queryKey, old => {
        return [...(old || []), optimisticFormula]
      })

      return { previousFormulas, optimisticFormula }
    },
    onError: (err, newFormula, context) => {
      queryClient.setQueryData(queryKey, context?.previousFormulas)
      if (!options?.dontShowUpdates) {
        showSnackbar('Error adding formula', 'error')
      }
      console.error('Error adding formula:', err)
      options?.onError?.(err)
    },
    onSuccess: (result, variables, context) => {
      queryClient.setQueryData<Formula[]>(queryKey, old => {
        return old?.map(formula =>
          formula.id === context?.optimisticFormula.id ? result : formula
        )
      })
      if (!options?.dontShowUpdates) {
        showSnackbar('Formula added successfully', 'success')
      }
      options?.onSuccess?.()
    },
  })

  const updateFormula = useMutation({
    mutationFn: async ({ id, data }: { id: string; data: Partial<Formula> }) => {
      const formulaRef = doc(formulasCollectionRef, id)
      const updateData = formulaConverter.toFirestore({
        ...data,
        updatedAt: new Date().toISOString(),
      } as Formula)
      await updateDoc(formulaRef, updateData)
      return { id, ...data }
    },
    onMutate: async ({ id, data }) => {
      await queryClient.cancelQueries({ queryKey })
      const previousFormulas = queryClient.getQueryData<Formula[]>(queryKey)

      queryClient.setQueryData<Formula[]>(queryKey, old => {
        if (!old) return old
        return old.map(formula =>
          formula.id === id ? { ...formula, ...data } : formula
        )
      })

      return { previousFormulas }
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(queryKey, context?.previousFormulas)
      if (!options?.dontShowUpdates) {
        showSnackbar('Error updating formula', 'error')
      }
      console.error('Error updating formula:', err)
      options?.onError?.(err)
    },
    onSuccess: (result) => {
      if (!options?.dontShowUpdates) {
        showSnackbar('Formula updated successfully', 'success')
      }
      options?.onSuccess?.()
    },
  })

  const deleteFormula = useMutation({
    mutationFn: async (id: string) => {
      const formulaRef = doc(formulasCollectionRef, id)
      await deleteDoc(formulaRef)
      return id
    },
    onMutate: async (id) => {
      await queryClient.cancelQueries({ queryKey })
      const previousFormulas = queryClient.getQueryData<Formula[]>(queryKey)

      queryClient.setQueryData<Formula[]>(queryKey, old => {
        if (!old) return old
        return old.filter(formula => formula.id !== id)
      })

      return { previousFormulas }
    },
    onError: (err, id, context) => {
      queryClient.setQueryData(queryKey, context?.previousFormulas)
      if (!options?.dontShowUpdates) {
        showSnackbar('Error deleting formula', 'error')
      }
      console.error('Error deleting formula:', err)
      options?.onError?.(err)
    },
    onSuccess: () => {
      if (!options?.dontShowUpdates) {
        showSnackbar('Formula deleted successfully', 'success')
      }
      options?.onSuccess?.()
    },
  })

  return {
    formulas,
    isLoading,
    isError,
    error,
    refetchFormulas: refetch,
    addFormula: addFormula.mutateAsync,
    updateFormula: updateFormula.mutateAsync,
    deleteFormula: deleteFormula.mutateAsync,
  }
}
