import { deleteDoc, doc } from '@firebase/firestore'
import { db } from 'core/config/firebase'
import { deleteObject, getStorage, listAll, ref } from 'firebase/storage'

export const removeFromWebsiteInventory = async (inventoryId: string) => {
    const storage = getStorage()

    // Reference to the storage location in website_inventory
    const storageRef = ref(storage, `website_inventory/${inventoryId}`)

    // Get all objects in the website_inventory path
    const storageObjects = await listAll(storageRef)

    // Delete each file in the website_inventory/{inventoryId} path
    for (const item of storageObjects.items) {
        await deleteObject(item)
    }

    // Delete the document from website_inventory
    const websiteDocRef = doc(db, 'website_inventory', inventoryId)
    await deleteDoc(websiteDocRef)

    
}

export default removeFromWebsiteInventory
