import { Box, Chip, TextField, Typography, InputAdornment, IconButton, ToggleButtonGroup, ToggleButton, styled, useTheme, useMediaQuery, Collapse } from '@mui/material'
import { ContactSubmission, RFISubmission, SellSubmission } from 'core/types/form_submission'
import { useSubmissions } from 'hooks/useSubmissions'
import { useState, useMemo } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { startOfDay, startOfWeek, startOfMonth, format } from 'date-fns'
import { ContactDetail } from '../SubmissionDetail/ContactDetail'
import { RFIDetail } from '../SubmissionDetail/RFIDetail'
import { SellDetail } from '../SubmissionDetail/SellDetail'
import React from 'react'
import InboxIcon from '@mui/icons-material/Inbox'
import FilterListIcon from '@mui/icons-material/FilterList'

const TIME_FILTERS = ['All Time', 'Today', 'Last Week', 'This Month'] as const
const TYPE_FILTERS = ['All', 'Sell Form', 'Vehicle Interest', 'Contact'] as const
const TYPE_FILTER_VALUES = {
  'All': 'all',
  'Sell Form': 'sell',
  'Vehicle Interest': 'rfi',
  'Contact': 'contact'
} as const
const STATUS_FILTERS = ['All', 'Unread', 'Read'] as const

type TimeFilter = typeof TIME_FILTERS[number]
type TypeFilter = typeof TYPE_FILTERS[number]
type StatusFilter = typeof STATUS_FILTERS[number]

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  '& .MuiToggleButtonGroup-grouped': {
    border: 'none',
    borderRadius: theme.shape.borderRadius + 'px !important',
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  padding: theme.spacing(0.5, 1.5),
  textTransform: 'none',
  fontSize: '0.875rem',
  minWidth: 'fit-content',
  flex: '0 0 auto',
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  '&:not(.Mui-selected)': {
    color: theme.palette.text.primary,
  },
}))

const FORM_TYPE_LABELS = {
  'sell': 'Sell Form',
  'rfi': 'Vehicle Interest',
  'contact': 'Contact'
} as const

const FiltersContent = React.memo(({ 
  searchQuery, 
  setSearchQuery,
  statusFilter,
  setStatusFilter,
  timeFilter,
  setTimeFilter,
  typeFilter,
  setTypeFilter 
}: {
  searchQuery: string;
  setSearchQuery: (value: string) => void;
  statusFilter: StatusFilter;
  setStatusFilter: (value: StatusFilter) => void;
  timeFilter: TimeFilter;
  setTimeFilter: (value: TimeFilter) => void;
  typeFilter: TypeFilter;
  setTypeFilter: (value: TypeFilter) => void;
}) => (
  <>
    <TextField
      placeholder="Search submissions..."
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      size="small"
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
    
    <Typography variant="overline" color="text.secondary">
      Status
    </Typography>
    <StyledToggleButtonGroup
      value={statusFilter}
      exclusive
      onChange={(_, value) => value && setStatusFilter(value)}
      aria-label="status filter"
    >
      {STATUS_FILTERS.map(filter => (
        <StyledToggleButton key={filter} value={filter}>
          {filter}
        </StyledToggleButton>
      ))}
    </StyledToggleButtonGroup>

    <Typography variant="overline" color="text.secondary">
      Time Period
    </Typography>
    <StyledToggleButtonGroup
      value={timeFilter}
      exclusive
      onChange={(_, value) => value && setTimeFilter(value)}
      aria-label="time filter"
    >
      {TIME_FILTERS.map(filter => (
        <StyledToggleButton key={filter} value={filter}>
          {filter}
        </StyledToggleButton>
      ))}
    </StyledToggleButtonGroup>

    <Typography variant="overline" color="text.secondary">
      Type
    </Typography>
    <StyledToggleButtonGroup
      value={typeFilter}
      exclusive
      onChange={(_, value) => value && setTypeFilter(value)}
      aria-label="type filter"
    >
      {TYPE_FILTERS.map(filter => (
        <StyledToggleButton key={filter} value={filter}>
          {filter}
        </StyledToggleButton>
      ))}
    </StyledToggleButtonGroup>
  </>
));

const SubmissionManagement = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [filtersOpen, setFiltersOpen] = useState(false)
  const [selectedSubmission, setSelectedSubmission] = useState<ContactSubmission | SellSubmission | RFISubmission | null>(null)
  const [timeFilter, setTimeFilter] = useState<TimeFilter>('All Time')
  const [typeFilter, setTypeFilter] = useState<TypeFilter>('All')
  const [statusFilter, setStatusFilter] = useState<StatusFilter>('All')
  const [searchQuery, setSearchQuery] = useState('')
  
  const { submissions, isLoading } = useSubmissions()

  // Helper function to convert Firestore timestamp to Date
  const timestampToDate = (timestamp: { seconds: number; nanoseconds: number }) => {
    return new Date(timestamp.seconds * 1000)
  }

  // Filter submissions based on time, type and status
  const filteredSubmissions = useMemo(() => {
    if (!submissions) return []

    const now = new Date()
    let dateThreshold: Date

    // Set time threshold based on filter
    switch (timeFilter) {
      case 'Today':
        dateThreshold = startOfDay(now)
        break
      case 'Last Week':
        dateThreshold = startOfWeek(now)
        break
      case 'This Month':
        dateThreshold = startOfMonth(now)
        break
      case 'All Time':
      default:
        dateThreshold = new Date(0)
    }

    return submissions
      .filter(submission => {
        const submissionDate = timestampToDate(submission.createdAt)
        const matchesTime = submissionDate >= dateThreshold
        const matchesType = typeFilter === 'All' || 
          submission.type.toUpperCase() === TYPE_FILTER_VALUES[typeFilter].toUpperCase()
        
        // Updated status filtering logic
        const isUnread = submission.status === 'New'
        const isRead = ['Read', 'Completed'].includes(submission.status as string)
        const matchesStatus = statusFilter === 'All' || 
          (statusFilter === 'Unread' && isUnread) ||
          (statusFilter === 'Read' && isRead)

        const matchesSearch = searchQuery 
          ? submission.firstName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            submission.lastName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            submission.email?.toLowerCase().includes(searchQuery.toLowerCase())
          : true

        return matchesTime && matchesType && matchesStatus && matchesSearch
      })
  }, [submissions, timeFilter, typeFilter, statusFilter, searchQuery])

  const renderSubmissionMessage = (submission: ContactSubmission | SellSubmission | RFISubmission) => {
    const messageCharLimit = isMobile ? 30 : 100;
    const vehicleLimit = isMobile ? 1 : 3;
    
    return (
      <Box
        key={submission.id}
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          mb: 2,
          px: isMobile ? 1 : 2,
        }}
      >
        <Box
          sx={{
            flex: 1,
            p: isMobile ? 1.5 : 2,
            borderRadius: 2,
            bgcolor: selectedSubmission?.id === submission.id 
              ? 'primary.main' 
              : 'grey.100',
            color: selectedSubmission?.id === submission.id ? 'white' : 'text.primary',
            cursor: 'pointer',
            '&:hover': {
              bgcolor: selectedSubmission?.id === submission.id 
                ? 'primary.dark' 
                : 'grey.200',
            },
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'space-between',
            position: 'relative',
          }}
          onClick={() => setSelectedSubmission(submission)}
        >
          <Box flex={1}>
            <Box 
              display="flex" 
              alignItems="center" 
              gap={1} 
              mb={1}
              flexWrap="wrap"
            >
              <Chip 
                size="small" 
                label={FORM_TYPE_LABELS[submission.formType]}
                color={
                  submission.formType === 'sell' ? 'info' :
                  submission.formType === 'rfi' ? 'success' :
                  'primary'
                }
              />
              <Chip 
                size="small" 
                label={
                  submission.formType === 'sell' && submission.status === 'New' ? 'Ready' :
                  submission.status === 'Completed' ? 'Completed' :
                  submission.status === 'Canceled' ? 'Cancelled' :
                  submission.status === 'New' ? 'Unread' :
                  'Read'
                }
                variant={submission.status === 'New' ? 'filled' : 'outlined'}
                color={
                  submission.status === 'New' ? 'error' :
                  submission.status === 'Completed' ? 'success' :
                  submission.status === 'Canceled' ? 'warning' :
                  'default'
                }
              />
            </Box>

            <Typography 
              variant={isMobile ? 'body1' : 'h6'} 
              gutterBottom
              sx={{ 
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              {`${submission.firstName} ${submission.lastName}`}
            </Typography>

            <Typography 
              variant="body2" 
              color="text.secondary" 
              gutterBottom
              sx={{ 
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              {submission.email} • {submission.phone}
            </Typography>

            {submission.formType === 'sell' && Array.isArray(submission.vehicles) && submission.vehicles.length > 0 && (
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ 
                  mt: 0.5,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                {submission.vehicles
                  .filter(v => v?.year && v?.make && v?.model)
                  .slice(0, vehicleLimit)
                  .map(v => `${v.year} ${v.make} ${v.model}`)
                  .join(', ')}
                {submission.vehicles.length > vehicleLimit && ` and ${submission.vehicles.length - vehicleLimit} more`}
              </Typography>
            )}

            {submission.formType === 'rfi' && submission.stockNumber && (
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ 
                  mt: 0.5,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                Stock #{submission.stockNumber}
              </Typography>
            )}

            {submission.formType === 'contact' && submission.message && (
              <Typography 
                variant="body2" 
                sx={{ 
                  mt: 1,
                  bgcolor: 'rgba(0,0,0,0.03)',
                  p: 1,
                  borderRadius: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                {submission.message.length > 30 
                  ? `${submission.message.slice(0, 30)}...`
                  : submission.message
                }
              </Typography>
            )}
          </Box>
          
          <Box sx={{ 
            ml: isMobile ? 0 : 2, 
            mt: isMobile ? 1 : 0,
            textAlign: isMobile ? 'left' : 'right',
            borderTop: isMobile ? `1px solid ${theme.palette.divider}` : 'none',
            pt: isMobile ? 1 : 0
          }}>
            <Typography variant="caption" sx={{ opacity: 0.7 }}>
              {format(new Date(submission.createdAt.seconds * 1000), isMobile ? 'PP' : 'PPp')}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  if (selectedSubmission) {
    return (
      <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
        <Box 
          sx={{ 
            p: 2, 
            borderBottom: 1, 
            borderColor: 'divider',
            bgcolor: 'background.paper',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IconButton 
            onClick={() => setSelectedSubmission(null)}
            sx={{ mr: 2 }}
            aria-label="back to submissions list"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6">
            {selectedSubmission.formType === 'sell' ? 'Sell Request' :
             selectedSubmission.formType === 'rfi' ? 'Vehicle Interest' :
             'Contact Message'}
          </Typography>
        </Box>
        <Box sx={{ flex: 1, overflow: 'auto' }}>
          {selectedSubmission.formType === 'contact' && (
            <ContactDetail submission={selectedSubmission} />
          )}
          {selectedSubmission.formType === 'rfi' && (
            <RFIDetail submission={selectedSubmission} />
          )}
          {selectedSubmission.formType === 'sell' && (
            <SellDetail submission={selectedSubmission} />
          )}
        </Box>
      </Box>
    )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', height: '100vh' }}>
      {isMobile ? (
        <Box sx={{ 
          borderBottom: 1, 
          borderColor: 'divider',
          bgcolor: 'background.paper',
          zIndex: 1,
        }}>
          <Box sx={{ 
            p: 2, 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between'
          }}>
            <Typography variant="subtitle1" component="div">
              Filters
            </Typography>
            <IconButton 
              onClick={() => setFiltersOpen(!filtersOpen)}
              color={filtersOpen ? 'primary' : 'default'}
            >
              <FilterListIcon />
            </IconButton>
          </Box>
          <Collapse in={filtersOpen}>
            <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
              <FiltersContent
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                statusFilter={statusFilter}
                setStatusFilter={setStatusFilter}
                timeFilter={timeFilter}
                setTimeFilter={setTimeFilter}
                typeFilter={typeFilter}
                setTypeFilter={setTypeFilter}
              />
            </Box>
          </Collapse>
        </Box>
      ) : (
        <Box sx={{ 
          width: '300px', 
          borderRight: 1, 
          borderColor: 'divider',
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          bgcolor: 'background.paper',
          zIndex: 1,
          height: '100%',
          overflow: 'auto'
        }}>
          <FiltersContent
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
            timeFilter={timeFilter}
            setTimeFilter={setTimeFilter}
            typeFilter={typeFilter}
            setTypeFilter={setTypeFilter}
          />
        </Box>
      )}

      {/* Submissions List */}
      <Box 
        sx={{ 
          flex: 1, 
          overflow: 'auto',
          bgcolor: 'background.default',
        }}
      >
        {filteredSubmissions.length > 0 ? (
          filteredSubmissions.map(renderSubmissionMessage)
        ) : (
          <Box 
            display="flex" 
            flexDirection="column" 
            alignItems="center" 
            justifyContent="center" 
            minHeight="calc(100vh - 100px)"
            sx={{ 
              backgroundColor: 'background.paper',
              borderRadius: 2,
              p: 4,
              m: 2,
              textAlign: 'center'
            }}
          >
            <InboxIcon sx={{ fontSize: 80, color: 'text.secondary', mb: 3, opacity: 0.5 }} />
            <Typography variant="h5" color="text.secondary" gutterBottom fontWeight="medium">
              No Submissions Found
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ maxWidth: 500, opacity: 0.8 }}>
              {searchQuery 
                ? `No submissions match your search "${searchQuery}"`
                : `No ${typeFilter.toLowerCase() !== 'all' ? typeFilter : ''} submissions found for the selected time period`
              }
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default SubmissionManagement
