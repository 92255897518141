import { 
  Dialog, DialogTitle, DialogContent, TextField, Table, 
  TableBody, TableCell, TableContainer, TableHead, TableRow, 
  Paper, IconButton, InputAdornment
} from '@mui/material'
import { Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material'
import { useState, useMemo } from 'react'
import { formatPrice } from 'core/utils/inventoryUtils'
import React from 'react'

interface PendingSalesModalProps {
  open: boolean
  onClose: () => void
  vehicles: any[] | undefined
}

export const PendingSalesModal = ({ open, onClose, vehicles = [] }: PendingSalesModalProps) => {
  const [searchTerm, setSearchTerm] = useState('')

  const pendingVehicles = useMemo(() => {
    if (!vehicles) return []
    
    return vehicles
      .filter(v => v.status?.toLowerCase() === 'pending sale')
      .filter(v => 
        Object.values(v).some(value => 
          String(value).toLowerCase().includes(searchTerm.toLowerCase())
        )
      )
  }, [vehicles, searchTerm])

  const formatStatusDate = (dateString: string | undefined): string => {
    if (!dateString) return 'No Date'
    try {
      const date = new Date(dateString)
      return isNaN(date.getTime()) ? 'Invalid Date' : date.toLocaleDateString()
    } catch {
      return 'Invalid Date'
    }
  }

  const calculateDaysPending = (statusDate: string | undefined): string => {
    if (!statusDate) return '-'
    try {
      const date = new Date(statusDate)
      if (isNaN(date.getTime())) return '-'
      
      const days = Math.floor(
        (new Date().getTime() - date.getTime()) / (1000 * 60 * 60 * 24)
      )
      return days.toString()
    } catch {
      return '-'
    }
  }

  const formatPriceOrDash = (value: string | undefined): string => {
    if (!value || value === '0' || value === '0.00') return '-'
    return formatPrice(value)
  }

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        Pending Sales Details
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      
      <DialogContent>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search vehicles..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ mb: 2 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />

        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>VIN</TableCell>
                <TableCell>Stock #</TableCell>
                <TableCell>Year</TableCell>
                <TableCell>Make</TableCell>
                <TableCell>Model</TableCell>
                <TableCell align="right">List Price</TableCell>
                <TableCell align="right">Expected Profit</TableCell>
                <TableCell align="right">Days Pending</TableCell>
                <TableCell>Date Added</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pendingVehicles.map((vehicle) => (
                <TableRow key={vehicle.vin}>
                  <TableCell>{vehicle.vin || '-'}</TableCell>
                  <TableCell>{vehicle.stock_number || '-'}</TableCell>
                  <TableCell>{vehicle.year || '-'}</TableCell>
                  <TableCell>{vehicle.make || '-'}</TableCell>
                  <TableCell>{vehicle.model || '-'}</TableCell>
                  <TableCell align="right">
                    {formatPriceOrDash(vehicle.optic_list_price?.toString())}
                  </TableCell>
                  <TableCell align="right">
                    {formatPriceOrDash(vehicle.profit?.toString())}
                  </TableCell>
                  <TableCell align="right">
                    {calculateDaysPending(vehicle.date_added)}
                  </TableCell>
                  <TableCell>
                    {formatStatusDate(vehicle.date_added)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  )
}
