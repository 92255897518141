import { FirestoreDataConverter, DocumentSnapshot, SnapshotOptions, WithFieldValue } from 'firebase/firestore'
import { Formula } from 'core/types/grayBook'

export const formulaConverter: FirestoreDataConverter<Formula> = {
  toFirestore(formula: WithFieldValue<Formula>) {
    const cleanedFormula = Object.fromEntries(
      Object.entries(formula).filter(([key, value]) => value !== undefined && key !== 'id'),
    )
    return cleanedFormula
  },
  fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions): Formula {
    const data = snapshot.data(options)
    return {
      id: snapshot.id,
      name: data?.name || '',
      vehicleType: data?.vehicleType || '',
      vehicleSize: data?.vehicleSize || '',
      vehicleMake: data?.vehicleMake || '',
      vehicleModel: data?.vehicleModel || '',
      vehicleFuel: data?.vehicleFuel || '',
      maxPrice: data?.maxPrice || 0,
      minPrice: data?.minPrice || undefined,
      wholesaleDiscount: data?.wholesaleDiscount || undefined,
      createdBy: data?.createdBy || '',
      createdAt: data?.createdAt || '',
      updatedAt: data?.updatedAt || '',
    }
  },
}
