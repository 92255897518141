import React, { useMemo, useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  InputAdornment,
  CircularProgress,
  Divider,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useManageUsers } from 'hooks/useManageUsers';
import { UserInfoType } from 'core/types/users';
import { collection, doc, setDoc } from 'firebase/firestore';
import { db } from 'core/config/firebase';
import { CreateUser } from 'views/SuperAdmin/Management/UserManagement/components/CreateUser'
import { useQueryClient } from '@tanstack/react-query';

interface SoldPopupProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (soldTo: string) => void;
  itemName?: string;
}

const SoldPopup: React.FC<SoldPopupProps> = ({ open, onClose, onSubmit, itemName }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const queryClient = useQueryClient();
  const { users, isLoading } = useManageUsers();
  const [showCreateUser, setShowCreateUser] = useState(false);

  // Add filtered clients logic
  const filteredClients = useMemo(() => {
    if (!users) return [];
    
    return users.filter(user => {
      const searchLower = searchQuery.toLowerCase();
      const fullName = `${user.first_name} ${user.last_name}`.toLowerCase();
      const email = user.email?.toLowerCase() || '';
      
      return (
        fullName.includes(searchLower) || 
        email.includes(searchLower)
      );
    });
  }, [users, searchQuery]);

  const handleUserSelect = (user: UserInfoType) => {
    onSubmit(user.first_name + ' ' + user.last_name || user.email);
    onClose();
  };

  // Override CreateUser's default behavior
  useEffect(() => {
    if (showCreateUser) {
      const originalCreateUser = document.querySelector('[aria-label="Create User"]');
      if (originalCreateUser) {
        (originalCreateUser as HTMLElement).click();
      }
    }
  }, [showCreateUser]);

  // Add observer for user creation events
  useEffect(() => {
    const handleUserCreated = () => {
      // Use React Query's invalidation instead of a direct refetch
      queryClient.invalidateQueries({ queryKey: ["users"] });
    };

    window.addEventListener('userCreated', handleUserCreated);
    return () => {
      window.removeEventListener('userCreated', handleUserCreated);
    };
  }, [queryClient]);

  const handleUserCreated = () => {
    queryClient.invalidateQueries({ queryKey: ["users"] });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Select Client
        <Box sx={{ position: 'absolute', right: 16, top: 8 }}>
          <CreateUser onSuccess={handleUserCreated} defaultUserType="client" />
        </Box>
      </DialogTitle>
      <DialogContent>
        {itemName && <p>Item: {itemName}</p>}
        <TextField
          autoFocus
          margin="dense"
          fullWidth
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search clients..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        {isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
            <CircularProgress />
          </div>
        ) : (
          <List sx={{ maxHeight: '400px', overflow: 'auto', mt: 2 }}>
            {filteredClients.map((client: UserInfoType) => (
              <ListItem key={client.firestoreDocId} disablePadding>
                <ListItemButton onClick={() => handleUserSelect(client)}>
                  <ListItemText 
                    primary={client.first_name + ' ' + client.last_name || 'Unnamed'} 
                    secondary={client.email}
                  />
                </ListItemButton>
              </ListItem>
            ))}
            {filteredClients.length === 0 && (
              <ListItem>
                <ListItemText primary="No clients found" />
              </ListItem>
            )}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SoldPopup;
