import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { ImageList, ImageListItem, ImageListItemBar, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import { usePhotosForInventoryObject } from 'hooks/usePhotosForInventoryObject'
import { updatePhotoPosition } from 'core/api/inventory/updatePhotoPosition'

interface SelectMainPhotoDialogProps {
  collectionName: string
  open: boolean
  onClose: () => void
  vehicle: Vehicle
}

const SelectMainPhotoDialog = ({ collectionName, open, onClose, vehicle: row }: SelectMainPhotoDialogProps) => {
  const [selectedPhoto, setSelectedPhoto] = useState<VehiclePhoto | null>(null)

  const handleSelectMainPhoto = (photo: VehiclePhoto) => {
    setSelectedPhoto(photo)
  }

  const handleSubmitSelectedMainPhoto = () => {
    if (!selectedPhoto) {
      console.error('No selected photo to submit.')
      return
    }

    if (selectedPhoto) {
      
    }

    updatePhotoPosition(collectionName, row.id, selectedPhoto.name, 'main')
    onClose()
  }

  const { data: photos, isLoading: isLoadingPhotos } = usePhotosForInventoryObject(collectionName, row.id, {
    enabled: row.photo_storage_path !== '',
  })

  if (isLoadingPhotos) {
    return <div>Loading photos...</div>
  }

  if (!photos) {
    return <div>No photos found</div>
  }

  if (photos.length === 0) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Select Main Photo</DialogTitle>
        <DialogContent>
          <p>No photos found for this inventory item.</p>
          <p> It was still shown on the website.</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Select Main Photo</DialogTitle>
      <DialogContent>
        <ImageList cols={3}>
          {photos.map(photo => (
            <ImageListItem key={photo.url} onClick={() => handleSelectMainPhoto(photo)}>
              <img src={photo.url} alt={photo.name} />
              {selectedPhoto && selectedPhoto.url === photo.url && (
                <ImageListItemBar
                  // title={photo.name}
                  position='top'
                  actionIcon={
                    <IconButton
                      sx={{ color: 'green', background: 'black', padding: '2px' }}
                      aria-label={`close ${photo.name}`}
                      onClick={() => setSelectedPhoto(null)}
                    >
                      <Close />
                    </IconButton>
                  }
                  actionPosition='right'
                  sx={{ background: 'transparent', padding: '4px' }}
                />
              )}
            </ImageListItem>
          ))}
        </ImageList>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmitSelectedMainPhoto} disabled={!selectedPhoto}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SelectMainPhotoDialog
