import React, { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  IconButton,
  Typography,
  Divider,
} from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { adminFunctions } from 'core/api/application/admin'
import { AdminApplication } from 'core/types/applications'

interface AssociateVehiclesDialogProps {
  application: AdminApplication | null
  isOpen: boolean
  onClose: () => void
}

const AssociateVehiclesDialog = ({ application, isOpen, onClose }: AssociateVehiclesDialogProps) => {
  if (!application) {
    return null
  }

  const [vehicles, setVehicles] = useState<Vehicle[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (isOpen && application) {
      fetchVehicles()
    }
  }, [isOpen, application])

  const fetchVehicles = async () => {
    const response = await adminFunctions.getVehiclesForApplication(application.application_id)
    if (response) {
      setVehicles(response)
    }
  }

  const handleVehicleChange = (index: number, field: keyof Vehicle, value: string) => {
    const updatedVehicles = [...vehicles]
    // @ts-expect-error - TS doesn't know that field is a valid key of Vehicle
    updatedVehicles[index][field] = value
    setVehicles(updatedVehicles)
  }

  const addVehicleForm = () => {
    setVehicles([
      ...vehicles,
      {
        id: '',
        vin: '',
        optic_list_price: '',
        seller_asking_price: '',
        miles: '',
        year: '',
        stock_number: '',
        who: '',
        make: '',
        model: '',
        size: '',
        photo_storage_path: '',
        fuel: '',
        location: '',
        cameras: '',
        type: '',
        profit: '',
        sold_date: '',
        truck_notes: '',
        phone_number: '',
        email: '',
        follow_up_notes: '',
        entered_by: '',
        payoff_amount: '',
        link: '',
        show_on_website: false,
      },
    ])
  }

  const handleAssociate = async () => {
    setLoading(true)
    try {
      const response = await adminFunctions.associateVehiclesToApplication(application.application_id, vehicles)

      if (!response) {
        throw new Error('Failed to associate vehicles')
      }

      setVehicles([]) // Clear the form
      onClose() // Close the dialog on successful association
    } catch (error) {
      console.error('Failed to associate vehicles:', error)
      // Handle the error appropriately
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    setVehicles([])
    onClose()
  }

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth='md' fullWidth>
      <DialogTitle>Associate Vehicles</DialogTitle>
      <DialogContent>
        {vehicles.map((vehicle, index) => (
          <>
            <Typography variant='h6' sx={{ mt: 2 }}>
              Vehicle {index + 1}
            </Typography>
            <Grid container spacing={2} alignItems='center'>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label='VIN'
                  value={vehicle.vin}
                  onChange={e => handleVehicleChange(index, 'vin', e.target.value)}
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextField
                  fullWidth
                  label='Sale Price'
                  value={vehicle.optic_list_price}
                  onChange={e => handleVehicleChange(index, 'optic_list_price', e.target.value)}
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextField
                  fullWidth
                  label='Buy Price'
                  value={vehicle.seller_asking_price}
                  onChange={e => handleVehicleChange(index, 'seller_asking_price', e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems='center' sx={{ mt: 2 }}>
              <Grid item xs={4} sm={4}>
                <TextField
                  fullWidth
                  label='Mileage'
                  value={vehicle.miles}
                  onChange={e => handleVehicleChange(index, 'miles', e.target.value)}
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <TextField
                  fullWidth
                  label='Year'
                  value={vehicle.year}
                  onChange={e => handleVehicleChange(index, 'year', e.target.value)}
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <TextField
                  fullWidth
                  label='Stock Number'
                  value={vehicle.stock_number}
                  onChange={e => handleVehicleChange(index, 'stock_number', e.target.value)}
                />
              </Grid>
            </Grid>
            {vehicles.length - 1 !== index && <Divider sx={{ mt: 2, mb: 2 }} />}
          </>
        ))}
        <IconButton onClick={addVehicleForm} color='primary'>
          <AddCircleOutlineIcon />
        </IconButton>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Cancel
        </Button>
        <Button onClick={handleAssociate} color='primary' disabled={loading}>
          Associate
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AssociateVehiclesDialog
