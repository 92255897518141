import { Box, Chip, Paper, Typography, Link, Grid, Divider, Alert } from '@mui/material'
import { RFISubmission } from 'core/types/form_submission'
import { format } from 'date-fns'
import { useInventory } from 'hooks/useInventory'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import PersonIcon from '@mui/icons-material/Person'
import BusinessIcon from '@mui/icons-material/Business'
import React, { useEffect } from 'react'
import WarningIcon from '@mui/icons-material/Warning'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from 'core/config/firebase'

interface Props {
  submission: RFISubmission
}

export const RFIDetail = ({ submission }: Props) => {
  const { vehicles: opticVehicles, isLoading: opticLoading } = useInventory('master_inventory')
  const { vehicles: shieldVehicles, isLoading: shieldLoading } = useInventory('shield_inventory')

  useEffect(() => {
    const markAsRead = async () => {
      if (submission.status === 'New') {
        try {
          const submissionRef = doc(db, 'form_rfi', submission.id)
          await updateDoc(submissionRef, {
            status: 'Read'
          })
        } catch (error) {
          console.error('Error marking submission as read:', error)
        }
      }
    }

    markAsRead()
  }, [submission])

  const findVehicleInInventory = (stockNumber: string) => {
    const opticVehicle = opticVehicles?.find(v => v.stock_number === stockNumber)
    const shieldVehicle = shieldVehicles?.find(v => v.stock_number === stockNumber)
    
    return {
      vehicle: opticVehicle || shieldVehicle,
      source: opticVehicle ? 'Optic' : shieldVehicle ? 'Shield' : null
    }
  }

  const renderVehicleDetails = (stockNumber: string) => {
    const { vehicle, source } = findVehicleInInventory(stockNumber)
    
    if (opticLoading || shieldLoading) {
      return (
        <Paper variant="outlined" sx={{ p: 3, mb: 2 }}>
          <Typography>Loading vehicle information...</Typography>
        </Paper>
      )
    }

    if (!vehicle) {
      return (
        <Paper variant="outlined" sx={{ p: 3, mb: 2 }}>
          <Alert 
            severity="warning" 
            icon={<WarningIcon />}
            sx={{ mb: 2 }}
          >
            Vehicle with Stock #{stockNumber} is not currently in inventory
          </Alert>
        </Paper>
      )
    }

    return (
      <Paper variant="outlined" sx={{ p: 3, mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <DirectionsCarIcon sx={{ mr: 2, color: 'primary.main' }} />
            <Typography variant="h6">Vehicle Details</Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Chip 
              label={vehicle.status || 'Status Unknown'} 
              color={
                vehicle.status === 'Available' ? 'success' : 
                vehicle.status === 'Pending Sale' ? 'warning' : 
                vehicle.status === 'Sold' ? 'error' : 
                'default'
              }
              size="small" 
            />
            <Chip 
              label={`${source} Inventory`} 
              color={source === 'Optic' ? 'primary' : 'error'} 
              size="small" 
            />
          </Box>
        </Box>
        
        <Grid container spacing={3}>
          {/* Basic Vehicle Info */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" color="text.secondary">Vehicle Information</Typography>
            <Typography variant="h6">{vehicle.year} {vehicle.make} {vehicle.model}</Typography>
            <Typography variant="body2" color="text.secondary">Stock #: {vehicle.stock_number}</Typography>
            <Typography variant="body2" color="text.secondary">VIN: {vehicle.vin}</Typography>
            <Typography variant="body2">Type: {vehicle.type}</Typography>
            <Typography variant="body2">Size: {vehicle.size}</Typography>
          </Grid>
          
          {/* Specifications */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" color="text.secondary">Specifications</Typography>
            <Typography variant="body2">Mileage: {vehicle.miles.toLocaleString()}</Typography>
            <Typography variant="body2">Fuel Type: {vehicle.fuel}</Typography>
            {vehicle.drive_type && (
              <Typography variant="body2">Drive Type: {vehicle.drive_type}</Typography>
            )}
            {vehicle.gross_vehicle_weight && (
              <Typography variant="body2">GVW: {vehicle.gross_vehicle_weight}</Typography>
            )}
            {vehicle.cameras && (
              <Typography variant="body2">Cameras: {vehicle.cameras}</Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
          </Grid>

          {/* Location & Status */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" color="text.secondary">Location & Details</Typography>
            <Typography variant="body2">Location: {vehicle.location}</Typography>
            {vehicle.condition && (
              <Typography variant="body2">Condition: {vehicle.condition}</Typography>
            )}
            {vehicle.truck_notes && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                Notes: {vehicle.truck_notes}
              </Typography>
            )}
          </Grid>

          {/* Pricing */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" color="text.secondary">Pricing</Typography>
            {vehicle.optic_list_price && (
              <Typography variant="h6" color="primary">
                List Price: ${parseInt(vehicle.optic_list_price).toLocaleString()}
              </Typography>
            )}
            {vehicle.seller_asking_price && (
              <Typography variant="body2" color="text.secondary">
                Seller Asking: ${parseInt(vehicle.seller_asking_price).toLocaleString()}
              </Typography>
            )}
          </Grid>

          {/* Photos if available */}
          {vehicle.photos && Object.values(vehicle.photos).some(photo => photo) && (
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 2 }}>Photos</Typography>
              <Box sx={{ display: 'flex', gap: 2, overflow: 'auto' }}>
                {Object.entries(vehicle.photos).map(([position, url]) => 
                  url ? (
                    <Box
                      key={position}
                      component="img"
                      src={url as string}
                      alt={`${position} view`}
                      sx={{
                        width: 150,
                        height: 150,
                        objectFit: 'cover',
                        borderRadius: 1
                      }}
                    />
                  ) : null
                )}
              </Box>
            </Grid>
          )}
        </Grid>

        <Link 
          href={`https://optictruckworks.com/buy/${vehicle.id}`} 
          target="_blank" 
          sx={{ mt: 2, display: 'block' }}
        >
          View Vehicle Listing
        </Link>
      </Paper>
    )
  }

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        {/* Contact Information */}
        <Grid item xs={12} md={6}>
          <Paper variant="outlined" sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <PersonIcon sx={{ mr: 2, color: 'primary.main' }} />
              <Typography variant="h6">Contact Information</Typography>
            </Box>
            <Typography variant="body1">{submission.firstName} {submission.lastName}</Typography>
            <Typography variant="body2">{submission.email}</Typography>
            <Typography variant="body2">{submission.phone}</Typography>
          </Paper>
        </Grid>

        {/* Company Information */}
        <Grid item xs={12} md={6}>
          <Paper variant="outlined" sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <BusinessIcon sx={{ mr: 2, color: 'primary.main' }} />
              <Typography variant="h6">Company Information</Typography>
            </Box>
            <Typography variant="body1">{submission.companyName || 'N/A'}</Typography>
            <Typography variant="body2">{submission.customerType} Customer</Typography>
            {submission.companyPosition && (
              <Typography variant="body2">Position: {submission.companyPosition}</Typography>
            )}
          </Paper>
        </Grid>

        {/* Vehicle Details */}
        <Grid item xs={12}>
          {submission.selectedVehicles ? (
            submission.selectedVehicles.map(vehicle => 
              renderVehicleDetails(vehicle.stockNumber)
            )
          ) : (
            submission.stockNumber && renderVehicleDetails(submission.stockNumber)
          )}
        </Grid>
      </Grid>

      {/* Status and Metadata */}
      <Box sx={{ mt: 3, display: 'flex', gap: 1 }}>
        {submission.needsFinancing && (
          <Chip label="Needs Financing" color="primary" />
        )}
        <Chip label={submission.status} />
        <Chip 
          label={format(new Date(submission.createdAt.seconds * 1000), 'PP')} 
          variant="outlined" 
        />
      </Box>
    </Box>
  )
} 