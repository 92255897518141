export const vehicleStatusOptions = ['Pending Available', 'Available', 'Pending Sale', 'Sold', 'Sold elsewhere']

export const vehicleTypeOptions = ['Step van', 'Cargo van', 'Cutaway', 'Box truck']

export const vehicleFuelOptions = ['Gas', 'Diesel']

export const dealQualityOptions = ['great', 'good', 'average', 'belowAverage', 'poor']

export const getVehicleSizeOptions = (vehicleType: string) => {
  if (!vehicleType) return []

  switch (vehicleType.toLowerCase()) {
    case 'step van':
      return ['P500', 'P700', 'P900', 'P1000', 'P1100', 'P1200', 'Unknown']
    case 'cargo van':
      return [
        'low-standard',
        'medium-standard',
        'high-standard',
        'low-extended',
        'medium-extended',
        'high-extended',
        'Unknown',
      ]
    case 'cutaway':
      return ['10ft', '12ft', '14ft', '15ft', '16ft', '17ft', '18ft', 'Unknown']
    case 'box truck':
      return ['12ft', '14ft', '16ft', '18ft', '20ft', '22ft', '24ft', '26ft', 'Unknown']
    default:
      return []
  }
}

export const makeOptions = ['Ford', 'Chevrolet', 'Freightliner', 'GMC']

export const getVehicleModelOptions = (vehicleMake: string) => {
  switch (vehicleMake) {
    case 'Ford':
      return ['E350', 'E450', 'F59', 'F650', 'Unknown']
    case 'Chevrolet':
      return ['Express 3500', 'Express 4500', 'Unkown']
    case 'Freightliner':
      return ['MT', 'MT45', 'MT55', 'Unknown']
    case 'GMC':
      return ['Express 3500', 'Express 4500', 'SAVANA 3500', 'SAVANA 4500', 'Unknown']
    default:
      return []
  }
}
