import { 
  Paper, Typography, TableContainer, Table, TableHead, 
  TableRow, TableCell, TableBody, Box, Chip
} from '@mui/material'
import { formatPrice } from 'core/utils/inventoryUtils'
import React from 'react'

interface PendingSale {
  model: string
  count: number
  totalRevenue: number
  totalProfit: number
  avgDaysInStatus: number
  vehicles: any[] // Replace with your vehicle type
}

interface PendingSalesOverviewProps {
  vehicles: any[] | undefined // Update type to match what comes from useInventory
  onClick: () => void
}

export const PendingSalesOverview = ({ vehicles = [], onClick }: PendingSalesOverviewProps) => {
  const pendingSales = React.useMemo(() => {
    if (!vehicles) return []
    
    const pending = vehicles.filter(v => v.status?.toLowerCase() === 'pending sale')
    
    // Group by model
    const byModel = pending.reduce((acc: Record<string, PendingSale>, vehicle) => {
      const model = vehicle.model || 'Unknown'
      if (!acc[model]) {
        acc[model] = {
          model,
          count: 0,
          totalRevenue: 0,
          totalProfit: 0,
          avgDaysInStatus: 0,
          vehicles: []
        }
      }

      const revenue = parseFloat(vehicle.optic_list_price) || 0
      const profit = parseFloat(vehicle.profit) || 0
      
      // Calculate days in inventory instead of status
      const addedDate = vehicle.date_added ? new Date(vehicle.date_added) : null
      const daysInInventory = addedDate && !isNaN(addedDate.getTime()) 
        ? Math.floor((new Date().getTime() - addedDate.getTime()) / (1000 * 60 * 60 * 24))
        : 0

      acc[model].count++
      acc[model].totalRevenue += revenue
      acc[model].totalProfit += profit
      acc[model].avgDaysInStatus = 
        ((acc[model].avgDaysInStatus * (acc[model].count - 1)) + daysInInventory) / acc[model].count
      acc[model].vehicles.push(vehicle)

      return acc
    }, {})

    return Object.values(byModel)
  }, [vehicles])

  const totals = React.useMemo(() => ({
    count: pendingSales.reduce((sum, model) => sum + model.count, 0),
    revenue: pendingSales.reduce((sum, model) => sum + model.totalRevenue, 0),
    profit: pendingSales.reduce((sum, model) => sum + model.totalProfit, 0),
    avgDays: pendingSales.length ? 
      pendingSales.reduce((sum, model) => sum + model.avgDaysInStatus, 0) / pendingSales.length 
      : 0
  }), [pendingSales])

  return (
    <Paper 
      sx={{ 
        p: 3, 
        height: 325,
        cursor: 'pointer',
        '&:hover': { bgcolor: 'action.hover' },
        display: 'flex',
        flexDirection: 'column'
      }}
      onClick={onClick}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h6">
          Pending Sales Overview
        </Typography>
        <Chip 
          label={`${totals.count} Total Vehicles`}
          color="primary"
          size="small"
        />
      </Box>

      <TableContainer sx={{ flex: 1 }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Model</TableCell>
              <TableCell align="right">Count</TableCell>
              <TableCell align="right">Expected Revenue</TableCell>
              <TableCell align="right">Expected Profit</TableCell>
              <TableCell align="right">Avg Days in Inventory</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pendingSales.map((model) => (
              <TableRow key={model.model}>
                <TableCell>{model.model}</TableCell>
                <TableCell align="right">{model.count}</TableCell>
                <TableCell align="right">{formatPrice(model.totalRevenue.toString())}</TableCell>
                <TableCell align="right">{model.totalProfit > 0 ? formatPrice(model.totalProfit.toString()) : '-'}</TableCell>
                <TableCell align="right">{Math.round(model.avgDaysInStatus)}</TableCell>
              </TableRow>
            ))}
            <TableRow sx={{ '& td': { fontWeight: 'bold' } }}>
              <TableCell>Total</TableCell>
              <TableCell align="right">{totals.count}</TableCell>
              <TableCell align="right">{formatPrice(totals.revenue.toString())}</TableCell>
              <TableCell align="right">{totals.profit > 0 ? formatPrice(totals.profit.toString()) : '-'}</TableCell>
              <TableCell align="right">{Math.round(totals.avgDays)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}
