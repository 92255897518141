/* eslint-disable */

// TODO: @adamsiwiec1 i will type this

import { API_URL } from 'core/config/index'
import { AdditionalAppliationDocuments } from 'core/types/applications'
import { PhotoUpload, PhotoSubmission } from 'core/types/photoUpload'

const RouteName = 'admin'

const getJwtToken = (): string | null => {
  return localStorage.getItem('token')
}

const getHeaders = (): HeadersInit => {
  const jwtToken = getJwtToken()
  return {
    'Content-Type': 'application/json',
    ...(jwtToken ? { Authorization: `Bearer ${jwtToken}` } : {}),
  }
}

const getApplications = async (): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/get-applications`, {
    method: 'GET',
    headers: getHeaders(),
  })

  const data = await response.json()
  return data
}

const getViewableDocument = async (document_id: string): Promise<string> => {
  const response = await fetch(`${API_URL}/${RouteName}/get-viewable-document`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ document_id }),
  })

  if (!response.ok) {
    return ''
  }

  const blob = await response.blob()
  return URL.createObjectURL(blob)
}

const getBanks = async (): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/get-banks`, {
    method: 'GET',
    headers: getHeaders(),
  })

  if (!response.ok) {
    return false
  }

  const data = await response.json()
  return data
}

const getAgentsForBank = async (bank_id: string): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/get-agents`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ bank_id }),
  })

  if (!response.ok) {
    return false
  }

  const data = await response.json()
  return data
}

const assignApplicationToAgent = async (application_id: number, bank_agent_id: string): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/assign-application-to-agent`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ application_id, bank_agent_id }),
  })

  if (!response.ok) {
    return ''
  }

  const data = await response.json()
  return data
}

const addNotesToApplication = async (application_id: number, notes: string): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/add-notes-to-application`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ application_id, notes }),
  })

  if (!response.ok) {
    return ''
  }

  const data = await response.json()
  return data
}

const markApplicationAsComplete = async (application_id: number): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/mark-application-complete`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ application_id }),
  })

  if (!response.ok) {
    return ''
  }

  const data = await response.json()
  return data
}

const sendDocumentBackForCorrections = async (application_id: number, correction_notes: string): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/send-document-back-to-client`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ application_id, correction_notes }),
  })

  if (!response.ok) {
    return ''
  }

  const data = await response.json()
  return data
}

const associateVehiclesToApplication = async (application_id: number, vehicles: Vehicle[]): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/associate-vehicles-to-application`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ application_id, vehicles }),
  })

  if (!response.ok) {
    return ''
  }

  const data = await response.json()
  return data
}

const getVehicles = async (): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/get-vehicles`, {
    method: 'GET',
    headers: getHeaders(),
  })
  if (!response.ok) {
    return ''
  }

  const data = await response.json()

  return data['vehicles']
}

const addVehicles = async (vehicles: Vehicle[]): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/add-vehicles`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ vehicles }),
  })

  if (!response.ok) {
    return ''
  }

  const data = await response.json()
  return data
}

const getVehiclesForApplication = async (application_id: number): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/get-vehicles-for-application`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ application_id }),
  })

  if (!response.ok) {
    return ''
  }

  const data = await response.json()
  return data['vehicles']
}

const getApplicationDocuments = async (document_id: string): Promise<AdditionalAppliationDocuments> => {
  const response = await fetch(`${API_URL}/${RouteName}/get-application-documents`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ document_id }),
  })

  const data: AdditionalAppliationDocuments = await response.json()

  return data
}

const deleteVehicles = async (vehicleIds: number[]): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/delete-vehicles`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ vehicleIds }),
  })

  if (!response.ok) {
    return ''
  }

  const data = await response.json()
  return data
}

const getAffiliatesLeads = async (): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/get-affiliate-leads`, {
    method: 'GET',
    headers: getHeaders(),
  })

  if (!response.ok) {
    return ''
  }

  const data = await response.json()
  return data
}

const handleAffiliateStatusChange = async (affiliateId: number, status: string): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/handle-affiliate-status-change`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ affiliateId, status }),
  })

  if (!response.ok) {
    
  }

  const data = await response.json()
  return data
}

const handleLeadStatusChange = async (leadId: string, status: string): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/handle-lead-status-change`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ leadId, status }),
  })

  if (!response.ok) {
    
  }

  const data = await response.json()
  return data
}

const getTruckDebtOverhaulClients = async (): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/get-truck-debt-overhaul-clients`, {
    method: 'GET',
    headers: getHeaders(),
  })

  const data = await response.json()
  return data
}

const getTruckDebtOverhaulSubmissions = async (clientId: string, status: string): Promise<any> => {
  const queryParams = new URLSearchParams({
    client_id: clientId,
  })

  if (status !== '') {
    queryParams.append('status', status)
  }

  const response = await fetch(`${API_URL}/${RouteName}/get-truck-debt-overhaul-submissions?${queryParams}`, {
    method: 'GET',
    headers: getHeaders(),
  })

  const data = await response.json()
  return data
}

const getTrucksForSubmission = async (submissionId: number): Promise<any> => {
  const response = await fetch(
    `${API_URL}/${RouteName}/get-trucks-for-debt-overhaul-submission?submission_id=${submissionId}`,
    {
      method: 'GET',
      headers: getHeaders(),
    },
  )

  const data = await response.json()
  return data
}

const getStatusesForClientSubmissions = async (clientId: number): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/get-client-submission-statuses?client_id=${clientId}`, {
    method: 'GET',
    headers: getHeaders(),
  })

  const data = await response.json()
  return data
}

const markSubmissionAsCompleted = async (submission_id: number): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/mark-submission-completed`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ submission_id }),
  })

  if (!response.ok) {
    return ''
  }

  const data = await response.json()
  return data
}

const markSubmissionAsPending = async (submission_id: number): Promise<any> => {
  const response = await fetch(`${API_URL}/${RouteName}/mark-submission-pending`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ submission_id }),
  })

  if (!response.ok) {
    return ''
  }

  const data = await response.json()
  return data
}

const getPhotoUploads = async (): Promise<PhotoUpload[]> => {
  const response = await fetch(`${API_URL}/${RouteName}/get-photo-uploads`, {
    method: 'GET',
    headers: getHeaders(),
  })

  if (!response.ok) {
    return []
  }

  const data = await response.json()
  return data
}

const getPhotoSubmissions = async (uploadId: string): Promise<PhotoSubmission[]> => {
  const response = await fetch(`${API_URL}/${RouteName}/get-photo-submissions`, {
    method: 'GET',
    headers: getHeaders(),
    body: JSON.stringify({ uploadId }),
  })

  if (!response.ok) {
    return []
  }

  const data = await response.json()
  return data
}

interface AdminFunctions {
  getApplications: () => Promise<any>
  getViewableDocument: (document_id: string) => Promise<string>
  getBanks: () => Promise<any>
  getAgentsForBank: (bank_id: string) => Promise<any>
  assignApplicationToAgent: (application_id: number, bank_agent_id: string) => Promise<any>
  addNotesToApplication: (application_id: number, notes: string) => Promise<any>
  markApplicationAsComplete: (application_id: number) => Promise<any>
  sendDocumentBackForCorrections: (application_id: number, correction_notes: string) => Promise<any>
  associateVehiclesToApplication: (application_id: number, vehicles: Vehicle[]) => Promise<any>
  getVehicles: () => Promise<any>
  addVehicles: (vehicles: Vehicle[]) => Promise<any>
  getVehiclesForApplication: (application_id: number) => Promise<any>
  getApplicationDocuments: (document_id: string) => Promise<any>
  deleteVehicles: (vehicleIds: number[]) => Promise<any>
  getAffiliatesLeads: () => Promise<any>
  handleAffiliateStatusChange: (affiliateId: number, status: string) => Promise<any>
  handleLeadStatusChange: (leadId: string, status: string) => Promise<any>
  getTruckDebtOverhaulClients: () => Promise<any>
  getTruckDebtOverhaulSubmissions: (clientId: string, status: string) => Promise<any>
  getTrucksForSubmission: (submissionId: number) => Promise<any>
  getStatusesForClientSubmissions: (clientId: number) => Promise<any>
  markSubmissionAsCompleted: (submission_id: number) => Promise<any>
  markSubmissionAsPending: (submission_id: number) => Promise<any>
  getPhotoUploads: () => Promise<PhotoUpload[]>
  getPhotoSubmissions: (uploadId: string) => Promise<PhotoSubmission[]>
}

export const adminFunctions: AdminFunctions = {
  getApplications: getApplications,
  getViewableDocument: getViewableDocument,
  getBanks: getBanks,
  getAgentsForBank: getAgentsForBank,
  assignApplicationToAgent: assignApplicationToAgent,
  addNotesToApplication: addNotesToApplication,
  markApplicationAsComplete: markApplicationAsComplete,
  sendDocumentBackForCorrections: sendDocumentBackForCorrections,
  associateVehiclesToApplication: associateVehiclesToApplication,
  getVehicles: getVehicles,
  addVehicles: addVehicles,
  getVehiclesForApplication: getVehiclesForApplication,
  getApplicationDocuments: getApplicationDocuments,
  deleteVehicles: deleteVehicles,
  getAffiliatesLeads: getAffiliatesLeads,
  handleAffiliateStatusChange: handleAffiliateStatusChange,
  handleLeadStatusChange: handleLeadStatusChange,
  getTruckDebtOverhaulClients: getTruckDebtOverhaulClients,
  getTruckDebtOverhaulSubmissions: getTruckDebtOverhaulSubmissions,
  getTrucksForSubmission: getTrucksForSubmission,
  getStatusesForClientSubmissions: getStatusesForClientSubmissions,
  markSubmissionAsCompleted: markSubmissionAsCompleted,
  markSubmissionAsPending: markSubmissionAsPending,
  getPhotoUploads: getPhotoUploads,
  getPhotoSubmissions: getPhotoSubmissions,
}
