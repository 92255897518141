import React, { useEffect, useState } from 'react';
import { useInventory } from 'hooks/useInventory';
import { useAuth } from 'contexts/AuthContext';
import { useManageUsers } from 'hooks/useManageUsers';
import { 
  Box, 
  Typography, 
  Paper, 
  Button, 
  Stack,
  Checkbox,
  IconButton,
  Collapse,
  Chip,
  Divider,
  Alert,
  Tooltip,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
  Grid
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { collection, getFirestore, onSnapshot, getDocs, deleteDoc, query, where, addDoc } from 'firebase/firestore';
import dayjs from 'dayjs';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import UndoIcon from '@mui/icons-material/Undo';
import DeleteIcon from '@mui/icons-material/Delete';
import TimelineIcon from '@mui/icons-material/Timeline';
import InfoIcon from '@mui/icons-material/Info';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

const db = getFirestore();

interface InventoryLogProps {
  inventoryCollection: string;
  externalHistory?: HistoryEntry[];
  showTypeIndicator?: boolean;
  title?: string;
}

interface HistoryEntry {
  id: string;
  documentId: string;
  timestamp: any;
  alphas?: Record<string, any>;
  deltas?: Record<string, any>;
  fullData?: Record<string, any>;
  modifiedBy: string;
  collection: string;
  type: 'update' | 'create' | 'delete';
  identifier: string;
  docId: string;
}

const VehicleDataDisplay: React.FC<{ data: Record<string, any> | undefined }> = ({ data }) => {
  if (!data) return null;
  
  const mainFields = [
    { label: 'Stock Number', key: 'stock_number' },
    { label: 'Year', key: 'year' },
    { label: 'Make', key: 'make' },
    { label: 'Model', key: 'model' },
    { label: 'VIN', key: 'vin' },
    { label: 'Color', key: 'color' },
    { label: 'Mileage', key: 'mileage' },
    { label: 'Status', key: 'status' },
    { label: 'Location', key: 'location' },
  ];

  const formatValue = (value: any) => {
    if (value === null || value === undefined) return 'N/A';
    if (typeof value === 'boolean') return value ? 'Yes' : 'No';
    if (typeof value === 'number') return value.toLocaleString();
    return value;
  };

  return (
    <Box sx={{ pl: 4 }}>
      <Grid container spacing={2}>
        {mainFields.map(({ label, key }) => (
          data[key] && (
            <Grid item xs={12} sm={6} md={4} key={key}>
              <Typography variant="body2">
                <strong>{label}:</strong>{' '}
                <span style={{ color: 'text.secondary' }}>
                  {formatValue(data[key])}
                </span>
              </Typography>
            </Grid>
          )
        ))}
      </Grid>
    </Box>
  );
};

const InventoryLog: React.FC<InventoryLogProps> = ({ 
  inventoryCollection, 
  externalHistory,
  showTypeIndicator,
  title
}) => {
  const { 
    updateDocument, 
    addDocument,
    deleteDocument,
    restoreDocument
  } = useInventory(inventoryCollection);

  const {
    updateUser,
    deleteUser,
    restoreUser
  } = useManageUsers();

  const history = externalHistory;
  const isLoading = false;
  const isError = false;
  const refetch = () => {};

  const [revertTime, setRevertTime] = useState(dayjs());
  const [selectedEntries, setSelectedEntries] = useState<Set<string>>(new Set());
  const [expandedEntries, setExpandedEntries] = useState<Set<string>>(new Set());
  const [showTimeRevert, setShowTimeRevert] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [revertPreviewOpen, setRevertPreviewOpen] = useState(false);
  const [revertSelections, setRevertSelections] = useState<Set<string>>(new Set());
  const [previewChanges, setPreviewChanges] = useState<HistoryEntry[]>([]);
  const [revertedEntries, setRevertedEntries] = useState<Set<string>>(new Set());
  const { userInfo } = useAuth();

  useEffect(() => {
    if (history) {
      const updateEntries = history
        .filter(entry => entry.type === 'update')
        .map(entry => entry.id);
      setExpandedEntries(new Set(updateEntries));
    }
  }, [history]);

  const handleEntrySelect = (entryId: string) => {
    const newSelected = new Set(selectedEntries);
    if (newSelected.has(entryId)) {
      newSelected.delete(entryId);
    } else {
      newSelected.add(entryId);
    }
    setSelectedEntries(newSelected);
  };

  const handleExpandEntry = (entryId: string) => {
    const newExpanded = new Set(expandedEntries);
    if (newExpanded.has(entryId)) {
      newExpanded.delete(entryId);
    } else {
      newExpanded.add(entryId);
    }
    setExpandedEntries(newExpanded);
  };

  const handleRevertSelected = () => {
    const selectedChanges = history?.filter(entry => selectedEntries.has(entry.id));
    
    console.group('Reverting Selected Changes');
    ;

    const newReverted = new Set(revertedEntries);
    selectedChanges?.forEach(entry => newReverted.add(entry.id));
    setRevertedEntries(newReverted);

    selectedChanges?.forEach((entry) => {
      if (entry.documentId) {
        console.group('Reverting Entry');
        ;
        ;
        ;
        
        handleRevertOperation(entry);
        
        console.groupEnd();
      }
    });

    console.groupEnd();
    setSelectedEntries(new Set());
  };

  const getChangeTypeColor = (type: string) => {
    switch (type) {
      case 'create': return 'success';
      case 'update': return 'primary';
      case 'delete': return 'error';
      default: return 'default';
    }
  };

  const handleDateChange = (newValue: dayjs.Dayjs | null) => {
    if (newValue) {
      setRevertTime(newValue);
      const changes = calculateChangesToRevert();
      setPreviewChanges(changes);
      setRevertSelections(new Set(changes.map(entry => entry.id)));
    }
  };

  const calculateChangesToRevert = () => {
    if (!revertTime || !history) return [];
    const revertTimestamp = revertTime.valueOf();
    return history.filter((entry) => {
      const entryTimestamp = entry.timestamp?.toDate().getTime();
      return entryTimestamp && entryTimestamp > revertTimestamp;
    });
  };

  const handleRevertPreview = () => {
    const changes = calculateChangesToRevert();
    setPreviewChanges(changes);
    setRevertSelections(new Set(changes.map(entry => entry.id)));
    setRevertPreviewOpen(true);
  };

  const handleRevertOperation = (entry: HistoryEntry) => {
    const isUserChange = entry.collection === 'users';

    switch (entry.type) {
      case 'delete':
        if (entry.fullData) {
          if (isUserChange) {
            restoreUser({
              ...entry.fullData,
              id: entry.documentId,
              timestamp: dayjs().toDate()
            });
          } else {
            restoreDocument({
              id: entry.documentId,
              fullData: entry.fullData
            });
          }
        }
        break;
      case 'create':
        if (isUserChange) {
          deleteUser(entry.documentId);
        } else {
          deleteDocument(entry.documentId);
        }
        break;
      case 'update':
        if (isUserChange) {
          updateUser({
            id: entry.documentId,
            ...entry.deltas,
            timestamp: dayjs().toDate(),
            isRevert: true
          });
        } else {
          updateDocument({
            id: entry.documentId,
            data: { 
              ...entry.deltas,
              timestamp: dayjs().toDate()
            },
            isRevert: true
          });
        }
        break;
    }
  };

  const handleRevert = () => {
    if (revertTime) {
      const selectedChanges = previewChanges.filter(entry => revertSelections.has(entry.id));
      
      console.group('Revert Operation');
      ;
      ;

      const newReverted = new Set(revertedEntries);
      selectedChanges.forEach(entry => newReverted.add(entry.id));
      setRevertedEntries(newReverted);

      selectedChanges.forEach((entry) => {
        if (entry.documentId) {
          console.group('Reverting Entry');
          ;
          ;
          ;
          
          handleRevertOperation(entry);
          
          console.groupEnd();
        }
      });

      console.groupEnd();
      setRevertPreviewOpen(false);
    }
  };

  const handleToggleRevert = (entryId: string) => {
    const newSelections = new Set(revertSelections);
    if (newSelections.has(entryId)) {
      newSelections.delete(entryId);
    } else {
      newSelections.add(entryId);
    }
    setRevertSelections(newSelections);
  };

  const handleClearHistory = async () => {
    if (!history) return;
    
    const historyRef = collection(db, 'document_history');
    const q = query(historyRef, where('collection', '==', inventoryCollection));
    const snapshot = await getDocs(q);
    
    await Promise.all(
      snapshot.docs.map(doc => deleteDoc(doc.ref))
    );

    // Refetch to update the UI
    refetch();
  };

  const filterHistoryBySearch = (history: HistoryEntry[] | undefined) => {
    if (!history) return [];
    
    // Filter out reverted entries and user create operations
    const filteredHistory = history.filter(entry => {
      const isReverted = revertedEntries.has(entry.id);
      const isUserCreate = entry.collection === 'users' && entry.type === 'create';
      return !isReverted && !isUserCreate;
    });
    
    if (!searchQuery) return filteredHistory;
    
    const query = searchQuery.toLowerCase();
    return filteredHistory.filter(entry => {
      if (entry.identifier?.toLowerCase().includes(query)) return true;
      if (entry.modifiedBy?.toLowerCase().includes(query)) return true;
      
      if (entry.deltas) {
        const deltasString = JSON.stringify(entry.deltas).toLowerCase();
        if (deltasString.includes(query)) return true;
      }
      if (entry.alphas) {
        const alphasString = JSON.stringify(entry.alphas).toLowerCase();
        if (alphasString.includes(query)) return true;
      }
      
      if (entry.fullData) {
        const fullDataString = JSON.stringify(entry.fullData).toLowerCase();
        if (fullDataString.includes(query)) return true;
      }
      
      if (entry.timestamp?.toDate().toLocaleString().toLowerCase().includes(query)) return true;
      
      return false;
    });
  };

  const handleToggleAllEntries = () => {
    if (expandedEntries.size > 0) {
      setExpandedEntries(new Set());
    } else if (history) {
      setExpandedEntries(new Set(history.map(entry => entry.id)));
    }
  };

  const filterSensitiveData = (data: Record<string, any>) => {
    if (!data || userInfo?.user_type === 'admin') return data;

    const filteredData = { ...data };
    delete filteredData.profit;
    delete filteredData.seller_asking_price;
    return filteredData;
  };

  if (isLoading) return <div>Loading history...</div>;
  if (isError) return <div>Error loading history.</div>;

  return (
    <Box>
      <Paper sx={{ p: 3, mb: 3 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h4">{title || "Inventory Change History"}</Typography>
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              startIcon={expandedEntries.size > 0 ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
              onClick={handleToggleAllEntries}
            >
              {expandedEntries.size > 0 ? 'Collapse All' : 'Expand All'}
            </Button>
            <Button
              variant="outlined"
              startIcon={<TimelineIcon />}
              onClick={() => setShowTimeRevert(!showTimeRevert)}
            >
              Time Revert
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<UndoIcon />}
              disabled={selectedEntries.size === 0}
              onClick={handleRevertSelected}
            >
              Revert Selected ({selectedEntries.size})
            </Button>
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={handleClearHistory}
            >
              Clear History
            </Button>
          </Stack>
        </Stack>

        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search in history..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ mb: 2 }}
        />

        <Collapse in={showTimeRevert}>
          <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label="Revert to Time"
                  value={revertTime}
                  onChange={handleDateChange}
                />
              </LocalizationProvider>
              <Button 
                variant="outlined" 
                onClick={handleRevertPreview}
                startIcon={<InfoIcon />}
              >
                Preview Changes ({calculateChangesToRevert().length})
              </Button>
              <Button 
                variant="contained" 
                onClick={handleRevert}
                disabled={revertSelections.size === 0}
              >
                Revert Selected ({revertSelections.size})
              </Button>
            </Stack>
          </Paper>
        </Collapse>

        {selectedEntries.size > 0 && (
          <Alert severity="info" sx={{ mb: 2 }}>
            {selectedEntries.size} changes selected for reversion
          </Alert>
        )}

        <Stack spacing={2}>
          {history && history.length > 0 ? (
            (filterHistoryBySearch(history) || []).map((entry: HistoryEntry) => (
              <Paper 
                key={entry.id} 
                variant="outlined" 
                sx={{ 
                  p: 2,
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'action.hover'
                  }
                }}
                onClick={(e) => {
                  if (!(e.target as HTMLElement).closest('.MuiCheckbox-root')) {
                    handleExpandEntry(entry.id);
                  }
                }}
              >
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Checkbox
                    className="MuiCheckbox-root"
                    checked={selectedEntries.has(entry.id)}
                    onChange={(e) => {
                      e.stopPropagation();
                      handleEntrySelect(entry.id);
                    }}
                  />
                  <Box flex={1}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Chip 
                        label={entry.type} 
                        color={getChangeTypeColor(entry.type)} 
                        size="small" 
                        onClick={(e) => e.stopPropagation()}
                      />
                      {showTypeIndicator && (
                        <Chip
                          label={entry.collection === 'master_inventory' ? 'Inventory' : 'User'}
                          color={entry.collection === 'master_inventory' ? 'info' : 'secondary'}
                          size="small"
                          onClick={(e) => e.stopPropagation()}
                        />
                      )}
                      <Typography variant="subtitle1">
                        {entry.identifier || 'N/A'}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        by {entry.modifiedBy || 'N/A'}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        • {entry.timestamp?.toDate().toLocaleString() || 'N/A'}
                      </Typography>
                    </Stack>
                  </Box>
                  <IconButton 
                    onClick={(e) => {
                      e.stopPropagation();
                      handleExpandEntry(entry.id);
                    }}
                  >
                    {expandedEntries.has(entry.id) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </Stack>

                <Collapse in={expandedEntries.has(entry.id)}>
                  <Divider sx={{ my: 2 }} />
                  {entry.type === 'update' && entry.deltas && entry.alphas && (
                    <Box sx={{ pl: 4, maxWidth: '100%', overflow: 'hidden' }}>
                      {Object.entries(entry.deltas).map(([field, value]) => {
                        const alphaValue = entry.alphas?.[field];
                        return (
                          <Typography 
                            key={field} 
                            variant="body2" 
                            sx={{ 
                              mb: 1,
                              wordBreak: 'break-word',
                              overflowWrap: 'break-word'
                            }}
                          >
                            <strong>{field}:</strong>{' '}
                            <span style={{ color: 'error.main' }}>
                              {value === '' || value === null || value === undefined ? 'No value' : JSON.stringify(value)}
                            </span>
                            {' → '}
                            <span style={{ color: 'success.main' }}>
                              {alphaValue === '' || alphaValue === null || alphaValue === undefined 
                                ? 'No value' 
                                : JSON.stringify(alphaValue)}
                            </span>
                          </Typography>
                        );
                      })}
                    </Box>
                  )}
                  {(entry.type === 'create' || entry.type === 'delete') && entry.fullData && (
                    <Box sx={{ pl: 4 }}>
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="subtitle2" gutterBottom>
                          {entry.type === 'create' ? 'Initial Data:' : 'Deleted Data:'}
                        </Typography>
                        {entry.collection === 'master_inventory' ? (
                          <VehicleDataDisplay 
                            data={entry.fullData ? filterSensitiveData(entry.fullData) : undefined} 
                          />
                        ) : (
                          <pre style={{ 
                            margin: 0,
                            whiteSpace: 'pre-wrap',
                            wordBreak: 'break-word',
                            maxWidth: '100%',
                            overflow: 'hidden'
                          }}>
                            {entry.fullData ? JSON.stringify(filterSensitiveData(entry.fullData), null, 2) : ''}
                          </pre>
                        )}
                      </Stack>
                    </Box>
                  )}
                </Collapse>
              </Paper>
            ))
          ) : (
            <Typography>No history available.</Typography>
          )}
        </Stack>
      </Paper>

      <Dialog
        open={revertPreviewOpen}
        onClose={() => setRevertPreviewOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Changes to be Reverted
          <Typography variant="subtitle1" color="text.secondary">
            Reverting to {revertTime.format('MMMM D, YYYY h:mm A')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <List>
            {previewChanges.map((entry) => (
              <ListItem 
                key={entry.id}
                divider
                secondaryAction={
                  <Switch
                    edge="end"
                    onChange={() => handleToggleRevert(entry.id)}
                    checked={revertSelections.has(entry.id)}
                  />
                }
              >
                <ListItemText
                  primary={
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Chip 
                        label={entry.identifier} 
                        size="small" 
                        color="primary"
                      />
                      <Typography variant="body2" color="text.secondary">
                        by {entry.modifiedBy}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        • {entry.timestamp?.toDate().toLocaleString()}
                      </Typography>
                    </Stack>
                  }
                  secondary={
                    <Box sx={{ mt: 1 }}>
                      {entry.type === 'update' ? (
                        Object.entries(filterSensitiveData(entry.deltas || {})).map(([field, value]) => (
                          <Typography key={field} variant="body2" sx={{ mb: 0.5 }}>
                            <strong>{field}:</strong>{' '}
                            <span style={{ color: 'error.main' }}>
                              {value === '' || value === null || value === undefined ? 'No value' : JSON.stringify(value)}
                            </span>
                            {' → '}
                            <span style={{ color: 'success.main' }}>
                              {entry.alphas?.[field] === '' || entry.alphas?.[field] === null || entry.alphas?.[field] === undefined 
                                ? 'No value' 
                                : JSON.stringify(entry.alphas?.[field])}
                            </span>
                          </Typography>
                        ))
                      ) : (
                        entry.collection === 'master_inventory' ? (
                          <VehicleDataDisplay 
                            data={entry.fullData ? filterSensitiveData(entry.fullData) : undefined} 
                          />
                        ) : (
                          <pre>
                            {entry.fullData ? JSON.stringify(filterSensitiveData(entry.fullData), null, 2) : ''}
                          </pre>
                        )
                      )}
                    </Box>
                  }
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRevertPreviewOpen(false)}>Cancel</Button>
          <Button 
            onClick={handleRevert} 
            variant="contained"
            disabled={revertSelections.size === 0}
          >
            Revert Selected ({revertSelections.size})
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default InventoryLog;
