import { useTheme, useMediaQuery } from '@mui/material';

const useItemsPerRow = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  const isXl = useMediaQuery(theme.breakpoints.only('xl'));

  if (isXs) {
    return 1;
  } else if (isSm || isMd) {
    return 2;
  } else if (isLg || isXl) {
    return 4;
  }

  // Default fallback, though this should never be reached
  // if all breakpoints are properly defined in the theme
  return 2;
};

export default useItemsPerRow;