import { getHeaders, functionUrls } from 'core/api'

interface TransformedProperty {
  property: string
  value: string
}

interface TransformedData {
  properties: TransformedProperty[]
}

export const createHubspotContact = async (contactData: TransformedData) => {
  const functionUrl: string = functionUrls.createHubspotContact

  try {
    const res = await fetch(functionUrl, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(contactData),
    })

    
    const responseBody = await res.text()
    // ; // entire contact object

    if (res.status === 200) {
      const data = JSON.parse(responseBody)
      return data
    } else {
      
      throw new Error(`Request failed with status ${res.status}`)
    }
  } catch (error) {
    console.error('Error in createHubspotContact:', error)
    throw error
  }
}
