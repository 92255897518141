import React, { useState } from 'react'
import { Typography, Button, Box } from '@mui/material'
import CreatHubspotContact from './components/CreateUser'
import { GridColDef, GridActionsCellItem } from '@mui/x-data-grid'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import InfoIcon from '@mui/icons-material/Info'
import { deleteHubspotContact, pullHubspotContacts } from 'core/api/hubspot'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContentText from '@mui/material/DialogContentText'
import HubspotLoading from '../components/HubspotLoading'
import DataGridFirestoreCRUD from 'components/table/DataGridFirestoreCRUD'
// import { getJwtToken } from "core/api"; // const testingWithPostman

const ContactsDash = () => {
  const [openAddContact, setOpenAddContact] = useState(false)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [contactToDelete, setContactToDelete] = useState<string | null>(null)
  // const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
  const [isLoading, setIsLoading] = useState(false)
  // const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [notesDialogOpen, setNotesDialogOpen] = useState(false)
  const [selectedNotes, setSelectedNotes] = useState<string | null>(null)

  // const testingWithPostman = getJwtToken()
  // 

  const handleOpenNotesDialog = (notes: string) => {
    setSelectedNotes(notes)
    setNotesDialogOpen(true)
  }

  const handleCloseNotesDialog = () => {
    setNotesDialogOpen(false)
    setSelectedNotes(null)
  }

  const handleOpenAddContact = () => {
    setOpenAddContact(true)
  }

  const handleCloseAddContact = () => {
    setOpenAddContact(false)
  }

  const handleDeleteContact = (contactId: string) => {
    setContactToDelete(contactId)
    setConfirmDialogOpen(true)
  }

  const handleConfirmDelete = async () => {
    if (contactToDelete) {
      setIsLoading(true)
      try {
        await deleteHubspotContact(contactToDelete)
        
        await pullHubspotContacts() // Refresh the contact list
        
        alert('Contact deleted successfully')
      } catch (error) {
        console.error('Failed to delete contact:', error)
        alert('Failed to delete contact')
      } finally {
        setIsLoading(false)
        setConfirmDialogOpen(false)
        setContactToDelete(null)
      }
    }
  }

  const handleCancelDelete = () => {
    setConfirmDialogOpen(false)
    setContactToDelete(null)
  }

  // const handleOpenDetails = (contact: Contact) => {
  //   setSelectedContact(contact);
  //   setDetailsDialogOpen(true);
  // };

  // const handleCloseDetails = () => {
  //   setDetailsDialogOpen(false);
  //   setSelectedContact(null);
  // };

  const columns: GridColDef[] = [
    {
      field: 'First Name',
      headerName: 'First Name',
      width: 150,
      editable: false,
    },
    {
      field: 'Last Name',
      headerName: 'Last Name',
      width: 150,
      editable: false,
    },
    { field: 'Company', headerName: 'Company', width: 200, editable: false },
    { field: 'Email', headerName: 'Email', width: 200, editable: false },
    { field: 'Phone', headerName: 'Phone', width: 150, editable: false },
    {
      field: 'Creation Date',
      headerName: 'Date Created',
      width: 150,
      editable: false,
    },
    {
      field: 'membershipNotesAction',
      headerName: 'Notes',
      width: 75,
      renderCell: params =>
        params.row['Membership Notes'] ? (
          <GridActionsCellItem
            icon={<InfoIcon />}
            label='Notes'
            onClick={() => handleOpenNotesDialog(params.row['Membership Notes'])}
            color='inherit'
          />
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              height: '100%',
            }}
          >
            <Typography>None</Typography>
          </div>
        ),
    },
    // { field: 'vid', headerName: 'Portal ID', width: 100, editable: false },
    // {
    //   field: "detailsAction",
    //   type: "actions",
    //   headerName: "More Details",
    //   width: 150,
    //   getActions: (params) => [
    //     <GridActionsCellItem
    //       icon={<InfoIcon />}
    //       label="Details"
    //       onClick={() => handleOpenDetails(params.row)}
    //       color="inherit"
    //     />,
    //   ],
    // },
    {
      field: 'deleteAction',
      type: 'actions',
      headerName: 'Delete',
      width: 100,
      getActions: params => [
        <GridActionsCellItem
          key={params.row.vid}
          icon={<DeleteForeverIcon />}
          label='Delete'
          onClick={() => handleDeleteContact(params.row.vid)}
          color='inherit'
        />,
      ],
    },
  ]

  return (
    <>
      {/* Header */}
      {/* Add button */}
      <Box sx={{ marginBottom: 4 }}>
        <Button variant='outlined' onClick={handleOpenAddContact}>
          New Contact
        </Button>
      </Box>
      <CreatHubspotContact open={openAddContact} handleClose={handleCloseAddContact} />
      {!isLoading && (
        // Delete Warning
        <Dialog open={confirmDialogOpen} onClose={handleCancelDelete} aria-labelledby='confirm-delete-dialog'>
          <DialogTitle id='confirm-delete-dialog'>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to delete this contact?</DialogContentText>
          </DialogContent>
          {/* Deletes onclick() action */}
          <DialogActions>
            <Button onClick={handleCancelDelete} color='primary'>
              No
            </Button>
            <Button onClick={handleConfirmDelete} color='secondary'>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {/* Notes Handler */}
      {/* Notes popout */}
      <Dialog open={notesDialogOpen} onClose={handleCloseNotesDialog} aria-labelledby='membership-notes-dialog'>
        <DialogTitle id='membership-notes-dialog'>Membership Notes</DialogTitle>
        <DialogContent>
          <Typography>{selectedNotes}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNotesDialog} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* Notes Handler End */}
      {/* Nested More Details Onclick Handler Start */}
      {/* <Dialog
        open={detailsDialogOpen}
        onClose={handleCloseDetails}
        maxWidth="lg" // Set the maximum width to a larger size
        fullWidth // Ensure the dialog takes the full width allowed by maxWidth
      >
        <Box sx={{
          width: '100%', // Set width to 100% of the parent container
          minHeight: '50vh',
          position: 'relative',
          margin: '0',
          padding: '0', 
          boxSizing: 'border-box' // Include padding and border in the element's total width and height
        }}>
        <DialogTitle id="contact-details-dialog">Contact Details</DialogTitle> */}
      {/* Nested More Details Onclick Handler End */}
      {/* Open Card for Additional Details Start*/}
      {/* <DialogContent>
          {selectedContact && <ContactCard contact={selectedContact} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDetails} color="primary">
            Close
          </Button>
        </DialogActions>
        </Box>
      </Dialog> */}
      {/* Open Card for Additional Details End*/}
      {/* CRUD Table */}
      {isLoading ? (
        <HubspotLoading />
      ) : (
        <>
          <DataGridFirestoreCRUD
            columns={columns}
            collectionName='hubspot_contacts'
            filters={[]}
            rowSize={40}
            search={true}
            create={false}
            editable={false}
            deleteable={false}
          />
        </>
      )}
    </>
  )
}

export default ContactsDash
