import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react'
import {
  ColDef,
  GridReadyEvent,
  GridApi,
  FirstDataRenderedEvent,
  CellValueChangedEvent,
} from 'ag-grid-community'
import { useTheme } from '@mui/material'
import { useAuth } from 'contexts/AuthContext'
import { getUserColumns } from './getUserColumns'
import { useAdmin } from 'contexts/AdminContext'
import { useManageUsers } from 'hooks/useManageUsers'
import { useSnackbar } from 'contexts/snackBarContext'
import { updateUserBank } from 'core/api/users/updateUserBank'
import NotesDialog from 'views/SuperAdmin/Management/InventoryManagement/components/Dialogs/NotesDialog'
import { UserInfoType } from 'core/types/users'

type GridTheme = {
  name: string
  rowHeight: number
  fontSize: string
}

const defaultColDef: ColDef = {
  sortable: true,
  filter: true,
  resizable: true,
  floatingFilter: true,
  enableValue: true,
  enableRowGroup: true,
  enablePivot: true,
  flex: 1,
  minWidth: 100,
  editable: true,
  singleClickEdit: true,
}

const masterDetailOptions = {
  masterDetail: true,
  detailRowHeight: 600,
  detailRowAutoHeight: true,
  suppressMasterDetailRefit: false,
  isRowMaster: () => true,
}

const UserGridFirestoreCRUD: React.FC<{ gridRef: React.RefObject<AgGridReact>; gridTheme: GridTheme; rows: any[] }> = ({
  gridRef,
  gridTheme,
  rows,
}) => {
  const { userInfo } = useAuth()
  const { banks } = useAdmin()
  const theme = useTheme()
  const gridApiRef = useRef<GridApi | null>(null)
  const [noteDialogOpen, setNoteDialogOpen] = useState(false)
  const [currentNote, setCurrentNote] = useState('');
  const [currentRowId, setCurrentRowId] = useState<string | null>(null);

  const { handleCellValueChange } = useManageUsers();
  const { showSnackbar } = useSnackbar();

  const sideBar = useMemo(
    () => ({
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
      ],
    }),
    [],
  )

  const onCellClicked = useCallback((params: any) => {
    if (params.column?.getColDef().editable !== false) {
      params.api.startEditingCell({
        rowIndex: params.rowIndex!,
        colKey: params.column.getColId(),
      })

      if (!params.node.isSelected()) {
        params.node.setSelected(true)
      }
    }
  }, [])

  const onGridReady = useCallback((params: GridReadyEvent) => {
    gridApiRef.current = params.api;
  }, []);

  const handleCellValueChanged = useCallback((params: CellValueChangedEvent) => {
    const { data, colDef, newValue, oldValue } = params;
    
    
    if (newValue === oldValue) {
      return;
    }

    const firestoreDocId = data.firestoreDocId || data.id;

    if (colDef.field === 'bank_id') {
      const selectedBank = banks.find(bank => bank.name === newValue);
      const bankId = selectedBank ? selectedBank.id : null;
      if (bankId === oldValue) {
        return;
      }
      if (bankId) {
        updateUserBank(firestoreDocId, bankId)
          .then((response) => {
            
            if (!response) {
              throw new Error('Failed to update bank');
            }
            return handleCellValueChange(firestoreDocId, colDef.field!, bankId);
          })
          .then(() => {
            showSnackbar('Bank updated successfully', 'success', 'medium', { vertical: 'top', horizontal: 'right' }, false, '', 2000);
          })
          .catch((error) => {
            showSnackbar('Failed to update bank', 'error', 'medium', { vertical: 'top', horizontal: 'right' }, false, '', 2000);
            // Revert the cell value
            if (gridApiRef.current) {
              gridApiRef.current.refreshCells({ rowNodes: [params.node] });
            }
          });
      }
      return;
    }

    handleCellValueChange(firestoreDocId, colDef.field!, newValue)
      .then(() => {
        showSnackbar('Value updated successfully', 'success', 'medium', { vertical: 'top', horizontal: 'right' }, false, '', 2000);
      })
      .catch(() => {
        showSnackbar('Failed to update value', 'error', 'medium', { vertical: 'top', horizontal: 'right' }, false, '', 2000);
      });
    
    if (gridApiRef.current) {
      const allColumnIds: string[] = [];
      gridApiRef.current.getColumns()?.forEach(column => {
        allColumnIds.push(column.getId());
      });
      gridApiRef.current.autoSizeColumns(allColumnIds);
    }
  }, [handleCellValueChange, banks, showSnackbar]);

  const onUndoRedo = useCallback(() => {
    showSnackbar('Undo/Redo successful', 'success', 'medium', { vertical: 'top', horizontal: 'right' }, false, '', 2000);
  }, [showSnackbar]);

  const isAdmin = userInfo?.user_type === 'admin'
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  useEffect(() => {
    const loadColumns = async () => {
      if (banks.length === 0) {
        return;
      }
      const banksObject = Object.fromEntries(banks.map(bank => [bank.id, bank.name]));
      const cols = await getUserColumns(isAdmin, banksObject, 
        (message, severity) => showSnackbar(message, severity as "success" | "error" | "warning" | "info"), openNoteDialog, userInfo ?? {} as UserInfoType
      );
      setColumnDefs(cols as ColDef[]);
    };
    loadColumns();
  }, [isAdmin, banks, showSnackbar]);

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    if (gridApiRef.current) {
      gridApiRef.current.sizeColumnsToFit();
      const allColumnIds: string[] = [];
      gridApiRef.current.getColumns()?.forEach(column => {
        allColumnIds.push(column.getId());
      });
      gridApiRef.current.autoSizeColumns(allColumnIds);
    }
  }, []);

  const handleNoteUpdate = async (firestoreDocId: string, newNote: string) => {
    try {
      await handleCellValueChange(firestoreDocId, 'notes', newNote);
      showSnackbar('Notes updated successfully', 'success', 'medium', { vertical: 'top', horizontal: 'right' }, false, '', 2000);

      if (gridApiRef.current) {
        const rowNode = gridApiRef.current.getRowNode(firestoreDocId);
        if (rowNode) {
          rowNode.setDataValue('notes', newNote);
        }
      }
    } catch (error) {
      showSnackbar('Failed to update notes', 'error', 'medium', { vertical: 'top', horizontal: 'right' }, false, '', 2000);
    }
  };

  const openNoteDialog = (row: any) => {
    
    setCurrentNote(row.notes || '');
    setCurrentRowId(row.firestoreDocId);
    setNoteDialogOpen(true);
  };

  const saveNote = (newNote: string) => {
    if (currentRowId) {
      handleNoteUpdate(currentRowId, newNote);
    }
  };

  const gridConfig = useMemo(() => ({
    columnDefs,
    rowData: rows,
    defaultColDef,
    ...masterDetailOptions,
    rowSelection: 'multiple' as const,
    sideBar,
    onCellClicked,
    onCellValueChanged: handleCellValueChanged,
    onGridReady,
    suppressColumnVirtualisation: true,
    colResizeDefault: 'shift' as const,
    domLayout: 'autoHeight' as const,
    rowHeight: gridTheme.rowHeight,
    rowStyle: { fontSize: gridTheme.fontSize },
    groupDisplayType: 'groupRows' as const,
    animateRows: true,
    rowGroupPanelShow: 'always' as const,
    suppressAggFuncInHeader: true,
    
    statusBar: {
      statusPanels: [
        { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
        { statusPanel: 'agTotalRowCountComponent' },
        { statusPanel: 'agFilteredRowCountComponent' },
        { statusPanel: 'agSelectedRowCountComponent' },
        { statusPanel: 'agAggregationComponent' },
      ],
    },
    onFirstDataRendered,
    onUndoRedoStarted: onUndoRedo,
    onUndoRedoEnded: onUndoRedo,
  }), [
    columnDefs,
    rows,
    sideBar,
    onCellClicked,
    handleCellValueChanged,
    onGridReady,
    gridTheme.rowHeight,
    gridTheme.fontSize,
    onFirstDataRendered,
    onUndoRedo,
  ])

  return (
    <div
      className={theme.palette.mode === 'dark' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine'}
      style={{ width: '100%', height: 'auto', minHeight: '400px' }}
    >
      <AgGridReact
        undoRedoCellEditing={true}
        undoRedoCellEditingLimit={20}
        ref={gridRef}
        {...gridConfig}
        columnDefs={columnDefs}
      />
      <NotesDialog
        open={noteDialogOpen}
        initialValue={currentNote}
        onClose={() => setNoteDialogOpen(false)}
        onSave={saveNote}
      />
    </div>
  )
}

export default React.memo(UserGridFirestoreCRUD)
