import React, { useEffect } from "react";
import { AppProvider } from "@toolpad/core";
import { Box, useTheme } from "@mui/material";
import type {
  Router,
  Session,
} from "@toolpad/core";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";
import CoreLayout from "./CoreLayout";
import { getSuperUserPages } from 'router/pages/superUserPages'
import { clientPages } from 'router/pages/clientPages'
import { dealerPages } from 'router/pages/dealerPages'
import { bankPages } from 'router/pages/bankPages'
import { logoLight } from 'assets'

export const LayoutProvider = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();

  const theme = useTheme();


  const router = React.useMemo<Router>(
    () => ({
      pathname: location.pathname,
      searchParams: new URLSearchParams(location.search),
      navigate: (path: any) => navigate(path),
    }),
    [location, navigate]
  );

  const { userInfo, logout, isLoading } = useAuth();

  useEffect(() => {
    if (userInfo) {
      setSession({
        user: {
          id: userInfo?.id,
          name: userInfo?.first_name + " " + userInfo?.last_name,
          email: userInfo?.email,
        },
      });
    }
  }, [userInfo]);

  const userType = userInfo?.user_type;

  const superuserPages = getSuperUserPages(userType || '', userInfo?.isOwner || false);

  const pages = React.useMemo(() => {
    switch (userType) {
      case "admin":
        return superuserPages;
      case "babyAdmin":
        return superuserPages;
      case "client":
        return clientPages;
      case "dealer":
        return dealerPages;
      case "bank":
        return bankPages;
      default:
        return [];
    }
  }, [userType]);

  const [session, setSession] = React.useState<Session | null>({
    user: {
      id: userInfo?.id,
      name: userInfo?.first_name + " " + userInfo?.last_name,
      email: userInfo?.email,
    },
  });

  const authentication = React.useMemo(() => {
    return {
      signIn: () => {
        setSession({
          user: {
            id: userInfo?.id,
            name: userInfo?.first_name + " " + userInfo?.last_name,
            email: userInfo?.email,
          },
        });
      },
      signOut: () => {
        setSession(null);
        logout();
        navigate("/login");
      },
    };
  }, [userInfo, logout]);

  return (
    <AppProvider
      authentication={authentication}
      session={session}
      navigation={pages}
      theme={theme}
      router={router}
      branding={{
        logo: (
          <Box sx={{ paddingLeft: 2 }}>
            <Box
              component="img"
              src={logoLight}
              alt="Optic Truck Works"
              // sx={{
              //   maxWidth: { xs: "200px", sm: "300px", md: "400px" },
              //   // width: "100%", 
              //   height: "auto",
              //   borderRadius: "10px",
              // }}
            />
          </Box>
        ),
        title: "",
      }}
    >
      <CoreLayout>
        <Outlet />
      </CoreLayout>
    </AppProvider>
  );
};
