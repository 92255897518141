import React, { useEffect, useState, useMemo } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  Grid,
  CircularProgress,
  Alert,
  Divider,
  FormControlLabel,
  Switch,
  Checkbox,
} from '@mui/material'
import { ref, listAll, getDownloadURL } from 'firebase/storage'
import { storage } from 'core/config/firebase'
import { PhotoUploadVehicle } from 'core/types/photoUpload'
import PhotoPreviewDialog from './PhotoPreviewDialog'
import { useSnackbar } from 'contexts/snackBarContext'
import { uploadFileToInventory } from 'core/api/inventory/addFileToInventory'
import { useMutation, useQueryClient } from 'react-query'

interface ViewPhotoSubmissionsDialogProps {
  open: boolean
  onClose: () => void
  vehicles: PhotoUploadVehicle[]
  userId: string
}

interface PhotoItem {
  id: string
  url: string
  vehicleId: string
  vehicleInfo: string
}

interface PhotoPosition {
  id: number
  label: string
}

interface SelectedPhotos {
  [vehicleId: string]: {
    [photoId: string]: boolean
  }
}

const PHOTO_POSITIONS: PhotoPosition[] = [
  { id: 1, label: "FRONT DRIVER-SIDE CORNER" },
  { id: 2, label: "DRIVER SIDE FULL" },
  { id: 3, label: "REAR DRIVER-SIDE CORNER" },
  { id: 4, label: "BACK FULL" },
  { id: 5, label: "REAR PASSENGER-SIDE CORNER" },
  { id: 6, label: "PASSENGER SIDE FULL" },
  { id: 7, label: "FRONT PASSENGER-SIDE CORNER" },
  { id: 8, label: "FRONT FULL" },
  { id: 9, label: "DASH" },
  { id: 10, label: "LEFT SIDE ENGINE" },
  { id: 11, label: "RIGHT SIDE ENGINE" },
  { id: 12, label: "DRIVER SIDE INT." },
  { id: 13, label: "PASSENGER SIDE INT." },
  { id: 14, label: "VIN PLATE" }
]

const ViewPhotoSubmissionsDialog = ({ open, onClose, vehicles, userId }: ViewPhotoSubmissionsDialogProps) => {
  const [photos, setPhotos] = useState<PhotoItem[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [selectedVehicleId, setSelectedVehicleId] = useState<string | 'all'>('all')
  const [vehiclesWithPhotos, setVehiclesWithPhotos] = useState<PhotoUploadVehicle[]>([])
  const [showOnlyUploaded, setShowOnlyUploaded] = useState(false)
  const [selectedPhotos, setSelectedPhotos] = useState<SelectedPhotos>({})
  const [previewPhoto, setPreviewPhoto] = useState<{ url: string; label: string } | null>(null)

  const { showSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const { mutate: uploadPhotos, isLoading: isUploading } = useMutation(
    async () => {
      const selectedPhotosList = photos.filter(photo => 
        selectedPhotos[photo.vehicleId]?.[photo.id]
      )

      const uploadPromises = selectedPhotosList.map(async (photo) => {
        try {
          const file = await downloadPhotoAsFile(photo.url, `${photo.id}.jpg`)
          return uploadFileToInventory('master_inventory', photo.vehicleId, file, true)
        } catch (error) {
          console.error(`Error processing photo ${photo.id}:`, error)
          throw error
        }
      })
      
      return Promise.all(uploadPromises)
    },
    {
      onSuccess: () => {
        showSnackbar('Photos added to inventory successfully!', 'success')
        setSelectedPhotos({})
        queryClient.invalidateQueries(['photos'])
      },
      onError: (error) => {
        console.error('Error uploading photos:', error)
        showSnackbar('Error adding photos to inventory', 'error')
      }
    }
  )

  useEffect(() => {
    const fetchPhotos = async () => {
      if (!open || !userId || !vehicles.length) return

      try {
        setLoading(true)
        setError(null)
        
        const allPhotosPromises = vehicles.map(async (vehicle) => {
          const storagePath = `users/${userId}/photos/${vehicle.id}`
          const storageRef = ref(storage, storagePath)
          
          try {
            const result = await listAll(storageRef)
            if (result.items.length === 0) return { photos: [], vehicle: null }
            
            const vehiclePhotosPromises = result.items.map(async (item) => {
              try {
                const url = await getDownloadURL(item)
                return {
                  id: item.name,
                  url: url,
                  vehicleId: vehicle.id,
                  vehicleInfo: `${vehicle.year} ${vehicle.make} ${vehicle.model} (${vehicle.vin})`
                }
              } catch (err) {
                return null
              }
            })
            
            const vehiclePhotos = await Promise.all(vehiclePhotosPromises)
            const filteredPhotos = vehiclePhotos.filter((photo): photo is PhotoItem => photo !== null)
            return { 
              photos: filteredPhotos,
              vehicle: filteredPhotos.length > 0 ? vehicle : null
            }
          } catch (err) {
            return { photos: [], vehicle: null }
          }
        })
        
        const results = await Promise.all(allPhotosPromises)
        const allPhotos = results.flatMap(result => result.photos)
        const vehiclesWithSubmissions = results
          .map(result => result.vehicle)
          .filter((vehicle): vehicle is PhotoUploadVehicle => vehicle !== null)
        
        setPhotos(allPhotos)
        setVehiclesWithPhotos(vehiclesWithSubmissions)
        
        if (selectedVehicleId !== 'all' && !vehiclesWithSubmissions.some(v => v.id === selectedVehicleId)) {
          setSelectedVehicleId('all')
        }
      } catch (error) {
        setError(error instanceof Error ? error.message : 'An error occurred while fetching photos')
      } finally {
        setLoading(false)
      }
    }

    fetchPhotos()
  }, [open, userId, vehicles])

  const displayedPhotos = useMemo(() => {
    if (selectedVehicleId === 'all') return photos
    return photos.filter(photo => photo.vehicleId === selectedVehicleId)
  }, [photos, selectedVehicleId])

  const getPhotoProgress = (photos: PhotoItem[]) => {
    const uniquePhotos = new Set(photos.map(p => p.id))
    return `${uniquePhotos.size}/${PHOTO_POSITIONS.length}`
  }

  const groupPhotosByVehicle = (photos: PhotoItem[]) => {
    return photos.reduce((acc, photo) => {
      if (!acc[photo.vehicleId]) {
        acc[photo.vehicleId] = {
          vehicleInfo: photo.vehicleInfo,
          photos: []
        }
      }
      acc[photo.vehicleId].photos.push(photo)
      return acc
    }, {} as Record<string, { vehicleInfo: string, photos: PhotoItem[] }>)
  }

  const handlePhotoSelect = (vehicleId: string, photoId: string) => {
    setSelectedPhotos(prev => ({
      ...prev,
      [vehicleId]: {
        ...(prev[vehicleId] || {}),
        [photoId]: !(prev[vehicleId]?.[photoId] || false)
      }
    }))
  }

  const hasSelectedPhotos = () => {
    return Object.values(selectedPhotos).some(vehicle => 
      Object.values(vehicle).some(selected => selected)
    )
  }

  const handlePhotoClick = (url: string, label: string) => {
    setPreviewPhoto({ url, label })
  }

  const downloadPhotoAsFile = (url: string, fileName: string): Promise<File> => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.responseType = 'blob'
      
      xhr.onload = () => {
        if (xhr.status === 200) {
          const blob = xhr.response
          const file = new File([blob], fileName, { type: 'image/jpeg' })
          resolve(file)
        } else {
          reject(new Error(`Failed to download photo: ${xhr.statusText}`))
        }
      }
      
      xhr.onerror = () => {
        reject(new Error('Network error occurred while downloading photo'))
      }
      
      xhr.open('GET', url)
      xhr.send()
    })
  }

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="lg" 
      fullWidth
      PaperProps={{
        sx: { 
          borderRadius: 1,
          minHeight: '80vh',
        }
      }}
    >
      <DialogTitle sx={{ 
        pb: 1, 
        fontSize: '1.5rem',
        fontWeight: 500
      }}>
        Vehicle Photos
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ p: 3 }}>
        {vehiclesWithPhotos.length > 0 && (
          <Box sx={{ mb: 4 }}>
            <Typography 
              variant="h6" 
              sx={{ 
                mb: 2,
                color: 'text.secondary',
                fontSize: '1.1rem'
              }}
            >
              Select Vehicle
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Button
                variant={selectedVehicleId === 'all' ? 'contained' : 'outlined'}
                onClick={() => setSelectedVehicleId('all')}
                sx={{ mr: 1, mb: 1 }}
              >
                All Vehicles ({vehiclesWithPhotos.length})
              </Button>
              {vehiclesWithPhotos.map((vehicle) => (
                <Button
                  key={vehicle.id}
                  variant={selectedVehicleId === vehicle.id ? 'contained' : 'outlined'}
                  onClick={() => setSelectedVehicleId(vehicle.id)}
                  sx={{ mr: 1, mb: 1 }}
                >
                  {vehicle.year} {vehicle.make} {vehicle.model}
                </Button>
              ))}
            </Box>
          </Box>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <FormControlLabel
            control={
              <Switch
                checked={showOnlyUploaded}
                onChange={(e) => setShowOnlyUploaded(e.target.checked)}
                color="primary"
              />
            }
            label="Show only uploaded photos"
          />
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
            <CircularProgress />
          </Box>
        ) : displayedPhotos.length > 0 ? (
          <Box sx={{ mt: 2 }}>
            {selectedVehicleId === 'all' ? (
              Object.entries(groupPhotosByVehicle(displayedPhotos)).map(([vehicleId, data]) => (
                <Box key={vehicleId} sx={{ mb: 4 }}>
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    mb: 2,
                    pb: 1,
                    borderBottom: '2px solid',
                    borderColor: 'primary.main'
                  }}>
                    <Typography variant="h6" sx={{ color: 'primary.main' }}>
                      {data.vehicleInfo}
                    </Typography>
                    <Typography variant="subtitle1">
                      Photos Submitted: {getPhotoProgress(data.photos)}
                    </Typography>
                  </Box>
                  <Grid container spacing={2}>
                    {PHOTO_POSITIONS
                      .filter(position => !showOnlyUploaded || data.photos.some(p => p.id === position.label))
                      .map((position) => {
                        const photo = data.photos.find(p => p.id === position.label)
                        
                        return (
                          <Grid item xs={12} sm={6} md={4} key={`${vehicleId}-${position.id}`}>
                            <Box
                              sx={{
                                height: 250,
                                border: '1px solid',
                                borderColor: 'divider',
                                borderRadius: 1,
                                overflow: 'hidden',
                                position: 'relative',
                                bgcolor: 'background.paper',
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <Typography
                                variant="subtitle2"
                                sx={{
                                  p: 1,
                                  bgcolor: 'background.default',
                                  borderBottom: '1px solid',
                                  borderColor: 'divider',
                                }}
                              >
                                {position.label}
                              </Typography>
                              {photo ? (
                                <Box sx={{ flex: 1, position: 'relative' }}>
                                  <Box
                                    sx={{
                                      position: 'absolute',
                                      top: 8,
                                      left: 8,
                                      zIndex: 1
                                    }}
                                  >
                                    <Checkbox
                                      checked={!!selectedPhotos[vehicleId]?.[photo.id]}
                                      onChange={() => handlePhotoSelect(vehicleId, photo.id)}
                                      sx={{
                                        bgcolor: 'rgba(255, 255, 255, 0.8)',
                                        borderRadius: 1,
                                        '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.9)' }
                                      }}
                                    />
                                  </Box>
                                  <Box
                                    onClick={() => handlePhotoClick(photo.url, position.label)}
                                    sx={{
                                      width: '100%',
                                      height: '100%',
                                      cursor: 'pointer',
                                      '&:hover': {
                                        opacity: 0.9,
                                      }
                                    }}
                                  >
                                    <img
                                      src={photo.url}
                                      alt={position.label}
                                      style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'contain',
                                        backgroundColor: '#f5f5f5',
                                      }}
                                    />
                                  </Box>
                                </Box>
                              ) : (
                                <Box
                                  sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    bgcolor: '#f5f5f5',
                                  }}
                                >
                                  <Typography color="text.secondary">
                                    No photo submitted
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </Grid>
                        )
                      })}
                  </Grid>
                </Box>
              ))
            ) : (
              <Box>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                  Photos Submitted: {getPhotoProgress(displayedPhotos)}
                </Typography>
                <Grid container spacing={2}>
                  {PHOTO_POSITIONS
                    .filter(position => !showOnlyUploaded || displayedPhotos.some(p => p.id === position.label))
                    .map((position) => {
                      const photo = displayedPhotos.find(p => p.id === position.label)
                      
                      return (
                        <Grid item xs={12} sm={6} md={4} key={position.id}>
                          <Box
                            sx={{
                              height: 250,
                              border: '1px solid',
                              borderColor: 'divider',
                              borderRadius: 1,
                              overflow: 'hidden',
                              position: 'relative',
                              bgcolor: 'background.paper',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              sx={{
                                p: 1,
                                bgcolor: 'background.default',
                                borderBottom: '1px solid',
                                borderColor: 'divider',
                              }}
                            >
                              {position.label}
                            </Typography>
                            {photo ? (
                              <Box sx={{ flex: 1, position: 'relative' }}>
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    top: 8,
                                    left: 8,
                                    zIndex: 1
                                  }}
                                >
                                  <Checkbox
                                    checked={!!selectedPhotos[selectedVehicleId]?.[photo.id]}
                                    onChange={() => handlePhotoSelect(selectedVehicleId, photo.id)}
                                    sx={{
                                      bgcolor: 'rgba(255, 255, 255, 0.8)',
                                      borderRadius: 1,
                                      '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.9)' }
                                    }}
                                  />
                                </Box>
                                <img
                                  src={photo.url}
                                  alt={position.label}
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                    backgroundColor: '#f5f5f5',
                                  }}
                                />
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    bgcolor: 'rgba(0,0,0,0.7)',
                                    color: 'white',
                                    p: 1,
                                  }}
                                >
                                  <Typography variant="caption" sx={{ fontSize: '0.75rem' }}>
                                    {photo.vehicleInfo}
                                  </Typography>
                                </Box>
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  flex: 1,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  bgcolor: '#f5f5f5',
                                }}
                              >
                                <Typography color="text.secondary">
                                  No photo submitted
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Grid>
                      )
                    })}
                </Grid>
              </Box>
            )}
          </Box>
        ) : (
          <Box 
            display="flex" 
            justifyContent="center" 
            alignItems="center" 
            minHeight="400px"
          >
            <Typography color="text.secondary">
              No photos found for {selectedVehicleId === 'all' ? 'any vehicles' : 'this vehicle'}
            </Typography>
          </Box>
        )}
      </DialogContent>
      <Divider />
      <DialogActions sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="contained"
          color="primary"
          disabled={!hasSelectedPhotos() || isUploading}
          onClick={() => uploadPhotos()}
          sx={{ minWidth: 200 }}
        >
          {isUploading ? 'Adding Photos...' : 'Add Selected Photos to Inventory'}
        </Button>
        <Button 
          onClick={onClose}
          variant="contained"
          sx={{ minWidth: 100 }}
        >
          Close
        </Button>
      </DialogActions>

      <PhotoPreviewDialog
        open={!!previewPhoto}
        onClose={() => setPreviewPhoto(null)}
        photoUrl={previewPhoto?.url || ''}
        photoLabel={previewPhoto?.label || ''}
      />
    </Dialog>
  )
}

export default ViewPhotoSubmissionsDialog