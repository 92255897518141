import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DeleteIcon from '@mui/icons-material/Delete'
import UploadIcon from '@mui/icons-material/Upload'
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  IconButton,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material'
import { useLocalStorageState } from '@toolpad/core'
import { useAuth } from 'contexts/AuthContext'
import { useSnackbar } from 'contexts/snackBarContext'
import { readInventoryFromCarFaxXLSX } from 'core/api/inventory/excelParsers'
import { mapPartialToFullVehicle, storeImportedVehicles } from 'core/utils/inventoryUtils'
import { useImportedVehiclesExcels } from 'hooks/useImportedVehiclesExcels'
import React, { useState } from 'react'
import InventoryImporter from './InventoryImporter'
import { useIsMobile } from 'hooks/screen/useIsMobile'

const InventoryImporterManagement = () => {
  const [selectedExcelId, setSelectedExcelId] = useLocalStorageState<string | null>('importerSelectedExcelId', null)
  const { showSnackbar } = useSnackbar()
  const { userInfo } = useAuth()
  const isMobile = useIsMobile()

  const [isProcessing, setIsProcessing] = useState(false)
  const [progress, setProgress] = useState(0)
  const [processedCount, setProcessedCount] = useState(0)
  const importCollection = 'imported_vehicles'
  const {
    importedExcels,
    addImportedExcel,
    deleteImportedExcel,
    isLoading: isLoadingImportedExcels,
  } = useImportedVehiclesExcels({
    dontShowUpdates: true,
  })

  const currentExcel = importedExcels?.find(excel => excel.id === selectedExcelId)

  const handleNewImport = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return

    const file = event.target.files[0]

    const partialVehicles = await readInventoryFromCarFaxXLSX(file)
    const vehicles = partialVehicles.map(mapPartialToFullVehicle).filter(vehicle => vehicle.link !== '')

    setIsProcessing(true)
    setProgress(0)
    setProcessedCount(0)

    const { id: newExcelId } = await addImportedExcel({
      fileName: file.name,
      importedBy: `${userInfo!.first_name}, ${userInfo!.last_name}`,
      importedAt: new Date().toISOString(),
    })
    try {
      await storeImportedVehicles(
        vehicles,
        importCollection,
        currentProgress => {
          setProgress(currentProgress)
          setProcessedCount(Math.floor((currentProgress * vehicles.length) / 100))
        },
        {
          parentDocId: newExcelId,
          name: 'vehicles',
        },
      )

      setSelectedExcelId(newExcelId)
    } catch (error) {
      console.error('Error storing vehicles', error)
      showSnackbar('Error importing vehicles', 'error')
    } finally {
      setIsProcessing(false)
      setProgress(0)
      setProcessedCount(0)
    }
  }

  const handleDeleteExcel = (excelId: string) => {
    
    deleteImportedExcel(excelId)
  }

  const handleViewExcel = (excelId: string) => {
    setSelectedExcelId(excelId)
  }

  const renderExcelList = () => {
    if (isLoadingImportedExcels) {
      return <CircularProgress />
    }

    const sortedExcels =
      importedExcels
        ?.filter(
          (excel): excel is NonNullable<typeof excel> =>
            excel != null && excel.importedAt != null && excel.importedBy != null,
        )
        .sort((a, b) => {
          const dateA = new Date(a.importedAt).getTime()
          const dateB = new Date(b.importedAt).getTime()
          return dateB - dateA // Descending order
        }) || []

    const groupedExcels = sortedExcels.reduce<Record<string, typeof sortedExcels>>((acc, excel) => {
      const key = excel.importedBy
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(excel)
      return acc
    }, {})

    return (
      <Box>
        {Object.entries(groupedExcels || {}).map(([importedBy, excels]) => (
          <Box key={importedBy} mb={3}>
            <Typography variant='h6' gutterBottom>
              {importedBy}
            </Typography>
            <List component={Paper} elevation={3}>
              {excels.map(excel => (
                <ListItem
                  key={excel.id}
                  onClick={event => {
                    event.stopPropagation()
                    handleViewExcel(excel.id)
                  }}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'action.hover',
                      cursor: 'pointer',
                    },
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography variant='subtitle1' component='div'>
                        {excel.fileName}
                      </Typography>
                    }
                    secondary={
                      <>
                        <Typography variant='body2' color='text.secondary'>
                          Imported by {excel.importedBy}
                        </Typography>
                        <Typography variant='body2' color='text.secondary'>
                          {new Date(excel.importedAt).toLocaleString()}
                        </Typography>
                      </>
                    }
                  />
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Tooltip title='Delete'>
                      <IconButton
                        aria-label='delete'
                        onClick={event => {
                          event.stopPropagation()
                          handleDeleteExcel(excel.id)
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </ListItem>
              ))}
            </List>
          </Box>
        ))}
      </Box>
    )
  }

  return (
    <Box>
      {!isMobile &&<Typography variant='h4'>Inventory Importer</Typography>}
      {selectedExcelId && (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              component='button'
              variant='body1'
              onClick={() => setSelectedExcelId(null)}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <ArrowBackIcon sx={{ mr: 0.5 }} fontSize='small' />
              All Imports
            </Link>
            <Typography variant='body1' color='text.primary' sx={{ display: 'flex', alignItems: 'center' }}>
              {currentExcel ? ( isMobile ? `${currentExcel.fileName.slice(0, 20)}...` : currentExcel.fileName) : 'Unknown Excel'}
            </Typography>
          </Breadcrumbs>
        </Box>
      )}

      {isProcessing && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '400px',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            zIndex: 1000,
            textAlign: 'center',
          }}
        >
          <CircularProgress variant='determinate' value={progress} size={80} thickness={4} sx={{ mb: 2 }} />
          <Typography variant='h6' sx={{ mb: 1 }}>
            Importing Vehicles
          </Typography>
          <Typography variant='body1' color='text.secondary' sx={{ mb: 2 }}>
            {processedCount} vehicles completed ({Math.round(progress)}%)
          </Typography>
          <LinearProgress
            variant='determinate'
            value={progress}
            sx={{
              height: 8,
              borderRadius: 4,
              '& .MuiLinearProgress-bar': {
                borderRadius: 4,
              },
            }}
          />
        </Box>
      )}
      {!selectedExcelId && !isProcessing && (
        <Box>
          <Button variant='contained' component='label' startIcon={<UploadIcon />} sx={{ my: 2 }}>
            Import Excel File
            <input type='file' hidden accept='.xlsx,.xls' onChange={handleNewImport} />
          </Button>

          <Typography variant='h6' gutterBottom>
            Previously Imported Excels
          </Typography>
          {renderExcelList()}
        </Box>
      )}
      {!isProcessing && selectedExcelId && (
        <InventoryImporter
          inventoryCollection={importCollection}
          subCollection={{
            name: 'vehicles',
            parentDocId: selectedExcelId,
          }}
        />
      )}
    </Box>
  )
}

export default InventoryImporterManagement
