import { Paper, Typography, Stack, Chip, Box, Grid } from '@mui/material'
import { vehicleTypeOptions, getVehicleSizeOptions } from 'core/constants/inventory'
import React from 'react'
import { useNavigate } from 'react-router-dom'

interface VehicleTypeDistributionProps {
  vehicles: Vehicle[]
}

export const VehicleTypeDistribution = ({ vehicles }: VehicleTypeDistributionProps) => {
  const navigate = useNavigate()

  // Filter for only active inventory
  const activeVehicles = vehicles.filter(vehicle => 
    vehicle.status === 'Available' || vehicle.status === 'Pending Sale'
  )

  // Count vehicles by type and size
  const distribution = activeVehicles.reduce((acc: Record<string, Record<string, number>>, vehicle) => {
    const type = vehicle.type || 'Unknown'
    const size = vehicle.size || 'Unknown'
    
    if (!acc[type]) {
      acc[type] = {}
    }
    acc[type][size] = (acc[type][size] || 0) + 1
    return acc
  }, {})

  // Color mapping for different vehicle types
  const typeColors: Record<string, string> = {
    'Step van': '#474D66', // Navy
    'Cargo van': '#9747FF', // Purple
    'Cutaway': '#00B574', // Green
    'Box truck': '#2196F3', // Blue
  }

  const totalActive = activeVehicles.length

  const formatLabel = (label: string, count: number) => (
    <Box component="span" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
      <span>{label}:</span>
      <Box
        component="span"
        sx={{
          fontWeight: 700,
          fontSize: '1.1em',
          color: 'white'
        }}
      >
        {count}
      </Box>
    </Box>
  )

  const formatPercentage = (percentage: number) => (
    <Box component="span" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
      <Box
        component="span"
        sx={{
          fontWeight: 600,
          fontSize: '0.95rem',
          color: 'text.primary'
        }}
      >
        {percentage}%
      </Box>
      <Box
        component="span"
        sx={{
          fontSize: '0.85rem',
          color: 'text.secondary'
        }}
      >
        of inventory
      </Box>
    </Box>
  )

  const formatSizeLabel = (size: string, count: number) => (
    <Box component="span" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
      <span>{size}:</span>
      <Box
        component="span"
        sx={{
          fontWeight: 700,
          color: 'inherit'
        }}
      >
        {count}
      </Box>
    </Box>
  )

  const handleTypeClick = (vehicleType: string) => {
    const filterModel = {
      type: {
        filterType: 'multi',
        filterModels: [
          {
            filterType: 'text',
            type: 'contains',
            filter: vehicleType
          },
          null
        ]
      }
    }
    const hashValue = encodeURIComponent(JSON.stringify(filterModel))
    navigate(`/admin/inventory/optic-inventory-v2#${hashValue}`)
  }

  const handleSizeClick = (vehicleType: string, size: string) => {
    const filterModel = {
      type: {
        filterType: 'multi',
        filterModels: [
          {
            filterType: 'text',
            type: 'contains',
            filter: vehicleType
          },
          null
        ]
      },
      size: {
        filterType: 'multi',
        filterModels: [
          {
            filterType: 'text',
            type: 'contains',
            filter: size
          },
          null
        ]
      }
    }
    const hashValue = encodeURIComponent(JSON.stringify(filterModel))
    navigate(`/admin/inventory/optic-inventory-v2#${hashValue}`)
  }

  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <Stack spacing={3}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" sx={{ fontWeight: 500 }}>
            Active Inventory Distribution
          </Typography>
          <Chip
            label={`${totalActive} Total Vehicles`}
            sx={{
              bgcolor: '#474D66',
              color: 'white',
              borderRadius: '16px',
              fontSize: '0.875rem',
              fontWeight: 500
            }}
          />
        </Stack>

        <Grid container spacing={3}>
          {vehicleTypeOptions.map((vehicleType) => {
            const sizes = getVehicleSizeOptions(vehicleType)
            const typeTotal = Object.values(distribution[vehicleType] || {}).reduce((sum, count) => sum + count, 0)
            
            if (typeTotal === 0) return null
            
            const percentage = Math.round((typeTotal / totalActive) * 100)
            
            return (
              <Grid item xs={12} md={6} key={vehicleType}>
                <Box>
                  <Stack direction="row" alignItems="center" spacing={2} mb={1}>
                    <Chip
                      label={formatLabel(vehicleType, typeTotal)}
                      onClick={() => handleTypeClick(vehicleType)}
                      sx={{
                        bgcolor: typeColors[vehicleType],
                        color: 'white',
                        fontSize: '0.9rem',
                        fontWeight: 500,
                        py: 1.5,
                        cursor: 'pointer',
                        '&:hover': {
                          bgcolor: typeColors[vehicleType],
                          opacity: 0.9,
                        },
                        '& .MuiChip-label': { 
                          px: 2,
                          display: 'flex',
                          alignItems: 'center' 
                        }
                      }}
                    />
                    <Typography variant="body2">
                      {formatPercentage(percentage)}
                    </Typography>
                  </Stack>
                  
                  <Box 
                    sx={{ 
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 1,
                      ml: 2
                    }}
                  >
                    {sizes.map((size) => {
                      const count = (distribution[vehicleType] || {})[size] || 0
                      if (count === 0) return null
                      
                      return (
                        <Chip
                          key={`${vehicleType}-${size}`}
                          label={formatSizeLabel(size, count)}
                          onClick={() => handleSizeClick(vehicleType, size)}
                          sx={{
                            bgcolor: 'transparent',
                            border: `1px solid ${typeColors[vehicleType]}`,
                            color: 'text.primary',
                            fontSize: '0.8rem',
                            cursor: 'pointer',
                            '&:hover': {
                              bgcolor: `${typeColors[vehicleType]}15`,
                            },
                            '& .MuiChip-label': { 
                              px: 1.5,
                              display: 'flex',
                              alignItems: 'center'
                            },
                            '& span span': {
                              color: typeColors[vehicleType]
                            }
                          }}
                        />
                      )
                    })}
                  </Box>
                </Box>
              </Grid>
            )
          })}
        </Grid>
      </Stack>
    </Paper>
  )
}
