/* eslint-disable */

// TODO: @adamsiwiec1 i will type this

import React, { createContext, useContext, useState, useEffect, ReactNode, useMemo } from 'react'
import {
  User,
  UserCredential,
  onAuthStateChanged,
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  signOut,
  getAdditionalUserInfo,
  verifyPasswordResetCode,
  confirmPasswordReset,
  sendPasswordResetEmail,
  applyActionCode,
} from '@firebase/auth'
import { auth } from 'core/config/firebase'
import { removeJwtToken, setJwtToken, getJwtToken } from 'core/api'
import { createUser, getUserProfile, checkUserRole, signUpAndSendEmail } from 'core/api/users'
import { getUTCtimeNow } from 'core/utils'
import { handleLoginWithGoogle } from 'core/api/users/handleLoginWithGoogle'
import { handleLoginWithEmail } from 'core/api/users/handleLoginWithEmail'
import { resendVerificationEmail } from 'core/api/users'
import HyperDX from '@hyperdx/browser'
import { logHyperDxAction } from 'core/api/hyperdx/logHyperDxAction'
import { UserInfoType, LoginCredentials, SignUpCredentials } from 'core/types/users'

// TODO: Doing this as i type ill probably finish https://firebase.google.com/docs/auth/custom-email-handler#create_the_email_action_handler_page

type AuthContextType = {
  isAuthenticated: boolean
  setIsAuthenticated: (isAuthenticated: boolean) => void
  userInfo: UserInfoType | null
  setUserInfo: (userInfo: UserInfoType) => void
  isSuperuser: () => boolean
  isLoading: boolean
  loginWithEmail: (credentials: LoginCredentials) => Promise<any>
  loginWithGoogle: () => Promise<any>
  logout: () => void
  // verify: (token: string) => Promise<Response>;
  handleResendVerificationEmail: (email: string) => Promise<boolean>
  handleSendPasswordResetEmail: (email: string) => Promise<boolean>
  handleVerifyPasswordResetCode: (code: string) => Promise<string>
  handleVerifyActionCode: (code: string) => Promise<boolean>
  handleConfirmPasswordReset: (code: string, newPassword: string) => Promise<boolean>
  getUserInfo: () => Promise<UserInfoType | null>
  signUp: (credentials: SignUpCredentials) => Promise<boolean>
  isVerified: () => boolean
}

const AuthContext = createContext<AuthContextType | null>(null)

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}

type AuthProviderProps = {
  children: ReactNode
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [userInfo, setUserInfo] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(true) // handle auth check loading state
  const [currentUserRole, setCurrentUserRole] = useState('')
  const [isExpired, setIsExpired] = useState(false)

  // const [isSuperuser, setIsSuperuser] = useState(false); // isSuperuser state
  const isSuperuser = () => {
    return userInfo ? userInfo?.user_type === 'admin' || userInfo?.user_type === 'babyAdmin': false
  }
  const isVerified = () => true
  // userInfo ? userInfo.email_verified : false;

  const loginWithEmail = async (credentials: LoginCredentials) => {
    return await handleLoginWithEmail(credentials.email, credentials.password, setUserInfo, setIsAuthenticated)
  }

  const loginWithGoogle = async () => {
    setIsLoading(true)
    const response = await handleLoginWithGoogle(setIsAuthenticated, setUserInfo)
    setIsLoading(false)
    return response
  }

  const logout = async () => {
    removeJwtToken()
    setIsAuthenticated(false)
    setUserInfo(null)
    return await signOut(auth)
  }

  const handleResendVerificationEmail = async (email: string) => {
    try {
      await resendVerificationEmail(email)
      return true
    } catch (error) {
      console.error('Error resending verification email:', error)
      return false
    }
  }

  const handleVerifyActionCode = async (code: string) => {
    try {
      await applyActionCode(auth, code)
      return true
    } catch (error) {
      console.error('Error verifying action code:', error)
      return false
    }
  }

  const handleVerifyPasswordResetCode = async (code: string) => {
    const userEmail = await verifyPasswordResetCode(auth, code)
    return userEmail
  }

  const handleSendPasswordResetEmail = async (email: string) => {
    const actionCodeSettings = {
      url: 'http://localhost:3000',
      handleCodeInApp: false,
    }

    try {
      const response = await sendPasswordResetEmail(auth, email, actionCodeSettings)
      return true
    } catch (error) {
      console.error('Error sending password reset email:', error)
      return false
    }
  }

  const handleConfirmPasswordReset = async (code: string, newPassword: string) => {
    try {
      const response = await confirmPasswordReset(auth, code, newPassword)
      return true
    } catch (error) {
      console.error('Error confirming password reset:', error)
      return false
    }
  }

  const refreshUserData = async (currentUser: User) => {
    const jwtToken = await currentUser?.getIdToken(true)
    if (jwtToken) {
      setJwtToken(jwtToken)
    }
    const docSnapData = await getUserProfile(currentUser.uid)
    const verified = currentUser.emailVerified
    const data = { ...docSnapData, email_verified: verified }
    if (docSnapData) {
      if (!docSnapData.is_active) {
        
      }

      setUserInfo(data) // Set the state here
    } else {
      
    }
  }

  const getUserInfo = async () => {
    const currentUser = auth.currentUser

    if (!currentUser) {
      console.error('Failed to getUserInfo.')
      return null
    }

    return (await getUserProfile(currentUser?.uid)) as UserInfoType | null
  }
  const signUp = async (credentials: SignUpCredentials) => {
    return await signUpAndSendEmail(credentials, auth)
  }

  useEffect(() => {
    setIsLoading(true)
    const unsubscribe = onAuthStateChanged(auth, async user => {
      setIsAuthenticated(!!user)
      if (user) {
        if (user.email) {
          HyperDX.setGlobalAttributes({
            userId: user.uid,
            userEmail: user.email,
          })
        }
        await refreshUserData(user)
      }
      setIsLoading(false)
    })

    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        userInfo,
        setUserInfo,
        isSuperuser,
        isLoading,
        isVerified,
        loginWithEmail,
        getUserInfo,
        loginWithGoogle,
        logout,
        signUp,
        handleResendVerificationEmail,
        handleSendPasswordResetEmail,
        handleVerifyPasswordResetCode,
        handleConfirmPasswordReset,
        handleVerifyActionCode,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
