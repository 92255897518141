import { useEffect, useState } from 'react'
import { Formula } from 'core/types/grayBook'
import { useFormulas } from 'hooks/useFormulas'
import { useFormulaRules } from 'hooks/useFormulaRules'
import { calculatePrice } from 'views/SuperAdmin/Tools/GrayBook/utils/calculators'

interface CalculateDealInput {
  make: string
  model: string
  type: string
  size: string
  miles: string
  year: string
  sellerAskingPrice: string
}

type DealQualityInfo = {
  label: string
  color: 'success' | 'primary' | 'warning' | 'error'
  displayText: string
}

interface CalculateDealOutput {
  grayBookPrice: number | null
  dealQuality: string
  dealQualityInfo: DealQualityInfo | null
  matchedFormula: Formula | null
}

const getDealQualityInfo = (quality: string): DealQualityInfo => {
  const displayText = quality.charAt(0).toUpperCase() + quality.slice(1)
  
  switch (quality) {
    case 'great':
      return { label: 'Great', color: 'success', displayText }
    case 'good':
      return { label: 'Good', color: 'primary', displayText }
    case 'average':
      return { label: 'Average', color: 'warning', displayText }
    case 'belowAverage':
      return { label: 'Below Average', color: 'warning', displayText }
    case 'poor':
      return { label: 'Poor', color: 'error', displayText }
    default:
      return { label: 'Unkown', color: 'error', displayText }
  }
}

export const useCalculateDeal = (input: CalculateDealInput): CalculateDealOutput => {
  const [matchedFormula, setMatchedFormula] = useState<Formula | null>(null)
  const [grayBookPrice, setGrayBookPrice] = useState<number | null>(null)
  const [dealQuality, setDealQuality] = useState<string>('')
  const [dealQualityInfo, setDealQualityInfo] = useState<DealQualityInfo | null>(null)


  const { formulas } = useFormulas({ formulasCollection: 'gray_book_formulas' })
  
  const { rules } = useFormulaRules({
    rulesCollection: 'gray_book_formulas',
    subcollection: matchedFormula
      ? {
          name: 'rules',
          parentDocId: matchedFormula.id,
        }
      : undefined,
  })

  // Match formula
  useEffect(() => {
    const { make, model, type, size } = input
    if (make && model && type && size && formulas) {
      const matched = formulas.find(
        f =>
          f.vehicleMake.toLowerCase() === make.toLowerCase() &&
          f.vehicleModel.toLowerCase() === model.toLowerCase() &&
          f.vehicleType.toLowerCase() === type.toLowerCase() &&
          f.vehicleSize.toLowerCase() === size.toLowerCase(),
      )
      setMatchedFormula(matched || null)
    } else {
      setMatchedFormula(null)
      setGrayBookPrice(null)
      setDealQuality('')
    }
  }, [input, formulas])

  // Calculate price
  useEffect(() => {
    const { miles, year } = input
    if (matchedFormula && rules && miles && year) {
      const yearRules = rules?.filter(rule => rule.targetFactor === 'year') || []
      const mileageRules = rules?.filter(rule => rule.targetFactor === 'mileage') || []
      const result = calculatePrice({
        basePrice: matchedFormula.maxPrice,
        year: Number(year),
        mileage: Number(miles.replace(/,/g, '')),
        yearRules,
        mileageRules,
        minPrice: matchedFormula.minPrice,
      })
      const wholeSalePrice = result.price * (1 - (matchedFormula.wholesaleDiscount || 0) / 100)
      setGrayBookPrice(wholeSalePrice)
    }
  }, [matchedFormula, rules, input])

  // Update deal quality effect to include chip info
  useEffect(() => {
    if (grayBookPrice && input.sellerAskingPrice) {
      const askingPrice = Number(input.sellerAskingPrice)
      const priceDiff = ((askingPrice - grayBookPrice) / grayBookPrice) * 100

      let quality = ''
      if (priceDiff <= -10) {
        quality = 'great'
      } else if (priceDiff <= 0) {
        quality = 'good'
      } else if (priceDiff <= 10) {
        quality = 'average'
      } else if (priceDiff <= 20) {
        quality = 'belowAverage'
      } else {
        quality = 'poor'
      }

      setDealQuality(quality)
      setDealQualityInfo(getDealQualityInfo(quality))
    } else {
      setDealQuality('')
      setDealQualityInfo(null)
    }
  }, [grayBookPrice, input.sellerAskingPrice])

  return { grayBookPrice, dealQuality, dealQualityInfo, matchedFormula }
} 