import { Box, Typography } from '@mui/material'
import { axisClasses } from '@mui/x-charts'
import { Heatmap } from '@mui/x-charts-pro/Heatmap'
import { HeatmapValueType } from '@mui/x-charts-pro/models/seriesType/heatmap'
import { FormulaRule } from 'core/types/grayBook'
import { interpolatePuBu } from 'd3-scale-chromatic'
import { useIsMobile } from 'hooks/screen/useIsMobile'
import React, { useEffect, useRef, useState } from 'react'
import { calculatePrice } from '../../utils/calculators'

interface FormulaHeatmapProps {
  yearRules: FormulaRule[]
  mileageRules: FormulaRule[]
  maxPrice: number
  minPrice: number
  wholesaleDiscount: number
}

const FormulaHeatmap: React.FC<FormulaHeatmapProps> = ({ yearRules, mileageRules, maxPrice, minPrice, wholesaleDiscount}) => {
  const isMobile = useIsMobile()
  const containerRef = useRef<HTMLDivElement>(null)
  const [dimensions, setDimensions] = useState({ width: 600, height: 400 })

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const { width, height } = containerRef.current.getBoundingClientRect()
        setDimensions({
          width: Math.max(width - 40, 300), // Minimum width of 300px, accounting for padding
          height: Math.max(height - 120, 300), // Minimum height of 300px, accounting for padding and headers
        })
      }
    }

    updateDimensions()
    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  // Get min/max years from rules, filtering for active rules only
  const activeYearRules = yearRules.filter(r => r.status === 'active')
  const currentYear = new Date().getFullYear()
  const minYear = Math.min(...activeYearRules.map(r => r.lowerBound || Number.MAX_VALUE))
  const maxYear = Math.max(...activeYearRules.map(r => r.lowerBound || currentYear))
  
  // Ensure at least 5 years range
  const adjustedMinYear = Math.min(minYear, maxYear - 4) // Go back 4 years from max if needed
  const yearRange = Math.max(maxYear - adjustedMinYear + 1, 5) // At least 5 years
  const years = Array.from({ length: yearRange }, (_, i) => maxYear - i)

  // Three-scale mileage range: 0-50k in 10k increments, 50k-100k in 25k increments, 100k-250k in 50k increments
  const mileages = [0, 10000, 20000, 30000, 40000, 50000, 75000, 100000, 150000, 200000, 250000]
  const activeMileageRules = mileageRules.filter(r => r.status === 'active')
  // Calculate price data using only active rules
  const data: HeatmapValueType[] = years.flatMap((year, yearIndex) =>
    mileages.map((mileage, mileageIndex) => {
      const { price } = calculatePrice({
        basePrice: maxPrice,
        year,
        mileage,
        yearRules: activeYearRules,
        mileageRules: activeMileageRules,
        minPrice,
      })
      return [yearIndex, mileageIndex, price / 1000] as HeatmapValueType
    }),
  )

  if (years.length === 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <Typography variant='subtitle1' color='text.secondary'>
          Add rules to see the visualization
        </Typography>
      </Box>
    )
  }

  return (
    <Box ref={containerRef} sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', p: 0 }}>
      <Typography variant='h6' gutterBottom={!isMobile}sx={{ mb: 0.5 }}>
        Formula Visualization
      </Typography>
      <Typography variant='body2' color='text.secondary' gutterBottom={!isMobile} sx={{ mb: 1 }}>
        Note: Values shown are in thousands (prices divided by 1,000)
      </Typography>
      <Box sx={{ flex: 1, width: '100%', height: '100%', minHeight: 300, mx: isMobile ? -1 : 0 }}>
        <Heatmap
          xAxis={[
            {
              scaleType: 'band',
              data: years.map(y => y.toString()),
              label: 'Year',
              tickLabelStyle: {
                angle: 0,
                textAnchor: 'middle',
              },
            },
          ]}
          yAxis={[
            {
              scaleType: 'band',
              data: mileages.map(m => `${Math.round(m / 1000)}k`),
              label: 'Mileage (thousands)',
              reverse: true,
              position: 'right',
            },
          ]}
          zAxis={[
            {
              min: minPrice / 1000,
              max: maxPrice / 1000,
              colorMap: {
                type: 'continuous',
                color: interpolatePuBu,
              },
            },
          ]}
          sx={{
            [`.${axisClasses.left} .${axisClasses.label}`]: {
              transform: 'translateX(-35px)',
            },
            '& .MuiChartsAxis-line': {
              stroke: 'rgba(0, 0, 0, 0.2)',
            },
            '& .MuiChartsAxis-tick': {
              stroke: 'rgba(0, 0, 0, 0.2)',
            },
            '& .MuiChartsAxis-tickLabel': {
              fill: 'rgba(0, 0, 0, 0.6)',
              fontSize: '0.75rem',
            },
            '& .MuiChartsAxis-label': {
              fill: 'rgba(0, 0, 0, 0.8)',
              fontSize: '0.875rem',
              fontWeight: 500,
            },
          }}
          width={dimensions.width}
          height={dimensions.height}
          margin={{ left: isMobile ? 40 : 90, top: isMobile ? 0 : 20, right: isMobile ? 0 : 90, bottom: isMobile ? 20 : 40 }}
          series={[
            {
              data,
              valueFormatter: (value: HeatmapValueType) => {
                const retailPrice = value[2] as number
                const wholesalePrice = retailPrice * (1 - (wholesaleDiscount || 0) / 100)
                return `Retail: ${retailPrice}k\nWholesale: ${Math.round(wholesalePrice)}k`
              },
            },
          ]}
        />
      </Box>
    </Box>
  )
}

export default FormulaHeatmap
