import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  LinearProgress,
  List,
  ListItem,
  Typography,
  Tooltip,
} from '@mui/material'
import { useSnackbar } from 'contexts/snackBarContext'
import { readInventoryFromXLSX } from 'core/api/inventory/handleInventoryCsvUpload'
import { useAddFirestoreDocument } from 'hooks/firebase/useAddFirestoreDocument'
import TruckIcon from '@mui/icons-material/LocalShipping'
import SpeedIcon from '@mui/icons-material/Speed'
import { useAuth } from 'contexts/AuthContext'
import { generateOpticStockNumber, generateShieldStockNumber } from 'core/api/inventory/generateStockNumber'
import { useQueryFirestoreDocuments } from 'hooks/firebase/useQueryFirestoreDocuments'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { addVinData } from 'views/SuperAdmin/Tools/vehicleUtils'
import { useNavigate, useLocation } from 'react-router-dom'
import { updateSubmissionStatus } from 'core/api/submission/updateSubmissionStatus'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import InventoryIcon from '@mui/icons-material/Inventory'
import { db } from 'core/config/firebase'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { useInventory } from 'hooks/useInventory'

interface FleetOwner {
  name: string
  email: string
  phone: string
}

interface SubmissionVehicle {
  id: string
  year: string
  make: string
  model: string
  vin: string
  miles: string
  type: string
  size: string
  user_condition: string
  lien: string
  financingBank: string
  submissionId: string
  desiredPrice: string
  damage?: string
  stock_number?: string
  location?: string
  fuel?: string
  initials?: string
}

interface SubmittedVehicleInfo {
  vin: string;
  inventory: 'shield' | 'optic';
}

interface Props {
  initialVehicles?: any[] // Replace with proper vehicle type
  submissionId?: string
  hideBackButton?: boolean
  fleetOwner?: FleetOwner
}

const formatMileage = (miles: string | number | undefined): string => {
  if (!miles) return '0'
  const mileageString = miles.toString()
  return mileageString.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const formatField = (value: string | number | undefined): string => {
  if (!value || value === '') return 'N/A'
  return value.toString()
}

const FleetListPage: React.FC<Props> = ({ 
  initialVehicles,
  submissionId,
  hideBackButton = false,
  fleetOwner = {
    name: '',
    email: '',
    phone: ''
  }
}) => {
  const location = useLocation()
  const { file } = location.state || {}
  const navigate = useNavigate()
  const [selectedVehicles, setSelectedVehicles] = useState<SubmissionVehicle[]>([])
  const [vehicles1, setVehicles1] = useState<SubmissionVehicle[]>([])
  const { showSnackbar } = useSnackbar()
  const { userInfo } = useAuth()
  const [isLoading, setIsLoading] = useState(true)
  const [submittedVehicles, setSubmittedVehicles] = useState<SubmittedVehicleInfo[]>([])

  const { data: opticVehicles } = useQueryFirestoreDocuments({
    inventoryCollectionName: 'master_inventory',
  })

  const { data: shieldVehicles } = useQueryFirestoreDocuments({
    inventoryCollectionName: 'shield_inventory',
  })

  const { mutate: addFirebaseDocumentToOpticInventory } = useAddFirestoreDocument({
    collectionName: 'master_inventory',
  })

  const { mutate: addFirebaseDocumentToShieldInventory } = useAddFirestoreDocument({
    collectionName: 'shield_inventory',
  })

  const { data: spreadsheetVehicles, isLoading: isSpreadsheetLoading } = useQuery(
    ['submission-vehicles', file],
    async () => {
      if (!file) return null
      const vehicles = await readInventoryFromXLSX(file)
      const validVehicles = vehicles.filter(vehicle => vehicle.vin.length === 17)
      const updatedVehicles = await Promise.all(validVehicles.map(addVinData))
      return updatedVehicles.map((vehicle, index) => ({
        id: `spreadsheet_${index}`,
        year: vehicle.year || '',
        make: vehicle.make || '',
        model: vehicle.model || '',
        vin: vehicle.vin || '',
        miles: vehicle.miles || '',
        type: vehicle.type || '',
        size: vehicle.size || '',
        user_condition: '',
        lien: '',
        financingBank: '',
        submissionId: '',
        desiredPrice: '',
      })) as SubmissionVehicle[]
    },
    {
      staleTime: Infinity,
      enabled: !!file,
    },
  )

  // Query to get vehicles from form_sell collection
  const fetchVehiclesFromFormSell = async () => {
    if (!submissionId) {
      console.error('No submission ID provided')
      return []
    }

    try {
      const formSellRef = doc(db, 'form_sell', submissionId)
      const docSnap = await getDoc(formSellRef)
      
      if (!docSnap.exists()) {
        console.error('No submission found')
        return []
      }

      // Get submitted VINs from the document
      const submittedVehiclesFromDoc = docSnap.data().submittedVehicles || []
      setSubmittedVehicles(submittedVehiclesFromDoc)

      // Extract vehicles array from the submission document
      const vehicles = docSnap.data().vehicles || []
      
      return vehicles.map((vehicle: any, index: number) => ({
        id: `${submissionId}_${index}`,
        year: vehicle.year || '',
        make: vehicle.make || '',
        model: vehicle.model || '',
        vin: vehicle.vin || '',
        miles: vehicle.miles || '0',
        type: vehicle.type || vehicle.truckType || '',
        size: vehicle.size || '',
        user_condition: vehicle.user_condition || '',
        lien: vehicle.lien || '',
        location: vehicle.location || '',
        damage: vehicle.damage || '',
        fuel: vehicle.fuel || '',
        financingBank: vehicle.financingBank || '',
        submissionId: submissionId,
        desiredPrice: vehicle.desiredPrice || '',
        initials: vehicle.initials || ''
      })) as SubmissionVehicle[]

    } catch (error) {
      console.error('Error fetching vehicles:', error)
      showSnackbar('Error fetching vehicles', 'error')
      return []
    }
  }

  useEffect(() => {
    if (initialVehicles) {
      setVehicles1(initialVehicles)
      setIsLoading(false)
    } else if (submissionId) {
      fetchVehiclesFromFormSell()
    } else {
      setIsLoading(false)
    }
  }, [initialVehicles, submissionId])

  // Add inventory hooks
  const { vehicles: opticInventory } = useInventory('master_inventory')
  const { vehicles: shieldInventory } = useInventory('shield_inventory')
  
  // Update the VIN checking functions to use the inventory hooks
  const isVinInOpticInventory = (vin: string) => {
    return (opticInventory?.some((vehicle: any) => vehicle.vin === vin) ?? false) ||
           submittedVehicles.some(v => v.vin === vin && v.inventory === 'optic')
  }

  const isVinInShieldInventory = (vin: string) => {
    return (shieldInventory?.some((vehicle: any) => vehicle.vin === vin) ?? false) ||
           submittedVehicles.some(v => v.vin === vin && v.inventory === 'shield')
  }

  // Update addVehiclesToInventory to handle optimistic updates
  const addVehiclesToInventory = async (newVehicles: SubmissionVehicle[], kind: 'optic' | 'shield') => {
    if (!submissionId) {
      showSnackbar('No submission ID provided', 'error')
      return
    }

    if (!fleetOwner) {
      showSnackbar('No fleet owner information provided', 'error')
      return
    }

    // Double check that none of the vehicles are already in the target inventory
    const alreadyInInventory = newVehicles.some(vehicle => 
      kind === 'optic' ? isVinInOpticInventory(vehicle.vin) : isVinInShieldInventory(vehicle.vin)
    )

    if (alreadyInInventory) {
      showSnackbar('One or more vehicles are already in the target inventory', 'error')
      return
    }

    try {
      // Optimistically update local state
      const newSubmittedVehicles: SubmittedVehicleInfo[] = newVehicles.map(vehicle => ({
        vin: vehicle.vin,
        inventory: kind
      }))
      
      setSubmittedVehicles(prev => [...prev, ...newSubmittedVehicles])
      setSelectedVehicles([])

      // Then perform the actual updates
      for (const vehicle of newVehicles) {
        // Update Firestore
        const formSellRef = doc(db, 'form_sell', submissionId)
        await updateDoc(formSellRef, {
          submittedVehicles: [...submittedVehicles, ...newSubmittedVehicles]
        })

        // Add to inventory
        let newStockNumber: string
        const newVehicle = {
          ...vehicle,
          seller_asking_price: vehicle.desiredPrice,
          who: fleetOwner.name,
          phone_number: fleetOwner.phone,
          email: fleetOwner.email,
          condition: 'Used',
          date_added: new Date().toISOString(),
        }

        if (kind === 'optic') {
          const initials = vehicle.initials === "None" ? '' : vehicle.initials || ''
          const firstInitial = initials?.charAt(0) || userInfo?.first_name?.charAt(0) || ''
          const secondInitial = initials?.charAt(1) || userInfo?.last_name?.charAt(0) || ''

          newStockNumber = generateOpticStockNumber(
            opticInventory?.map((v: any) => v.stock_number) || [],
            firstInitial,
            secondInitial,
          )
          newVehicle.stock_number = newStockNumber
          await addFirebaseDocumentToOpticInventory(newVehicle)
        } else {
          newStockNumber = generateShieldStockNumber(
            shieldInventory?.map((v: any) => v.stock_number) || []
          )
          newVehicle.stock_number = newStockNumber
          await addFirebaseDocumentToShieldInventory(newVehicle)
        }
      }

      showSnackbar(`${newVehicles.length} vehicle${newVehicles.length > 1 ? 's' : ''} added to ${kind} inventory`, 'success')

    } catch (error) {
      // Revert optimistic update on error
      setSubmittedVehicles(prev => 
        prev.filter(v => !newVehicles.some(nv => nv.vin === v.vin && v.inventory === kind))
      )
      console.error('Error processing vehicles:', error)
      showSnackbar('Error processing vehicles', 'error')
    }
  }

  const handleVehicleSelect = (vehicle: SubmissionVehicle) => {
    // Check if vehicle is in both inventories
    const isInBoth = isVinInOpticInventory(vehicle.vin) && isVinInShieldInventory(vehicle.vin)
    
    // Don't allow selection if vehicle is in both inventories
    if (isInBoth) {
      return
    }

    const index = selectedVehicles.findIndex(v => v.vin === vehicle.vin)
    if (index > -1) {
      setSelectedVehicles(selectedVehicles.filter(v => v.vin !== vehicle.vin))
    } else {
      setSelectedVehicles([...selectedVehicles, vehicle])
    }
  }

  const handleSubmissionComplete = async () => {
    if (!submissionId) {
      showSnackbar('No submission ID provided', 'error')
      return
    }

    try {
      await updateSubmissionStatus(submissionId, 'Completed')
      navigate(-1)
    } catch (error) {
      showSnackbar('Failed to update submission status', 'error')
    }
  }

  // Helper function to get vehicle submission status
  const getVehicleSubmissionStatus = (vehicle: SubmissionVehicle) => {
    const isInOptic = isVinInOpticInventory(vehicle.vin)
    const isInShield = isVinInShieldInventory(vehicle.vin)

    if (isInOptic && isInShield) {
      return { label: 'Both', color: 'rgba(76, 175, 80, 0.2)' }
    }
    if (isInShield) {
      return { label: 'Shield', color: 'rgba(244, 67, 54, 0.2)' }
    }
    if (isInOptic) {
      return { label: 'Optic', color: 'rgba(33, 150, 243, 0.2)' }
    }
    return { label: 'Select', color: 'inherit' }
  }

  // Update the isVehicleInInventory function to check submittedVehicles array
  const isVehicleInInventory = (vehicle: SubmissionVehicle, inventory: 'optic' | 'shield') => {
    if (inventory === 'optic') {
      return isVinInOpticInventory(vehicle.vin)
    } else {
      return isVinInShieldInventory(vehicle.vin)
    }
  }

  // Update canSubmitToInventory to ONLY check the target inventory
  const canSubmitToInventory = (vehicles: SubmissionVehicle[], inventory: 'optic' | 'shield') => {
    return !vehicles.some(vehicle => {
      if (inventory === 'optic') {
        return isVinInOpticInventory(vehicle.vin)
      } else {
        return isVinInShieldInventory(vehicle.vin)
      }
    })
  }

  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          {!hideBackButton && (
            <Button 
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate(-1)} 
              sx={{ mr: 2 }}
            >
              Back
            </Button>
          )}
          <Typography variant='h4'>
            Submit Vehicles to Inventory
          </Typography>
        </Box>

        {isLoading ? (
          <Box display='flex' justifyContent='center' alignItems='center' minHeight={200}>
            <CircularProgress />
          </Box>
        ) : vehicles1 && vehicles1.length > 0 ? (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
              <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant='determinate' value={(selectedVehicles.length / vehicles1.length) * 100} />
              </Box>
              <Box sx={{ minWidth: 35 }}>
                <Typography variant='body2' color='text.secondary'>
                  {`${selectedVehicles.length}/${vehicles1.length}`}
                </Typography>
              </Box>
            </Box>

            <List>
              {vehicles1
                .sort((a: SubmissionVehicle, b: SubmissionVehicle) => parseInt(a.miles) - parseInt(b.miles))
                .map((vehicle: SubmissionVehicle) => {
                  const isInBoth = isVinInOpticInventory(vehicle.vin) && isVinInShieldInventory(vehicle.vin)
                  
                  const listItem = (
                    <ListItem
                      key={vehicle.vin}
                      onClick={() => handleVehicleSelect(vehicle)}
                      sx={{
                        cursor: isInBoth ? 'not-allowed' : 'pointer',
                        '&:hover': { 
                          filter: isInBoth ? 'none' : 'brightness(0.90)' 
                        },
                        padding: 2,
                        borderBottom: '1px solid',
                        borderColor: 'divider',
                        backgroundColor: getVehicleSubmissionStatus(vehicle).color,
                      }}
                    >
                      <Grid container spacing={2} alignItems='center'>
                        <Grid item>
                          <Checkbox 
                            checked={selectedVehicles.some(v => v.vin === vehicle.vin)}
                            disabled={isInBoth}
                            sx={{
                              '&.Mui-disabled': {
                                color: 'rgba(0, 0, 0, 0.26)', // More visible disabled state
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs>
                          <Typography variant='h6' gutterBottom>
                            <TruckIcon sx={{ verticalAlign: 'middle', marginRight: 1 }} />
                            {vehicle.year} {vehicle.make} {vehicle.model}
                            {vehicle.type && ` ${vehicle.type}`}
                            {vehicle.size && ` ${vehicle.size}`}
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={3}>
                              <Typography variant='body2' color='text.secondary'>
                                VIN: {formatField(vehicle.vin)}
                              </Typography>
                            </Grid>
                            
                            <Grid item xs={12} sm={3}>
                              <Typography variant='body2' color='text.secondary'>
                                <SpeedIcon sx={{ verticalAlign: 'middle', marginRight: 1, fontSize: '0.9rem' }} />
                                Mileage: {formatMileage(vehicle.miles)} mi
                              </Typography>
                            </Grid>
                            
                            <Grid item xs={12} sm={3}>
                              <Typography variant='body2' color='text.secondary'>
                                Condition: {formatField(vehicle.user_condition)}
                              </Typography>
                            </Grid>
                            
                            {vehicle.lien && (
                              <Grid item xs={12} sm={3}>
                                <Typography variant='body2' color='text.secondary'>
                                  Lien: {formatField(vehicle.lien)}
                                </Typography>
                              </Grid>
                            )}
                            
                            {vehicle.financingBank && (
                              <Grid item xs={12} sm={3}>
                                <Typography variant='body2' color='text.secondary'>
                                  Financing Bank: {formatField(vehicle.financingBank)}
                                </Typography>
                              </Grid>
                            )}
                            
                            {vehicle.desiredPrice && (
                              <Grid item xs={12} sm={3}>
                                <Typography variant='body2' color='text.secondary'>
                                  Desired Price: ${formatField(vehicle.desiredPrice)}
                                </Typography>
                              </Grid>
                            )}

                            {vehicle.location && (
                              <Grid item xs={12} sm={3}>
                                <Typography variant='body2' color='text.secondary'>
                                  Location: {formatField(vehicle.location)}
                                </Typography>
                              </Grid>
                            )}

                            {vehicle.fuel && (
                              <Grid item xs={12} sm={3}>
                                <Typography variant='body2' color='text.secondary'>
                                  Fuel: {formatField(vehicle.fuel)}
                                </Typography>
                              </Grid>
                            )}

                            {vehicle.initials && (
                              <Grid item xs={12} sm={3}>
                                <Typography variant='body2' color='text.secondary'>
                                  Referral: {formatField(vehicle.initials)}
                                </Typography>
                              </Grid>
                            )}

                            {vehicle.damage && (
                              <Grid item xs={12} sm={6}>
                                <Typography variant='body2' color='text.secondary'>
                                  Damage: {formatField(vehicle.damage)}
                                </Typography>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Chip
                            label={
                              selectedVehicles.some(v => v.vin === vehicle.vin)
                                ? 'Selected'
                                : getVehicleSubmissionStatus(vehicle).label
                            }
                            color={
                              selectedVehicles.some(v => v.vin === vehicle.vin)
                                ? 'primary'
                                : getVehicleSubmissionStatus(vehicle).label === 'Both'
                                  ? 'success'
                                  : getVehicleSubmissionStatus(vehicle).label === 'Shield'
                                    ? 'error'
                                    : getVehicleSubmissionStatus(vehicle).label === 'Optic'
                                      ? 'info'
                                      : 'default'
                            }
                            size='small'
                          />
                        </Grid>
                      </Grid>
                    </ListItem>
                  )

                  return isInBoth ? (
                    <Tooltip 
                      key={vehicle.vin}
                      title="Already in Shield and Optic inventory"
                      placement="top"
                    >
                      <div>{listItem}</div>
                    </Tooltip>
                  ) : listItem
                })}
            </List>
          </>
        ) : (
          <Box 
            display='flex' 
            flexDirection='column' 
            alignItems='center' 
            justifyContent='center' 
            minHeight={400}
            sx={{ 
              backgroundColor: 'background.paper',
              borderRadius: 1,
              p: 4,
              textAlign: 'center'
            }}
          >
            <InventoryIcon sx={{ fontSize: 100, color: 'text.secondary', mb: 2 }} />
            <Typography variant='h5' color='text.secondary' gutterBottom>
              No Vehicles Available
            </Typography>
            <Typography variant='body1' color='text.secondary' sx={{ maxWidth: 500, mb: 3 }}>
              There are no vehicles to display. This could be because no vehicles were submitted or the submission file was empty.
            </Typography>
            {!hideBackButton && (
              <Button
                variant='contained'
                onClick={() => navigate(-1)}
                startIcon={<ArrowBackIcon />}
              >
                Return to Submissions
              </Button>
            )}
          </Box>
        )}
      </Box>

      {vehicles1 && vehicles1.length > 0 && (
        <Box sx={{ 
          position: 'fixed',
          bottom: 24,
          right: 24,
          display: 'flex', 
          justifyContent: 'flex-end',
          gap: 2,
        }}>
          <Button 
            onClick={() => addVehiclesToInventory(selectedVehicles, 'shield')} 
            sx={{ textTransform: 'none' }}
            disabled={selectedVehicles.length === 0 || !canSubmitToInventory(selectedVehicles, 'shield')}
            variant='contained'
            color='error'
          >
            Submit {selectedVehicles.length > 0 ? `(${selectedVehicles.length})` : ''} to Shield Inventory
          </Button>
          <Button 
            onClick={() => addVehiclesToInventory(selectedVehicles, 'optic')} 
            sx={{ 
              textTransform: 'none',
              bgcolor: '#2196f3',
              '&:hover': {
                bgcolor: '#1976d2'
              }
            }}
            disabled={selectedVehicles.length === 0 || !canSubmitToInventory(selectedVehicles, 'optic')}
            variant='contained'
          >
            Submit {selectedVehicles.length > 0 ? `(${selectedVehicles.length})` : ''} to Optic Inventory
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default FleetListPage
