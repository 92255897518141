import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAuth } from 'contexts/AuthContext'
import { useSnackbar } from 'contexts/snackBarContext'
import { db } from 'core/config/firebase'
import { addDoc, collection, deleteDoc, doc, getDocs, updateDoc } from 'firebase/firestore'
import { formulaRuleConverter } from 'converters/ruleConverter'
import { FormulaRule } from 'core/types/grayBook'

interface UseFormulaRulesOptions {
  onSuccess?: () => void
  onError?: (error: Error) => void
  dontShowUpdates?: boolean
}

interface UseFormulaRulesParams {
  rulesCollection: string
  subcollection?: {
    name: string
    parentDocId: string
  }
  options?: UseFormulaRulesOptions
}

export const useFormulaRules = ({ rulesCollection, subcollection, options }: UseFormulaRulesParams) => {
  const { showSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { userInfo } = useAuth()

  const rulesCollectionRef = subcollection
    ? collection(db, rulesCollection, subcollection.parentDocId, subcollection.name)
    : collection(db, rulesCollection)


  const queryKey = subcollection
    ? [rulesCollection, subcollection.parentDocId, subcollection.name]
    : [rulesCollection]

  const {
    data: rules,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery<FormulaRule[]>({
    queryKey,
    queryFn: async () => {
      const collectionWithConverter = rulesCollectionRef.withConverter(formulaRuleConverter)
      const snapshot = await getDocs(collectionWithConverter)
      const docs = snapshot.docs.map(doc => ({
        // @ts-expect-error - overwrite incase an id field is accidently written
        id: doc.id,
        ...doc.data(),
      }))
      return docs.sort((a, b) => a.order - b.order)
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
  })

  type AddRuleContext = {
    previousRules: FormulaRule[] | undefined
    optimisticRule: FormulaRule
  }

  const addRule = useMutation<
    FormulaRule,
    Error,
    { 
      rule: Omit<FormulaRule, 'id' | 'createdAt' | 'createdBy'>
      customQueryKey?: string[] 
    },
    AddRuleContext
  >({
    mutationFn: async ({ rule, customQueryKey }) => {
      const targetCollectionRef = customQueryKey 
        ? collection(db, customQueryKey[0], ...customQueryKey.slice(1)).withConverter(formulaRuleConverter)
        : rulesCollectionRef.withConverter(formulaRuleConverter)

      const newRule = {
        ...rule,
        createdBy: userInfo?.email || '',
        createdAt: new Date().toISOString(),
      } as FormulaRule
      
      const docRef = await addDoc(targetCollectionRef, formulaRuleConverter.toFirestore(newRule))
      
      return {
        ...newRule,
        id: docRef.id
      } as FormulaRule
    },
    onMutate: async ({ rule, customQueryKey }) => {
      const targetQueryKey = customQueryKey || queryKey
      await queryClient.cancelQueries({ queryKey: targetQueryKey })
      const previousRules = queryClient.getQueryData<FormulaRule[]>(targetQueryKey) || []

      const optimisticRule = {
        ...rule,
        id: `temp-${Date.now()}`,
        createdBy: userInfo?.email || '',
        createdAt: new Date().toISOString(),
      }

      queryClient.setQueryData<FormulaRule[]>(targetQueryKey, old => {
        return [...(old || []), optimisticRule]
      })

      return { previousRules, optimisticRule }
    },
    onError: (err, { customQueryKey }, context) => {
      if (context) {
        queryClient.setQueryData(customQueryKey || queryKey, context.previousRules)
      }
      if (!options?.dontShowUpdates) {
        showSnackbar('Error adding rule', 'error')
      }
      options?.onError?.(err)
    },
    onSuccess: (result, { customQueryKey }, context) => {
      if (context) {
        queryClient.setQueryData<FormulaRule[]>(customQueryKey || queryKey, old => {
          return (old || []).map(rule => 
            rule.id === context.optimisticRule.id ? result : rule
          )
        })
      }
      
      if (!options?.dontShowUpdates) {
        showSnackbar('Rule added successfully', 'success')
      }
      options?.onSuccess?.()
    },
    onSettled: (_, __, { customQueryKey }) => {
      if (customQueryKey) {
        queryClient.invalidateQueries({ queryKey: customQueryKey })
      }
    }
  })

  type UpdateRuleContext = {
    previousRules: FormulaRule[]
  }

  const updateRule = useMutation<
    { id: string } & Partial<FormulaRule>,
    Error,
    { id: string; data: Partial<FormulaRule> },
    UpdateRuleContext
  >({
    mutationFn: async ({ id, data }) => {
      const collectionWithConverter = rulesCollectionRef.withConverter(formulaRuleConverter)
      const docRef = doc(collectionWithConverter, id)
      const updateData = formulaRuleConverter.toFirestore({
        ...data,
        modified_by: userInfo?.email,
        modified_at: new Date().toISOString(),
      } as FormulaRule)
      await updateDoc(docRef, updateData)
      return { id, ...data }
    },
    onMutate: async ({ id, data }) => {
      await queryClient.cancelQueries({ queryKey })
      const previousRules = queryClient.getQueryData<FormulaRule[]>(queryKey) || []

      queryClient.setQueryData<FormulaRule[]>(queryKey, old => {
        if (!old) return []
        return old.map(rule => 
          rule.id === id ? { ...rule, ...data } : rule
        )
      })

      return { previousRules }
    },
    onError: (err, variables, context) => {
      if (context) {
        queryClient.setQueryData(queryKey, context.previousRules)
      }
      if (!options?.dontShowUpdates) {
        showSnackbar('Error updating rule', 'error')
      }
      options?.onError?.(err)
    },
    onSuccess: (result) => {
      if (!options?.dontShowUpdates) {
        showSnackbar('Rule updated successfully', 'success')
      }
      options?.onSuccess?.()
    },
  })

  type DeleteRuleContext = {
    previousRules: FormulaRule[]
  }

  const deleteRule = useMutation<
    string,
    Error,
    string,
    DeleteRuleContext
  >({
    mutationFn: async (id: string) => {
      const collectionWithConverter = rulesCollectionRef.withConverter(formulaRuleConverter)
      const docRef = doc(collectionWithConverter, id)
      await deleteDoc(docRef)
      return id
    },
    onMutate: async (id) => {
      await queryClient.cancelQueries({ queryKey })
      const previousRules = queryClient.getQueryData<FormulaRule[]>(queryKey) || []

      queryClient.setQueryData<FormulaRule[]>(queryKey, old => {
        if (!old) return []
        return old.filter(rule => rule.id !== id)
      })

      return { previousRules }
    },
    onError: (err, id, context) => {
      if (context) {
        queryClient.setQueryData(queryKey, context.previousRules)
      }
      if (!options?.dontShowUpdates) {
        showSnackbar('Error deleting rule', 'error')
      }
      options?.onError?.(err)
    },
    onSuccess: () => {
      if (!options?.dontShowUpdates) {
        showSnackbar('Rule deleted successfully', 'success')
      }
      options?.onSuccess?.()
    },
  })

  return {
    rules,
    isLoading,
    isError,
    error,
    addRule: (rule: Omit<FormulaRule, 'id' | 'createdAt' | 'createdBy'>, customQueryKey?: string[]) => 
      addRule.mutate({ rule, customQueryKey }),
    updateRule: updateRule.mutate,
    deleteRule: deleteRule.mutate,
    refetchRules: refetch,
  }
}
