import React, { useState } from 'react'
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Box,
} from '@mui/material'
import { GridColDef, GridActionsCellItem } from '@mui/x-data-grid'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import InfoIcon from '@mui/icons-material/Info'
import CreateTicket from './components/CreateTicket'
import { deleteHubspotTicket, pullHubspotTickets } from 'core/api/hubspot'
import HubspotLoading from '../components/HubspotLoading'
import DataGridFirestoreCRUD from 'components/table/DataGridFirestoreCRUD'
// import { getJwtToken } from "core/api"; // const testingWithPostman

// const testingWithPostman = getJwtToken()
// 

const LeadsDash = () => {
  const [openAddTicket, setOpenAddTicket] = useState(false)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [ticketToDelete, setTicketToDelete] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [notesDialogOpen, setNotesDialogOpen] = useState(false)
  const [selectedNotes, setSelectedNotes] = useState<string | null>(null)

  const handleOpenAddTicket = () => {
    setOpenAddTicket(true)
  }

  const handleCloseAddContact = () => {
    setOpenAddTicket(false)
  }

  const handleDeleteTicket = (ticketId: string) => {
    setTicketToDelete(ticketId)
    setConfirmDialogOpen(true)
  }

  const handleConfirmDelete = async () => {
    if (ticketToDelete) {
      setIsLoading(true)
      try {
        await deleteHubspotTicket(ticketToDelete)
        
        
        await pullHubspotTickets() // Refresh the ticket list
        
        alert('ticket deleted successfully')
      } catch (error) {
        console.error('Failed to delete ticket:', error)
        alert('Failed to delete ticket')
      } finally {
        setIsLoading(false)
        setConfirmDialogOpen(false)
        setTicketToDelete(null)
      }
    }
  }

  const handleCancelDelete = () => {
    setConfirmDialogOpen(false)
    setTicketToDelete(null)
  }

  const handleOpenNotesDialog = (notes: string) => {
    setSelectedNotes(notes)
    setNotesDialogOpen(true)
  }

  const handleCloseNotesDialog = () => {
    setNotesDialogOpen(false)
    setSelectedNotes(null)
  }

  const columns: GridColDef[] = [
    {
      field: 'properties.subject.versions_0.value',
      headerName: 'Lead Title',
      width: 250,
      editable: false,
    },
    {
      field: 'properties.hs_pipeline_stage.value',
      headerName: 'Status',
      width: 150,
      editable: false,
    },
    // { field: 'properties.content.value', headerName: 'Desc', width: 250, editable: false },
    {
      field: 'ticketNotesAction',
      headerName: 'Notes',
      width: 150,
      renderCell: params =>
        params.row['properties.content.value'] ? (
          <GridActionsCellItem
            icon={<InfoIcon />}
            label='Notes'
            // this is the notes field in firebase
            onClick={() => handleOpenNotesDialog(params.row['properties.content.value'])}
            color='inherit'
          />
        ) : (
          // Else none
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <Typography>None</Typography>
          </div>
        ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Delete',
      width: 75,
      getActions: params => [
        <GridActionsCellItem
          key={params.row['properties.hs_ticket_id.value']}
          icon={<DeleteForeverIcon />}
          label='Delete'
          onClick={() => handleDeleteTicket(params.row['properties.hs_ticket_id.value'])}
          color='inherit'
        />,
      ],
    },
    {
      field: 'properties.createdate.timestamp',
      headerName: 'Date Created',
      width: 250,
      editable: false,
    },
  ]

  return (
    <>
      {/* Header */}
      {/* <Box textAlign="center" mb={3}>
        <Typography variant="h1">Hubspot Leads</Typography>
      </Box> */}
      {/* Add button */}
      <Box sx={{ marginBottom: 4 }}>
        <Button variant='outlined' onClick={handleOpenAddTicket}>
          New Lead
        </Button>
      </Box>
      <CreateTicket open={openAddTicket} handleClose={handleCloseAddContact} />
      {!isLoading && (
        // Delete Warning
        <Dialog open={confirmDialogOpen} onClose={handleCancelDelete} aria-labelledby='confirm-delete-dialog'>
          <DialogTitle id='confirm-delete-dialog'>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to delete this ticket?</DialogContentText>
          </DialogContent>
          {/* Deletes onclick() action */}
          <DialogActions>
            <Button onClick={handleCancelDelete} color='primary'>
              No
            </Button>
            <Button onClick={handleConfirmDelete} color='secondary'>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {/* Description Onclick Handler */}
      <Dialog open={notesDialogOpen} onClose={handleCloseNotesDialog} aria-labelledby='ticket-notes-dialog'>
        {/* Open Card Description popout */}
        <DialogTitle id='ticket-notes-dialog'>Notes</DialogTitle>
        <DialogContent>
          <Typography>{selectedNotes}</Typography>
        </DialogContent>
        {/* Close popout */}
        <DialogActions>
          <Button
            onClick={handleCloseNotesDialog}
            style={{
              color: 'red',
              transition: 'color 0.3s ease-in-out',
            }}
            onMouseEnter={e => (e.currentTarget.style.color = 'blue')}
            onMouseLeave={e => (e.currentTarget.style.color = 'red')}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* CURD Table */}
      {isLoading ? (
        <HubspotLoading />
      ) : (
        <DataGridFirestoreCRUD
          columns={columns}
          collectionName='hubspot_tickets'
          filters={[]}
          rowSize={40}
          search={true}
          create={false}
          editable={false}
          deleteable={false}
        />
      )}
    </>
  )
}

export default LeadsDash
