import { Box, Button, TextField, Tooltip, Typography } from '@mui/material'
import { useSessionStorageState } from '@toolpad/core'
import { Formula } from 'core/types/grayBook'
import { useFormulaRules } from 'hooks/useFormulaRules'
import React from 'react'
import { calculatePrice } from '../../utils/calculators'
import NumberInput from 'components/NumberInput'

interface CalculatorProps {
  formula: Formula
}

type CalculatorState = {
  mileage: number
  year: number
  calculatedPrice: number | null
  yearDepreciation: number
  mileageDepreciation: number
}

const Calculator: React.FC<CalculatorProps> = ({ formula }) => {
  const [mileage, setMileage] = React.useState(0)
  const [year, setYear] = React.useState(new Date().getFullYear())
  const [calculatedPrice, setCalculatedPrice] = React.useState<number | null>(null)
  const [yearDepreciation, setYearDepreciation] = React.useState(0)
  const [mileageDepreciation, setMileageDepreciation] = React.useState(0)

  const [calculatorState, setCalculatorState] = useSessionStorageState<CalculatorState>(
    'calculator_state',
    {
      mileage: 0,
      year: new Date().getFullYear(),
      calculatedPrice: null,
      yearDepreciation: 0,
      mileageDepreciation: 0
    },
    {
      codec: {
        stringify: JSON.stringify,
        parse: (storedValue: string) => {
          try {
            return JSON.parse(storedValue)
          } catch (error) {
            return null 
          }
        },
      },
    }
  )

  // Sync state with session storage only on initial load
  React.useEffect(() => {
    if (calculatorState && mileage === 0) { // Only sync if mileage is still default value
      setMileage(calculatorState.mileage)
      setYear(calculatorState.year)
      setCalculatedPrice(calculatorState.calculatedPrice)
      setYearDepreciation(calculatorState.yearDepreciation)
      setMileageDepreciation(calculatorState.mileageDepreciation)
    }
  }, [calculatorState]) // Only run on calculatorState changes

  const { rules } = useFormulaRules({
    rulesCollection: 'gray_book_formulas',
    subcollection: {
      name: 'rules',
      parentDocId: formula.id,
    },
  })

  const currentYear = new Date().getFullYear()

  const handleCalculate = () => {
    if (!formula.maxPrice || !mileage || !year) return

    // Add year validation before calculation
    if (year < 1990 || year > currentYear) {
      // You might want to add some error state/display here
      return
    }

    const yearRules = rules?.filter(rule => rule.targetFactor === 'year') || []
    const mileageRules = rules?.filter(rule => rule.targetFactor === 'mileage') || []

    const {price: newPrice, breakdown: {yearDepreciation, mileageDepreciation}} = calculatePrice({
      basePrice: formula.maxPrice,
      year,
      mileage,
      yearRules,
      mileageRules,
      minPrice: formula.minPrice ?? 0,
    })

    setCalculatedPrice(newPrice)
    setYearDepreciation(yearDepreciation)
    setMileageDepreciation(mileageDepreciation)
    setCalculatorState({ mileage, year, calculatedPrice: newPrice, yearDepreciation, mileageDepreciation })
  }

  const getDisabledReason = React.useMemo(() => {
    if (!formula.maxPrice) return 'No maximum price defined for this formula'
    if (!mileage) return 'Please enter a mileage value'
    if (!year) return 'Please enter a year'
    if (!rules) return 'Loading formula rules...'
    return ''
  }, [formula.maxPrice, mileage, year, rules])

  return (
    <>
      <TextField
        fullWidth
        label='Year'
        type='number'
        value={year || ''}
        onChange={e => setYear(e.target.value === '' ? 0 : Number(e.target.value))}
        helperText={`Enter a year between 1990 and ${currentYear}`}
        sx={{ mb: 2 }}
      />

      <NumberInput
        fullWidth
        label='Mileage'
        value={mileage || null}
        onChange={value => setMileage(value ?? 0)}
        sx={{ mb: 2 }}
      />

      <Tooltip title={getDisabledReason} placement='bottom'>
        <span>
          <Button
            variant='contained'
            onClick={handleCalculate}
            disabled={Boolean(getDisabledReason)}
            fullWidth
            sx={{ mb: 2 }}
          >
            Calculate
          </Button>
        </span>
      </Tooltip>

      {calculatedPrice !== null && (
        <Box sx={{ mt: 3, p: 3, bgcolor: 'background.paper', borderRadius: 2, boxShadow: 3 }}>
          <Typography variant='h5' gutterBottom color='primary' align='center'>
            Calculated Prices
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'space-around', mb: 3, flexWrap: 'wrap', gap: 4 }}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant='subtitle1' color='text.secondary'>
                Retail Price
              </Typography>
              <Typography variant='h3' sx={{ color: 'primary.dark' }}>
                ${calculatedPrice.toLocaleString()}
              </Typography>
            </Box>

            {formula.wholesaleDiscount !== undefined && (
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant='subtitle1' color='text.secondary'>
                  Wholesale Price {formula.wholesaleDiscount > 0 && `(${formula.wholesaleDiscount}% off)`}
                </Typography>
                <Typography variant='h3' sx={{ color: '#d3af37' }}>
                  ${(calculatedPrice * (1 - formula.wholesaleDiscount / 100)).toLocaleString()}
                </Typography>
              </Box>
            )}
          </Box>

          <Box sx={{ bgcolor: 'grey.50', p: 2, borderRadius: 1 }}>
            <Typography variant='subtitle1' sx={{ mb: 2, color: 'primary' }}>
              Price Breakdown
            </Typography>
            
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='body1' color='text.secondary'>
                  Original Price:
                </Typography>
                <Typography variant='body1' component='span'>
                  ${formula.maxPrice.toLocaleString()}
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='body1' color='text.secondary'>
                  Year Depreciation:
                </Typography>
                <Box>
                  <Typography variant='body1' component='span' color='error.main'>
                    -${yearDepreciation.toLocaleString()}
                  </Typography>
                  <Typography variant='body2' component='span' color='text.secondary' sx={{ ml: 1 }}>
                    ({((yearDepreciation / formula.maxPrice) * 100).toFixed(1)}%)
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='body1' color='text.secondary'>
                  Mileage Depreciation:
                </Typography>
                <Box>
                  <Typography variant='body1' component='span' color='error.main'>
                    -${mileageDepreciation.toLocaleString()}
                  </Typography>
                  <Typography variant='body2' component='span' color='text.secondary' sx={{ ml: 1 }}>
                    ({((mileageDepreciation / formula.maxPrice) * 100).toFixed(1)}%)
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                pt: 2,
                pb: 1,
                mt: 1,
                borderTop: '2px solid',
                borderColor: 'primary.main',
                px: 2
              }}>
                <Typography variant='h6' color='primary.main' sx={{ fontWeight: 'bold' }}>
                  Calculated Price:
                </Typography>
                <Box>
                  <Typography variant='h6' component='span' color='primary.main' sx={{ fontWeight: 'bold' }}>
                    ${calculatedPrice.toLocaleString()}
                  </Typography>
                  {formula.minPrice && calculatedPrice === formula.minPrice && (
                    <Typography variant='caption' color='warning.main' sx={{ display: 'block', textAlign: 'right' }}>
                      (Minimum price applied)
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}

export default Calculator
