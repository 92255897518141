
export const httpCodes: { [key: number]: string } = {
  401: 'Unauthorized',
  404: 'User not found',
  501: 'User with current applications cannot be deleted.',
  500: 'Error, please contact Chase.',
  400: 'Unsupported Role',
  409: 'User already exists',
  502: 'User already exists',
}
