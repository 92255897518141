import { doc, getDoc, setDoc } from '@firebase/firestore'
import { db } from 'core/config/firebase'

// @ts-expect-error We need to define the userAuth type
export const createUser = async (userAuth, additionalData = {}) => {
  try {
    if (!userAuth) return null

    

    const userRef = doc(db, `users/${userAuth.uid}`)
    
    const docSnap = await getDoc(userRef)

    if (!docSnap.exists()) {
      await setDoc(userRef, {
        email: userAuth.email,
        ...additionalData,
      })
      return {
        uid: userAuth.uid,
        email: userAuth.email,
        ...additionalData,
      }
    }
    return docSnap.data()
  } catch (error) {
    console.error('Error creating user:', error)
  }
}
