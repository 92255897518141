import { 
  Paper, Typography, TableContainer, Table, TableHead, 
  TableRow, TableCell, TableBody, Box, Chip
} from '@mui/material'
import { formatPrice } from 'core/utils/inventoryUtils'
import React, { useMemo } from 'react'

interface TopPerformancePreviewProps {
  vehicles: any[]
  onClick: () => void
  dateRangeText: string
}

export const TopPerformancePreview = ({ vehicles, onClick, dateRangeText }: TopPerformancePreviewProps) => {
  // Calculate all model stats
  const modelStats = useMemo(() => {
    const stats = vehicles.reduce((acc: Record<string, {
      count: number
      totalProfit: number
    }>, vehicle) => {
      const model = vehicle.model || 'Unknown'
      if (!acc[model]) {
        acc[model] = { count: 0, totalProfit: 0 }
      }
      
      const profit = parseFloat(vehicle.profit) || 0
      acc[model].count++
      acc[model].totalProfit += profit
      
      return acc
    }, {})

    return Object.entries(stats).map(([model, data]) => ({
      model,
      count: data.count,
      avgProfit: data.count ? data.totalProfit / data.count : 0
    }))
  }, [vehicles])

  // Get top 5 for display
  const topFiveModels = [...modelStats]
    .sort((a, b) => b.avgProfit - a.avgProfit)
    .slice(0, 5)

  // Calculate totals using ALL models
  const totalCount = modelStats.reduce((sum, model) => sum + model.count, 0)
  const totalProfit = modelStats.reduce((sum, model) => sum + (model.avgProfit * model.count), 0) / totalCount

  return (
    <Paper 
      sx={{ 
        p: 3, 
        height: 325,
        cursor: 'pointer',
        '&:hover': { bgcolor: 'action.hover' },
        display: 'flex',
        flexDirection: 'column'
      }}
      onClick={onClick}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h6">
          Top Performing Models ({dateRangeText})
        </Typography>
        <Chip 
          label={`${modelStats.length} Total Models`}
          color="primary"
          size="small"
        />
      </Box>

      <TableContainer sx={{ flex: 1 }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Model</TableCell>
              <TableCell align="right">Units Sold</TableCell>
              <TableCell align="right">Avg Profit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {topFiveModels.map((model) => (
              <TableRow key={model.model}>
                <TableCell>{model.model}</TableCell>
                <TableCell align="right">{model.count}</TableCell>
                <TableCell align="right">{model.avgProfit > 0 ? formatPrice(model.avgProfit.toString()) : '-'}</TableCell>
              </TableRow>
            ))}
            <TableRow sx={{ '& td': { fontWeight: 'bold' } }}>
              <TableCell>Total</TableCell>
              <TableCell align="right">{totalCount}</TableCell>
              <TableCell align="right">{formatPrice(totalProfit.toString())}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}
