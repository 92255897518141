import { FormulaRule } from 'core/types/grayBook'

export const calculatePrice = ({
  basePrice,
  year,
  mileage,
  yearRules,
  mileageRules,
  minPrice,
}: {
  basePrice: number
  year: number
  mileage: number
  yearRules: FormulaRule[]
  mileageRules: FormulaRule[]
  minPrice?: number
}) => {
  let price = basePrice
  let yearDepreciation = 0
  let mileageDepreciation = 0

  // Apply year rules cumulatively
  for (const rule of yearRules) {
    if (year <= (rule.lowerBound ?? new Date().getFullYear())) {
      const yearsInRange = rule.lowerBound - (Math.min(rule.upperBound ?? new Date().getFullYear(), year))

      if (yearsInRange > 0) {
        const depreciation = rule.operationType === 'subtract' 
          ? yearsInRange * rule.operationValue
          : yearsInRange * (basePrice * rule.operationValue / 100)
        
        yearDepreciation += depreciation
        price -= depreciation
      }
    }
  }

  // Apply mileage rules cumulatively
  for (const rule of mileageRules) {
    if (mileage >= (rule.lowerBound ?? 0)) {
      const milesInRange = rule.upperBound
        ? Math.min(mileage, rule.upperBound) - (rule.lowerBound ?? 0)
        : mileage - (rule.lowerBound ?? 0)
        
      if (milesInRange > 0) {
        const depreciation = rule.operationType === 'subtract'
          ? milesInRange * rule.operationValue
          : milesInRange * (basePrice * rule.operationValue / 100)
        
        mileageDepreciation += depreciation
        price -= depreciation
      }
    }
  }

  const finalPrice = Math.max(minPrice ?? 0, price)

  // For debugging
  console.debug('Final Price:', {
    originalPrice: basePrice,
    yearDepreciation,
    mileageDepreciation,
    totalDepreciation: yearDepreciation + mileageDepreciation,
    finalPrice,
    minPrice,
    actualFinalPrice: finalPrice,
  })

  return {
    price: finalPrice,
    breakdown: {
      yearDepreciation,
      mileageDepreciation,
      totalDepreciation: yearDepreciation + mileageDepreciation
    }
  }
}
