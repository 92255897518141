import { Box, Typography, Chip } from '@mui/material'
import { SellSubmission } from 'core/types/form_submission'
import { format } from 'date-fns'
import FleetListPage from '../SubmissionManagement/components/FleetListPage'
import React from 'react'

interface Props {
  submission: SellSubmission
}

export const SellDetail = ({ submission }: Props) => {
  // Construct fleetOwner object from submission data
  const fleetOwner = {
    name: `${submission.firstName} ${submission.lastName}`,
    email: submission.email,
    phone: submission.phone
  }

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {/* Contact Information Header */}
      <Box sx={{ p: 3, borderBottom: 1, borderColor: 'divider' }}>
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 3 }}>
          <Box>
            <Typography variant="overline" color="text.secondary">Contact Information</Typography>
            <Typography variant="h6">{submission.firstName} {submission.lastName}</Typography>
            <Typography>{submission.email}</Typography>
            <Typography>{submission.phone}</Typography>
          </Box>
          <Box>
            <Typography variant="overline" color="text.secondary">Submission Details</Typography>
            <Typography variant="body2">
              {format(new Date(submission.createdAt.seconds * 1000), 'PPpp')}
            </Typography>
            <Chip 
              size="small"
              label={submission.status}
              sx={{ mt: 1 }}
            />
          </Box>
        </Box>
      </Box>

      {/* Fleet List */}
      <Box sx={{ flex: 1, overflow: 'auto' }}>
        <FleetListPage 
          initialVehicles={submission.vehicles}
          submissionId={submission.id}
          hideBackButton
          fleetOwner={fleetOwner}
        />
      </Box>
    </Box>
  )
} 