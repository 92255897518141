import { 
  Dialog, DialogTitle, DialogContent, TextField, Table, 
  TableBody, TableCell, TableContainer, TableHead, TableRow, 
  Paper, TableSortLabel, IconButton, InputAdornment
} from '@mui/material'
import { Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material'
import { useState, useMemo } from 'react'
import { formatPrice } from 'core/utils/inventoryUtils'
import React from 'react'

// Helper function to safely parse price values
const parsePrice = (price: string | number | undefined | null): number => {
  try {
    if (price === null || price === undefined) return 0
    
    // If it's already a number, return it
    if (typeof price === 'number') return price

    // If it's a string, clean and parse it
    if (typeof price === 'string') {
      const cleanedPrice = price.replace(/[^0-9.-]+/g, '')
      const parsedPrice = parseFloat(cleanedPrice)
      return isNaN(parsedPrice) ? 0 : parsedPrice
    }

    return 0
  } catch (error) {
    console.error('Error parsing price:', price, error)
    return 0
  }
}

// Helper function to safely parse dates
const parseDate = (dateValue: any): Date | null => {
  try {
    if (!dateValue) return null

    // Handle Firestore Timestamp
    if (dateValue?.seconds) {
      return new Date(dateValue.seconds * 1000)
    }

    // Handle string dates with "at" format (e.g. "January 3, 2025 at 4:38:22 PM GMT+1")
    if (typeof dateValue === 'string' && dateValue.includes(' at ')) {
      // Remove the "at" and try parsing
      const cleanedDate = dateValue.replace(' at ', ' ')
      const date = new Date(cleanedDate)
      if (!isNaN(date.getTime())) {
        return date
      }
    }

    // Handle string dates
    if (typeof dateValue === 'string') {
      const date = new Date(dateValue)
      return isNaN(date.getTime()) ? null : date
    }

    // Handle Date objects
    if (dateValue instanceof Date) {
      return isNaN(dateValue.getTime()) ? null : dateValue
    }

    return null
  } catch (error) {
    console.error('Error parsing date:', dateValue, error)
    return null
  }
}

interface ModelStats {
  model: string
  totalSold: number
  avgProfit: number
  totalProfit: number
  currentInventory: number
  avgDaysToSell: number
  avgListPrice: number
}

interface TopModelsModalProps {
  open: boolean
  onClose: () => void
  vehicles: any[] | undefined // Update the type to match what's coming from useInventory
  dateRange: { start: string; end: string }
}

export const TopModelsModal = ({ 
  open, 
  onClose, 
  vehicles = [], // Provide default empty array
  dateRange 
}: TopModelsModalProps) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [sortConfig, setSortConfig] = useState<{
    key: keyof ModelStats
    direction: 'asc' | 'desc'
  }>({ key: 'avgProfit', direction: 'desc' })

  const modelStats = useMemo(() => {
    if (!vehicles?.length) return []

    // Group all vehicles by model
    const stats = vehicles.reduce((acc: Record<string, {
      sold: any[]
      available: any[]
      totalProfit: number
      totalDaysToSell: number
      totalListPrice: number
    }>, vehicle) => {
      const model = vehicle.model || 'Unknown'
      
      if (!acc[model]) {
        acc[model] = {
          sold: [],
          available: [],
          totalProfit: 0,
          totalDaysToSell: 0,
          totalListPrice: 0
        }
      }

      if (vehicle.status?.toLowerCase() === 'sold') {
        acc[model].sold.push(vehicle)
        acc[model].totalProfit += parsePrice(vehicle.profit)
        acc[model].totalListPrice += parsePrice(vehicle.optic_list_price)
        
        // Calculate days to sell if we have both dates
        if (vehicle.date_added && vehicle.sold_date) {
          const addedDate = parseDate(vehicle.date_added)
          const soldDate = parseDate(vehicle.sold_date)
          if (addedDate && soldDate) {
            const daysToSell = Math.floor((soldDate.getTime() - addedDate.getTime()) / (1000 * 60 * 60 * 24))
            acc[model].totalDaysToSell += daysToSell
          }
        }
      } else if (vehicle.status?.toLowerCase() === 'available') {
        acc[model].available.push(vehicle)
      }

      return acc
    }, {})

    // Convert to array with calculated metrics
    return Object.entries(stats).map(([model, data]): ModelStats => ({
      model,
      totalSold: data.sold.length,
      avgProfit: data.sold.length ? data.totalProfit / data.sold.length : 0,
      totalProfit: data.totalProfit,
      currentInventory: data.available.length,
      avgDaysToSell: data.sold.length ? data.totalDaysToSell / data.sold.length : 0,
      avgListPrice: data.sold.length ? data.totalListPrice / data.sold.length : 0
    }))
  }, [vehicles])

  const filteredAndSortedModels = useMemo(() => {
    return modelStats
      .filter(item => 
        item.model.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a, b) => {
        const multiplier = sortConfig.direction === 'asc' ? 1 : -1
        if (sortConfig.key === 'model') {
          return multiplier * a.model.localeCompare(b.model)
        }
        return multiplier * (a[sortConfig.key] - b[sortConfig.key])
      })
  }, [modelStats, searchTerm, sortConfig])

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        Complete Model Performance
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      
      <DialogContent>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search models..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ mb: 2 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />

        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === 'model'}
                    direction={sortConfig.key === 'model' ? sortConfig.direction : 'asc'}
                    onClick={() => setSortConfig({ key: 'model', direction: sortConfig.direction === 'asc' ? 'desc' : 'asc' })}
                  >
                    Model
                  </TableSortLabel>
                </TableCell>

                <TableCell align="right">
                  <TableSortLabel
                    active={sortConfig.key === 'totalSold'}
                    direction={sortConfig.key === 'totalSold' ? sortConfig.direction : 'asc'}
                    onClick={() => setSortConfig({ key: 'totalSold', direction: sortConfig.direction === 'asc' ? 'desc' : 'asc' })}
                  >
                    Total Sold
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">
                  <TableSortLabel
                    active={sortConfig.key === 'avgProfit'}
                    direction={sortConfig.key === 'avgProfit' ? sortConfig.direction : 'asc'}
                    onClick={() => setSortConfig({ key: 'avgProfit', direction: sortConfig.direction === 'asc' ? 'desc' : 'asc' })}
                  >
                    Avg Profit
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">
                  <TableSortLabel
                    active={sortConfig.key === 'totalProfit'}
                    direction={sortConfig.key === 'totalProfit' ? sortConfig.direction : 'asc'}
                    onClick={() => setSortConfig({ key: 'totalProfit', direction: sortConfig.direction === 'asc' ? 'desc' : 'asc' })}
                  >
                    Total Profit
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">
                  <TableSortLabel
                    active={sortConfig.key === 'avgDaysToSell'}
                    direction={sortConfig.key === 'avgDaysToSell' ? sortConfig.direction : 'asc'}
                    onClick={() => setSortConfig({ key: 'avgDaysToSell', direction: sortConfig.direction === 'asc' ? 'desc' : 'asc' })}
                  >
                    Avg Days to Sell
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">
                  <TableSortLabel
                    active={sortConfig.key === 'avgListPrice'}
                    direction={sortConfig.key === 'avgListPrice' ? sortConfig.direction : 'asc'}
                    onClick={() => setSortConfig({ key: 'avgListPrice', direction: sortConfig.direction === 'asc' ? 'desc' : 'asc' })}
                  >
                    Avg List Price
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredAndSortedModels.map((model) => (
                <TableRow key={model.model}>
                  <TableCell>{model.model}</TableCell>
                  <TableCell align="right">{model.totalSold}</TableCell>
                  <TableCell align="right">{formatPrice(model.avgProfit.toString())}</TableCell>
                  <TableCell align="right">{formatPrice(model.totalProfit.toString())}</TableCell>
                  <TableCell align="right">{Math.round(model.avgDaysToSell)}</TableCell>
                  <TableCell align="right">{formatPrice(model.avgListPrice.toString())}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  )
}
